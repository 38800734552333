import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { Form, Input, Button, Modal, } from "antd";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealFade from 'react-reveal/Fade';

const FormItem = Form.Item;

class Qna extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            popupVisible: false,
            open: false
        }
        this.formRef = React.createRef();
    }

    OnCreateInquiry = (e) => {
        let info = JSON.stringify({
            ...this.formRef.current.getFieldsValue(),
            //추가 지정해서 보내기
            category: 'MAIN',
            title: '문의/제안서',
            content:
                '회사이름:' + this.formRef.current.getFieldValue('company') + "/" +
                '전화번호:' + this.formRef.current.getFieldValue('phone') + "/" +
                '지역:' + this.formRef.current.getFieldValue('address') + "/" +
                '업종:' + this.formRef.current.getFieldValue('job')
        });
        const self = this;

        httpPost(httpUrl.inquiryCreate, [], info).then(response => {
            console.log('result=' + JSON.stringify(response.data))
            if (response.data == "SUCCESS") {
                alert('문의가 성공적으로 접수되었습니다. 빠른시일내 연락드리겠습니다.')
                this.formRef.current.resetFields();
            }
            else {
                alert('알수없는 문제로 문의에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
            }
        }).catch(e => {
            console.log('err=' + JSON.stringify(e))
            Modal.error({
                content: (
                    <div style={{ paddingLeft: '40px' }}>
                        작성에 실패하였습니다. <br />잠시 후 다시 시도해주세요.
                    </div>
                ),
                okText: '닫기'
            });
        })

    }

    ChangeNav = () => {
        this.setState(
            prevState => ({ open: !prevState.open })
        )
    };
    setLanguage = (lang) => {
        this.props.setLanguage(lang)
    }
    render() {
        const containerClasses = [];
        if (this.state.open) {
            containerClasses.push("open")
        }
        const { language, string } = this.props;

        const { form } = this.props;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 17 }
            }
        };
        return (
            <div className="ksmartcode-qna">
                <div className="ksmartcode-qna-header">
                    <div className="ksmartcode-qna-header-all">
                        <div className="ksmartcode-qna-header-left">
                            <Link to='/' className="text-link">
                                <img src={require('../../img/kslogo2.png')}
                                    className="header-logo" />
                            </Link>
                        </div>
                        <div className="ksmartcode-qna-header-menu">
                            <div className="ksmartcode-qna-header-menu-center">
                                <div className="ksmartcode-qna-header-menu-center-element">
                                    <div class="dropdown-page">
                                        <div class="dropdown-page-div">
                                            {string.blockchainService}
                                        </div>
                                        <div class="dropdown-page-content">
                                            <Link to='/intro' className="text-link2">
                                                {string.intro}
                                            </Link>
                                            <Link to='/companyIntro' className="text-link2">
                                                {string.safetyTitle}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="ksmartcode-qna-header-menu-center-element">
                                    <div class="dropdown-page">
                                        <div class="dropdown-page-div">
                                            {string.serviceAll}
                                        </div>
                                        <div class="dropdown-page-content">
                                            <Link to='/serviceUse' className="text-link2">
                                                {string.serviceUse}
                                            </Link>
                                            <Link to='/enterUse' className="text-link2">
                                                {string.customUse}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="ksmartcode-qna-header-menu-center-element">
                                    <Link to='/Charge' className="text-link2">
                                        {string.serviceCharge}
                                    </Link>
                                </div>
                                <div className="ksmartcode-qna-header-menu-center-element">
                                    <Link to='/marketing' className="text-link2">
                                        {string.marketing}
                                    </Link>
                                </div>
                                <div className="ksmartcode-qna-header-menu-center-element">
                                    <Link to='/shopping' offset='70' className="text-link2">
                                        {string.shopping}
                                    </Link>
                                </div>
                                <div className="ksmartcode-qna-header-menu-center-element">
                                    <Link to='/nft' offset='70' className="text-link2">
                                        {string.nftMarket}
                                    </Link>
                                </div>
                            </div>
                            <div className="ksmartcode-qna-header-menu-right">
                                <div className="ksmartcode-qna-header-menu-right-element">
                                    <Link to='/customer' className="text-link3">
                                        {string.customerCenter}
                                    </Link>
                                </div>
                                <Link to='/qna' className="text-link">
                                    <div className="ksmartcode-qna-header-menu-right-element2">
                                        <Link to='/qna' className="text-link">
                                            {string.consulting}
                                        </Link>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="ksmartcode-qna-header-hide">
                            <div className="ksmartcode-qna-header-hide-menu">
                                <div className={containerClasses.join(' ')}>
                                    <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                                        <MenuButton2 state={!this.state.open} /></div>
                                    <div className={"sidenav"}>
                                        <a href="/">{string.homePage}</a>
                                        <a href="./intro">{string.intro}</a>
                                        <a href="./companyintro">{string.safetyTitle}</a>
                                        <a href="./serviceuse">{string.serviceUse}</a>
                                        <a href="./enteruse">{string.customUse}</a>
                                        <a href="./charge">{string.serviceCharge}</a>
                                        <a href="./marketing">{string.marketing}</a>
                                        <a href="./shopping">{string.shopping}</a>
                                        <a href="./nft">{string.nftMarket}</a>
                                        <a href="./customer">{string.customerCenter}</a>
                                        <a href="./qna">{string.consulting}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ksmartcode-qna-consulting" id="consulting">
                    <RevealFade duration={2000}>
                        <div className="ksmartcode-qna-consulting-all">
                            <div className="ksmartcode-qna-consulting-guide">
                                {string.consultingTitle}
                                <div className="ksmartcode-qna-consulting-mainText">
                                    {string.consultingMain1}
                                    <br />
                                    {string.consultingMain2}
                                </div>
                                <div className="ksmartcode-qna-consulting-subText">
                                    {string.consultingSub}
                                </div>
                            </div>
                            <div className="ksmartcode-qna-consulting-input">
                                <Form ref={this.formRef} {...formItemLayout} onFinish={this.OnCreateInquiry}>
                                    <FormItem
                                        name="company"
                                        rules={[{ required: true, message: '회사명을 입력해주세요' }]}>
                                        <input type="text" id="qna" placeholder={string.consultingContent1} />
                                    </FormItem>
                                    <FormItem
                                        name="phone"
                                        rules={[{ required: true, message: '전화번호를 입력해주세요' }]}>
                                        <input type="text" id="qna" placeholder={string.consultingContent2} />
                                    </FormItem>
                                    <FormItem
                                        name="address"
                                        rules={[{ required: true, message: '지역을 입력해주세요' }]}>
                                        <input type="text" id="qna" placeholder={string.consultingContent3} />
                                    </FormItem>
                                    <FormItem
                                        name="job"
                                        rules={[{ required: true, message: '업종을 입력해주세요' }]}>
                                        <input type="text" id="qna" placeholder={string.consultingContent4} />
                                    </FormItem>
                                    {/* <div className="ksmartcode-qna-consulting-input-checkbox">
                                        <input type="checkbox" id="agree" />
                                        <div className="ksmartcode-qna-consulting-input-checkbox-text">
                                            {string.agreement}
                                        </div>
                                    </div> */}
                                    {/* <div className="ksmartcode-qna-consulting-input-text-button"
                                        onClick={() => { }}>
                                        {string.consultingButton}
                                        </div> */}
                                    <Button className="ksmartcode-qna-consulting-input-text-button" type="primary" htmlType="submit">
                                        {string.consultingButton}
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </RevealFade>
                </div>

                <div className="ksmartcode-footer">
                    <div className="ksmartcode-footer-all">
                        <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
                        <div className="ksmartcode-footer-address">{string.addressInfo}</div>
                        <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
                        <div className="ksmartcode-footer-term1">
                            <Link to='/terms' offset='70' className="text-link5">
                                {string.terms1}
                            </Link>
                        </div>
                        <div className="ksmartcode-footer-term2">
                            <Link to='/privacy' offset='70' className="text-link5">
                                {string.terms2}
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string
    };
};
export default withAlert()(connect(mapStateToProps)(Qna));





