import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { Form, Input, Button, } from "antd";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealFade from 'react-reveal/Fade';
import RevealSlide from 'react-reveal/Slide';

class Nft extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            popupVisible: false,
            open: false
        }
        this.formRef = React.createRef();
    }

    OnCreateInquiry = (e) => {
        let info = JSON.stringify({
            ...this.formRef.current.getFieldsValue(),
            //추가 지정해서 보내기
            category: 'MAIN',
            title: '문의/제안서',
            content: '업종:' + this.formRef.current.getFieldValue('job')
        });

        httpPost(httpUrl.inquiryCreate, [], info).then(response => {
            console.log('result=' + JSON.stringify(response.data))
            if (response.data == "SUCCESS") {
              alert('문의가 성공적으로 접수되었습니다. 빠른시일내 연락드리겠습니다.')
              this.formRef.current.resetFields();
            }
            else {
              alert('알수없는 문제로 문의에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
            }
        }).catch(e => {
            console.log('err=' + JSON.stringify(e))
        })

    }

    ChangeNav = () => {
        this.setState(
            prevState => ({ open: !prevState.open })
        )
    };
    setLanguage = (lang) => {
        this.props.setLanguage(lang)
    }
    render() {
        const containerClasses = [];
        if (this.state.open) {
            containerClasses.push("open")
        }
        const { language, string } = this.props;

        return (
            <div className="ksmartcode-charge">
                <div className="ksmartcode-charge-header">
                    <div className="ksmartcode-charge-header-all">
                        <div className="ksmartcode-charge-header-left">
                            <Link to='/' className="text-link">
                                <img src={require('../../img/kslogo2.png')}
                                    className="header-logo" />
                            </Link>
                        </div>
                        <div className="ksmartcode-charge-header-menu">
                            <div className="ksmartcode-charge-header-menu-center">
                                <div className="ksmartcode-charge-header-menu-center-element">
                                    <div class="dropdown-page">
                                        <div class="dropdown-page-div">
                                            {string.blockchainService}
                                        </div>
                                        <div class="dropdown-page-content">
                                            <Link to='/intro' className="text-link2">
                                                {string.intro}
                                            </Link>
                                            <Link to='/companyIntro' className="text-link2">
                                                {string.safetyTitle}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="ksmartcode-charge-header-menu-center-element">
                                    <div class="dropdown-page">
                                        <div class="dropdown-page-div">
                                            {string.serviceAll}
                                        </div>
                                        <div class="dropdown-page-content">
                                            <Link to='/serviceUse' className="text-link2">
                                                {string.serviceUse}
                                            </Link>
                                            <Link to='/enterUse' className="text-link2">
                                                {string.customUse}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="ksmartcode-charge-header-menu-center-element">
                                    {string.serviceCharge}
                                </div>
                                <div className="ksmartcode-charge-header-menu-center-element">
                                    <Link to='/marketing' className="text-link2">
                                        {string.marketing}
                                    </Link>
                                </div>
                                <div className="ksmartcode-charge-header-menu-center-element">
                                    <Link to='/shopping' offset='70' className="text-link2">
                                        {string.shopping}
                                    </Link>
                                </div>
                                <div className="ksmartcode-charge-header-menu-center-element">
                                    <Link to='/nft' offset='70' className="text-link2">
                                        {string.nftMarket}
                                    </Link>
                                </div>
                            </div>
                            <div className="ksmartcode-charge-header-menu-right">
                                <div className="ksmartcode-charge-header-menu-right-element">
                                    <Link to='/customer' className="text-link3">
                                        {string.customerCenter}
                                    </Link>
                                </div>
                                <Link to='/qna' className="text-link">
                                    <div className="ksmartcode-charge-header-menu-right-element2">
                                        <Link to='/qna' className="text-link">
                                            {string.consulting}
                                        </Link>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="ksmartcode-charge-header-hide">
                            <div className="ksmartcode-charge-header-hide-menu">
                                <div className={containerClasses.join(' ')}>
                                    <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                                        <MenuButton2 state={!this.state.open} /></div>
                                    <div className={"sidenav"}>
                                        <a href="/">{string.homePage}</a>
                                        <a href="./intro">{string.intro}</a>
                                        <a href="./companyintro">{string.safetyTitle}</a>
                                        <a href="./serviceuse">{string.serviceUse}</a>
                                        <a href="./enteruse">{string.customUse}</a>
                                        <a href="./charge">{string.serviceCharge}</a>
                                        <a href="./marketing">{string.marketing}</a>
                                        <a href="./shopping">{string.shopping}</a>
                                        <a href="./nft">{string.nftMarket}</a>
                                        <a href="./customer">{string.customerCenter}</a>
                                        <a href="./qna">{string.consulting}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ksmartcode-charge-main">
                    <RevealSlide bottom>
                        <div className="ksmartcode-charge-main-guide">{string.chargeTitle}</div>
                    </RevealSlide>
                    <RevealSlide bottom>
                        <div className="ksmartcode-charge-main-mainText">{string.chargeMainTitle}</div>
                    </RevealSlide>
                    <div className="ksmartcode-charge-main-all">
                        <div className="ksmartcode-charge-main-allfield">
                            {/* 이벤트 필드 */}
                            <RevealFade bottom duration={1500}>
                            <div className="ksmartcode-charge-main-field">
                                <div className="ksmartcode-charge-main-field-event">
                                    <div className="ksmartcode-charge-main-number">{string.enteruseChargeContentNumberEvent}</div>
                                    <div className="ksmartcode-charge-main-numbertext">{string.enteruseChargeContentTitleEvent}</div>
                                </div>
                                <div className="ksmartcode-charge-main-field-content-event">
                                    <div className="ksmartcode-charge-main-enter-text1">
                                        {string.enteruseChargeContent1ContentEvent1}
                                    </div>
                                    <div className="ksmartcode-charge-main-enter-cancel">
                                        {string.enteruseChargeContent1Content2}
                                    </div>
                                    <div>
                                        <div className="ksmartcode-charge-main-enter-main-event">
                                        {string.enteruseChargeContent1ContentEvent3}
                                        </div>
                                        <div className="ksmartcode-charge-main-enter-sub">
                                        {string.enteruseChargeContent1Content4}
                                        </div>
                                        <div className="ksmartcode-charge-main-enter-amount">
                                        {string.enteruseChargeContent1Content5}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </RevealFade>
                            {/* 첫번째 필드 */}
                            <RevealFade bottom duration={1500}>
                                <div className="ksmartcode-charge-main-field">
                                    <div className="ksmartcode-charge-main-field-01">
                                        <div className="ksmartcode-charge-main-number">{string.chargeContentNumber1}</div>
                                        <div className="ksmartcode-charge-main-numbertext">{string.chargeContentTitle1}</div>
                                    </div>
                                    <div className="ksmartcode-charge-main-field-content">
                                        <div className="ksmartcode-charge-main-enter-text1">
                                            {string.chargeContent1Content1}
                                        </div>
                                        <div className="ksmartcode-charge-main-enter-cancel">
                                            {string.chargeContent1Content2}
                                        </div>

                                        <div>
                                            <div className="ksmartcode-charge-main-enter-main">
                                                {string.chargeContent1Content3}
                                            </div>
                                            <div className="ksmartcode-charge-main-enter-sub">
                                                {string.chargeContent1Content4}
                                            </div>
                                            <div className="ksmartcode-charge-main-enter-amount">
                                                {string.chargeContent1Content5}
                                            </div>
                                        </div>

                                        <div className="ksmartcode-charge-main-enter-text2">
                                            {string.chargeContent1Content6}
                                        </div>
                                        <div className="ksmartcode-charge-main-enter-cancel2">
                                            {string.chargeContent1Content7}
                                        </div>

                                        <div>
                                            <div className="ksmartcode-charge-main-enter-main">
                                                {string.chargeContent1Content8}
                                            </div>
                                            <div className="ksmartcode-charge-main-enter-sub">
                                                {string.chargeContent1Content9}
                                            </div>
                                            <div className="ksmartcode-charge-main-enter-amount">
                                                {string.chargeContent1Content10}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RevealFade>
                            {/* 두번째필드 */}
                            <RevealFade bottom duration={1500}>
                                <div className="ksmartcode-charge-main-field">
                                    <div className="ksmartcode-charge-main-field-02">
                                        <div className="ksmartcode-charge-main-number">{string.chargeContentNumber2}</div>
                                        <div className="ksmartcode-charge-main-numbertext">{string.chargeContentTitle2}</div>
                                    </div>
                                    <div className="ksmartcode-charge-main-field-content">
                                        <div className="ksmartcode-charge-main-user-text">
                                            {string.chargeContentTitle2Sub}
                                        </div>

                                        <div>
                                            <div className="ksmartcode-charge-main-user-main-text1">
                                                {string.chargeContent2Content1}
                                            </div>
                                            <div className="ksmartcode-charge-main-user-main-text2">
                                                {string.chargeContent2Content2}
                                            </div>
                                            <div className="ksmartcode-charge-main-user-main-text3">
                                                {string.chargeContent2Content3}
                                            </div>
                                            <div className="ksmartcode-charge-main-user-main-text4">
                                                {string.chargeContent2Content4}
                                            </div>
                                        </div>

                                        <div>
                                            <div className="ksmartcode-charge-main-user-main-text5">
                                                {string.chargeContent2Content5}
                                            </div>
                                            <div className="ksmartcode-charge-main-user-main-text6">
                                                {string.chargeContent2Content6}
                                            </div>
                                            <div className="ksmartcode-charge-main-user-main-text7">
                                                {string.chargeContent2Content7}
                                            </div>
                                            <div className="ksmartcode-charge-main-user-main-text8">
                                                {string.chargeContent2Content8}
                                            </div>
                                        </div>

                                        <div>
                                            <div className="ksmartcode-charge-main-user-main-text9">
                                                {string.chargeContent2Content9}
                                            </div>
                                            <div className="ksmartcode-charge-main-user-main-text10">
                                                {string.chargeContent2Content10}
                                            </div>
                                            <div className="ksmartcode-charge-main-user-main-text11">
                                                {string.chargeContent2Content11}
                                            </div>
                                            <div className="ksmartcode-charge-main-user-main-text12">
                                                {string.chargeContent2Content12}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </RevealFade>
                            {/* 세번째필드 */}
                            <RevealFade bottom duration={1500}>
                                <div className="ksmartcode-charge-main-field">
                                    <div className="ksmartcode-charge-main-field-03">
                                        <div className="ksmartcode-charge-main-number">{string.chargeContentNumber3}</div>
                                        <div className="ksmartcode-charge-main-numbertext">{string.chargeContentTitle3}</div>
                                    </div>
                                    <div className="ksmartcode-charge-main-field-content">
                                        <div className="ksmartcode-charge-main-independent-main">
                                            {string.chargeContent3Content1}
                                            <div className="ksmartcode-charge-main-independent-sub1">
                                                {string.chargeContent3Content2}
                                            </div>
                                            <div className="ksmartcode-charge-main-independent-sub1">
                                                {string.chargeContent3Content3}
                                            </div>
                                            <div className="ksmartcode-charge-main-independent-sub2">
                                                {string.chargeContent3Content4}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RevealFade>
                        </div>
                    </div>
                </div>

                <div className="ksmartcode-footer">
                    <div className="ksmartcode-footer-all">
                        <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
                        <div className="ksmartcode-footer-address">{string.addressInfo}</div>
                        <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
                        <div className="ksmartcode-footer-term1">
                            <Link to='/terms' offset='70' className="text-link5">
                                {string.terms1}
                            </Link>
                        </div>
                        <div className="ksmartcode-footer-term2">
                            <Link to='/privacy' offset='70' className="text-link5">
                                {string.terms2}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string
    };
};
export default withAlert()(connect(mapStateToProps)(Nft));
