import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { Form, Input, Button, } from "antd";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealFade from 'react-reveal/Fade';
import RevealSlide from 'react-reveal/Slide';

class Privacy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            popupVisible: false,
            open: false,
        }
    }

    ChangeNav = () => {
        this.setState(
            prevState => ({ open: !prevState.open })
        )
    };
    setLanguage = (lang) => {
        this.props.setLanguage(lang)
    }
    render() {
        const containerClasses = [];
        if (this.state.open) {
            containerClasses.push("open")
        }
        const { language, string } = this.props;

        return (
            <div className="ksmartcode-privacy">
                <div className="ksmartcode-privacy-header">
                    <div className="ksmartcode-privacy-header-all">
                        <div className="ksmartcode-privacy-header-left">
                            <Link to='/' className="text-link">
                                <img src={require('../../img/kslogo2.png')}
                                    className="header-logo" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="ksmartcode-privacy-main">
                    <div className="ksmartcode-privacy-main-all">
                        {/* 개인정보 보호정책 */}
                        <div>
                            <div className="ksmartcode-privacy-main-text-title">
                                {string.privacyPolicy}
                            </div>
                            <div className="ksmartcode-privacy-main-text-sub">
                                {string.privacyPolicysub}
                            </div>
                            <div className="ksmartcode-privacy-main-text-line" />
                            {/* 제1조 */}
                            <div className="ksmartcode-privacy-main-content">
                                <div className="ksmartcode-privacy-main-content-element1">
                                    {string.Policy1title}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element2">
                                    {string.Policy1sub}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy1content1text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element5">
                                    <div className="ksmartcode-privacy-main-content-alp">
                                        {string.articleAlp1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alp-text">
                                        {string.Policy1element1text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy1element1textsub1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy1element1textsub2}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element5">
                                    <div className="ksmartcode-privacy-main-content-alp">
                                        {string.articleAlp2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alp-text">
                                        {string.Policy1element2text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy1element2textsub1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy1element2textsub2}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element5">
                                    <div className="ksmartcode-privacy-main-content-alp">
                                        {string.articleAlp3}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alp-text">
                                        {string.Policy1element3text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy1element3textsub1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy1element3textsub2}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element5">
                                    <div className="ksmartcode-privacy-main-content-alp">
                                        {string.articleAlp4}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alp-text">
                                        {string.Policy1element4text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy1element4textsub1}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element5">
                                    <div className="ksmartcode-privacy-main-content-alp">
                                        {string.articleAlp5}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alp-text">
                                        {string.Policy1element5text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy1element5textsub1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy1element5textsub2}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy1content2text}
                                    </div>
                                </div>
                            </div>

                            {/* 제2조 */}
                            <div className="ksmartcode-privacy-main-content">
                                <div className="ksmartcode-privacy-main-content-element1">
                                    {string.Policy2title}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element2">
                                    {string.Policy2sub1}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element3">
                                    {string.Policy2sub2}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy2content1text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin3-text">
                                        {string.Policy2content1textsub}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy2content2text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin3-text">
                                        {string.Policy2content2textsub}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum3}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy2content3text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin3-text">
                                        {string.Policy2content3textsub}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum4}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy2content4text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin3-text">
                                        {string.Policy2content4textsub}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum5}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy2content5text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin3-text">
                                        {string.Policy2content5textsub}
                                    </div>
                                </div>
                            </div>
                            {/* 제3조 */}
                            <div className="ksmartcode-privacy-main-content">
                                <div className="ksmartcode-privacy-main-content-element1">
                                    {string.Policy3title}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element2">
                                    {string.Policy3sub1}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element3">
                                    {string.Policy3sub2}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy3content1text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-box">
                                    <div className="ksmartcode-privacy-main-content-block">
                                        <div className="ksmartcode-privacy-main-content-block-menu1">
                                            {string.Policy3block1}
                                        </div>
                                        <div className="ksmartcode-privacy-main-content-block-menu2">
                                            {string.Policy3block2}
                                        </div>
                                        <div className="ksmartcode-privacy-main-content-block-menu3">
                                            {string.Policy3block3}
                                        </div>
                                        <div className="ksmartcode-privacy-main-content-block-menu4">
                                            {string.Policy3block4}
                                        </div>
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-block-sub1">
                                    <div className="ksmartcode-privacy-main-content-block-menu1">
                                        {string.Policy3blocktext1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block-menu2">
                                        {string.Policy3blocktext2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block-menu3">
                                        {string.Policy3blocktext3}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block-menu4">
                                        {string.Policy3blocktext4}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-block-sub2">
                                    <div className="ksmartcode-privacy-main-content-block-menu1">
                                        {string.Policy3blocktext5}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block-menu2">
                                        {string.Policy3blocktext6}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block-menu3">
                                        {string.Policy3blocktext7}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block-menu4">
                                        {string.Policy3blocktext8}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-block-sub3">
                                    <div className="ksmartcode-privacy-main-content-block-menu1">
                                        {string.Policy3blocktext9}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block-menu2">
                                        {string.Policy3blocktext10}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block-menu3">
                                        {string.Policy3blocktext11}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block-menu4">
                                        {string.Policy3blocktext12}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-subnum">
                                        {string.articleNum1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-subtext">
                                        {string.Policy3content1subtext1}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-subnum">
                                        {string.articleNum2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-subtext">
                                        {string.Policy3content1subtext2}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy3content2text}
                                    </div>
                                </div>

                                <div className="ksmartcode-privacy-main-content-box">
                                    <div className="ksmartcode-privacy-main-content-block">
                                        <div className="ksmartcode-privacy-main-content-block2-menu1">
                                            {string.Policy3block5}, {string.Policy3block6}
                                        </div>
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-block-sub4">
                                    <div className="ksmartcode-privacy-main-content-block2-menu1">
                                        {string.Policy3blocktext13}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block2-menu2">
                                        {string.Policy3blocktext14}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-block-sub4">
                                    <div className="ksmartcode-privacy-main-content-block2-menu1">
                                        {string.Policy3blocktext15}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block2-menu2">
                                        {string.Policy3blocktext16}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-block-sub4">
                                    <div className="ksmartcode-privacy-main-content-block2-menu1">
                                        {string.Policy3blocktext17}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block2-menu2">
                                        {string.Policy3blocktext18}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-block-sub4">
                                    <div className="ksmartcode-privacy-main-content-block2-menu1">
                                        {string.Policy3blocktext19}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block2-menu2">
                                        {string.Policy3blocktext20}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-block-sub4">
                                    <div className="ksmartcode-privacy-main-content-block2-menu1">
                                        {string.Policy3blocktext21}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block2-menu2">
                                        {string.Policy3blocktext22}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum3}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy3content3text}
                                    </div>
                                </div>
                            </div>
                            {/* 제4조 */}
                            <div className="ksmartcode-privacy-main-content">
                                <div className="ksmartcode-privacy-main-content-element1">
                                    {string.Policy4title}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element2">
                                    {string.Policy4sub1}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element3">
                                    {string.Policy4sub2}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy4content1text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element5">
                                    <div className="ksmartcode-privacy-main-content-alp">
                                        {string.articleAlp1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alp-text">
                                        {string.Policy4element1text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy4element1textsub1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy4element1textsub2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy4element1textsub3}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy4element1textsub4}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element5">
                                    <div className="ksmartcode-privacy-main-content-alp">
                                        {string.articleAlp2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alp-text">
                                        {string.Policy4element2text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy4element2textsub1}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element5">
                                    <div className="ksmartcode-privacy-main-content-alp">
                                        {string.articleAlp3}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alp-text">
                                        {string.Policy4element3text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin-text">
                                        {string.Policy4element3textsub1}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy4content2text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum3}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy4content3text}
                                    </div>
                                </div>
                            </div>
                            {/* 제5조 */}
                            <div className="ksmartcode-privacy-main-content">
                                <div className="ksmartcode-privacy-main-content-element1">
                                    {string.Policy5title}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element2">
                                    {string.Policy5sub}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy5content1text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy5content2text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum3}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy5content3text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum4}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy5content4text}
                                    </div>
                                </div>
                            </div>
                            {/* 제6조 */}
                            <div className="ksmartcode-privacy-main-content">
                                <div className="ksmartcode-privacy-main-content-element1">
                                    {string.Policy6title}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element2">
                                    {string.Policy6sub1}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element3">
                                    {string.Policy6sub2}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin2-text">
                                        {string.Policy6elementtextsub1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin2-text">
                                        {string.Policy6elementtextsub2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin2-text">
                                        {string.Policy6elementtextsub3}
                                    </div>
                                </div>
                            </div>
                            {/* 제7조 */}
                            <div className="ksmartcode-privacy-main-content">
                                <div className="ksmartcode-privacy-main-content-element1">
                                    {string.Policy7title}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element2">
                                    {string.Policy7sub1}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element3">
                                    {string.Policy7sub2}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin2-text">
                                        {string.Policy6elementtextsub1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin2-text">
                                        {string.Policy6elementtextsub2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin2-text">
                                        {string.Policy6elementtextsub3}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-box">
                                    <div className="ksmartcode-privacy-main-content-block">
                                        <div className="ksmartcode-privacy-main-content-block2-menu1">
                                            {string.Policy7block1}
                                        </div>
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-block-sub4">
                                    <div className="ksmartcode-privacy-main-content-block2-menu1">
                                        {string.Policy7blocktext1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block2-menu2">
                                        {string.Policy7blocktext2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block2-menu2">
                                        {string.Policy7blocktext3}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-block2-menu2">
                                        {string.Policy7blocktext4}
                                    </div>
                                </div>
                            </div>
                            {/* 제8조 */}
                            <div className="ksmartcode-privacy-main-content">
                                <div className="ksmartcode-privacy-main-content-element1">
                                    {string.Policy8title}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element2">
                                    {string.Policy8sub}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy8content1text}
                                    </div>
                                </div>
                                <div className="ksmartcode-privacy-main-content-element4">
                                    <div className="ksmartcode-privacy-main-content-num">
                                        {string.articleNum2}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-num-text">
                                        {string.Policy8content2text}
                                    </div>
                                </div>
                            </div>
                            {/* 제9조 */}
                            <div className="ksmartcode-privacy-main-content">
                                <div className="ksmartcode-privacy-main-content-element1">
                                    {string.Policy9title}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element2">
                                    {string.Policy9sub1}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element3">
                                    {string.Policy9sub2}
                                </div>
                                <div className="ksmartcode-privacy-main-content-element6">
                                    <div className="ksmartcode-privacy-main-content-alpin2-text">
                                        {string.Policy9elementtextsub1}
                                    </div>
                                    <div className="ksmartcode-privacy-main-content-alpin2-text">
                                        {string.Policy9elementtextsub2}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="ksmartcode-footer2">
                    <div className="ksmartcode-footer2-all">
                        <div className="ksmartcode-footer2-ceo">{string.ceoInfo}</div>
                        <div className="ksmartcode-footer2-address">{string.addressInfo}</div>
                        <div className="ksmartcode-footer2-custom">{string.emailInfo}</div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string
    };
};
export default withAlert()(connect(mapStateToProps)(Privacy));