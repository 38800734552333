export default {
  appName: 'ksmartcode',

  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "30001",

  serverProtocol: "https",
  serverIp: "back.e-smartcode.com",
  serverPort: "443",

  clientIp: "localhost",
  clientPort: "3000",

};
