import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealFade from 'react-reveal/Fade';
import Rotate from 'react-reveal/Rotate';
import Tada from 'react-reveal/Tada';

class Marketing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupVisible: false,
      open: false
    }
  }
  ChangeNav = () => {
    this.setState(
      prevState => ({ open: !prevState.open })
    )
  };
  setLanguage = (lang) => {
    this.props.setLanguage(lang)
  }
  render() {
    const containerClasses = [];
    if (this.state.open) {
      containerClasses.push("open")
    }
    const { language, string } = this.props;

    return (
      <div className="ksmartcode-marketing">
        <div className="ksmartcode-marketing-header">
          <div className="ksmartcode-marketing-header-all">
            <div className="ksmartcode-marketing-header-left">
              <Link to='/' className="text-link">
                <img src={require('../../img/kslogo2.png')}
                  className="header-logo" />
              </Link>
            </div>
            <div className="ksmartcode-marketing-header-menu">
              <div className="ksmartcode-marketing-header-menu-center">
                <div className="ksmartcode-marketing-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.blockchainService}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/intro' className="text-link2">
                        {string.intro}
                      </Link>
                      <Link to='/companyIntro' className="text-link2">
                        {string.safetyTitle}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-marketing-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.serviceAll}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/serviceUse' className="text-link2">
                        {string.serviceUse}
                      </Link>
                      <Link to='/enterUse' className="text-link2">
                        {string.customUse}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-marketing-header-menu-center-element">
                  <Link to='/Charge' className="text-link2">
                    {string.serviceCharge}
                  </Link>
                </div>
                <div className="ksmartcode-marketing-header-menu-center-element">
                  <Link to='/marketing' className="text-link2">
                    {string.marketing}
                  </Link>
                </div>
                <div className="ksmartcode-marketing-header-menu-center-element">
                  <Link to='/shopping' offset='70' className="text-link2">
                    {string.shopping}
                  </Link>
                </div>
                <div className="ksmartcode-marketing-header-menu-center-element">
                  <Link to='/nft' offset='70' className="text-link2">
                    {string.nftMarket}
                  </Link>
                </div>
              </div>
              <div className="ksmartcode-marketing-header-menu-right">
                <div className="ksmartcode-marketing-header-menu-right-element">
                  <Link to='/customer' className="text-link3">
                    {string.customerCenter}
                  </Link>
                </div>
                <Link to='/qna' className="text-link">
                  <div className="ksmartcode-marketing-header-menu-right-element2">
                    <Link to='/qna' className="text-link">
                      {string.consulting}
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="ksmartcode-marketing-header-hide">
              <div className="ksmartcode-marketing-header-hide-menu">
                <div className={containerClasses.join(' ')}>
                  <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                    <MenuButton2 state={!this.state.open} /></div>
                  <div className={"sidenav"}>
                    <a href="/">{string.homePage}</a>
                    <a href="./intro">{string.intro}</a>
                    <a href="./companyintro">{string.safetyTitle}</a>
                    <a href="./serviceuse">{string.serviceUse}</a>
                    <a href="./enteruse">{string.customUse}</a>
                    <a href="./charge">{string.serviceCharge}</a>
                    <a href="./marketing">{string.marketing}</a>
                    <a href="./shopping">{string.shopping}</a>
                    <a href="./nft">{string.nftMarket}</a>
                    <a href="./customer">{string.customerCenter}</a>
                    <a href="./qna">{string.consulting}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 고객관리 마케팅 첫번째 섹션 */}
        <div className="ksmartcode-marketing-info">
          <div className="ksmartcode-marketing-info-all">
            <RevealFade bottom duration={1000}>
              <div className="ksmartcode-marketing-info-guide">{string.managementMarketing}</div>
            </RevealFade>
            <RevealFade bottom duration={1500}>
              <div className="ksmartcode-marketing-info-text-title">
                {string.infoExcess1}
                <br />
                {string.infoExcess2}
                <br />
                {string.infoExcess3}
              </div>
            </RevealFade>
            <div className="ksmartcode-marketing-info-content1">
              <RevealFade bottom duration={1500}>
                <div className="ksmartcode-marketing-info-content-element">{string.infoExcessSub1}</div>
              </RevealFade>
              <RevealFade bottom duration={1500} delay={500}>
                <div className="ksmartcode-marketing-info-content-element">{string.infoExcessSub2}</div>
              </RevealFade>
              <RevealFade bottom duration={1500} delay={1000}>
                <div className="ksmartcode-marketing-info-content-element">{string.infoExcessSub3}</div>
              </RevealFade>
              <RevealFade bottom duration={1500} delay={1500}>
                <div className="ksmartcode-marketing-info-content-element">{string.infoExcessSub4}</div>
              </RevealFade>
            </div>
            <div className="ksmartcode-marketing-info-content-image">
              <Rotate bottom right duration={1500} delay={2000}>
                <div className="ksmartcode-marketing-info-content-image-content1">
                  {string.purchaseRegist}
                  <br />
                  {string.variousCRM}
                </div>
              </Rotate>
              <Rotate bottom right duration={1500} delay={2500}>
                <div className="ksmartcode-marketing-info-content-image-content2">{string.infoAcquisition}</div>
              </Rotate>
              <Rotate bottom left duration={1500} delay={3000}>
                <div className="ksmartcode-marketing-info-content-image-content2">{string.analysis}</div>
              </Rotate>
              <Rotate bottom left duration={1500} delay={3500}>
                <div className="ksmartcode-marketing-info-content-image-content3">{string.marketing}</div>
              </Rotate>
            </div>
            <RevealFade bottom duration={1500}>
              <div className="ksmartcode-marketing-info-content2">
                <div className="ksmartcode-marketing-info-content-element">{string.blockChainUtil1}</div>
                <div className="ksmartcode-marketing-info-content-element">{string.blockChainUtil2}</div>
                <div className="ksmartcode-marketing-info-content-element">{string.blockChainUtil3}</div>
                <div className="ksmartcode-marketing-info-content-element">{string.blockChainUtil4}</div>
                <div className="ksmartcode-marketing-info-content-element">{string.blockChainUtil5}</div>
              </div>
            </RevealFade>
            {/* 고객관리 마케팅 두번째 섹션 */}
            <div className="ksmartcode-marketing-userinfo">
              <RevealFade bottom duration={1000}>
                <img src={require('../../img/marketing/marketing1.png')}
                  className="marketing-userinfo-section2-img" />
              </RevealFade>
              <RevealFade bottom duration={1000} delay={500}>
                <div className="ksmartcode-marketing-userinfo-text-title">
                  {string.eventInfo1}
                  <br />
                  {string.eventInfo2}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000} delay={1000}>
                <div className="ksmartcode-marketing-userinfo-text-sub">
                  {string.eventInfoSub1}
                  <br />
                  {string.eventInfoSub2}
                </div>
              </RevealFade>
            </div>
            {/* 고객관리 마케팅 세번째 섹션 */}
            <div className="ksmartcode-marketing-event">
              <RevealFade duration={1000} delay={1000}>
                <div className="ksmartcode-marketing-event-content1">
                  <Tada duration={1000} delay={2000}>
                    <img src={require('../../img/marketing/alarm.png')}
                      className="marketing-event-section3-img1" />
                  </Tada>
                  <div className="ksmartcode-marketing-event-content1-text">
                    {string.specialEvent1}
                    <br />
                    {string.specialEvent2}
                  </div>
                </div>
              </RevealFade>
              <img src={require('../../img/marketing/marketing2.png')}
                className="marketing-event-section3-img2" />
              <RevealFade duration={1000} delay={3000}>
                <div className="ksmartcode-marketing-event-content2">
                  <Tada duration={1000} delay={4000}>
                    <img src={require('../../img/marketing/alarm.png')}
                      className="marketing-event-section3-img3" />
                  </Tada>
                  <div className="ksmartcode-marketing-event-content2-text">
                    {string.birthdayEvent1}
                    <br />
                    {string.birthdayEvent2}
                  </div>
                </div>
              </RevealFade>
            </div>
            <div className="ksmartcode-marketing-event-element-all">
              {string.exemple}
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-marketing-event-element-content">{string.exempleSub1}</div>
              </RevealFade>
              <RevealFade bottom duration={1000} delay={500}>
                <div className="ksmartcode-marketing-event-element-content">{string.exempleSub2}</div>
              </RevealFade>
              <RevealFade bottom duration={1000} delay={1000}>
                <div className="ksmartcode-marketing-event-element-content">{string.exempleSub3}</div>
              </RevealFade>
              <RevealFade bottom duration={1000} delay={1500}>
                <div className="ksmartcode-marketing-event-element-content">{string.exempleSub4}</div>
              </RevealFade>
              <RevealFade bottom duration={1000} delay={2000}>
                <div className="ksmartcode-marketing-event-element-content">{string.exempleSub5}</div>
              </RevealFade>
            </div>

            <div className="ksmartcode-marketing-event-footer">
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-marketing-event-footer-element">{string.infoExchange1}</div>
                <div className="ksmartcode-marketing-event-footer-element">{string.infoExchange2}</div>
                <div className="ksmartcode-marketing-event-footer-element">{string.infoExchange3}</div>
              </RevealFade>
            </div>
          </div>
        </div>

        <div className="ksmartcode-footer">
          <div className="ksmartcode-footer-all">
            <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
            <div className="ksmartcode-footer-address">{string.addressInfo}</div>
            <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
            <div className="ksmartcode-footer-term1">
              <Link to='/terms' offset='70' className="text-link5">
                {string.terms1}
              </Link>
            </div>
            <div className="ksmartcode-footer-term2">
              <Link to='/privacy' offset='70' className="text-link5">
                {string.terms2}
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};
export default withAlert()(connect(mapStateToProps)(Marketing));
