import React from "react";
import { connect } from "react-redux";
import "../../css/index.scss";
import "../../css/index_m.scss";

class MenuButton extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const containerClasses=["container"];
        if (!this.props.state){
          containerClasses.push("close")
        }
        return (
          <span className={containerClasses.join(' ')}>
            <div className={"bar1"}></div>
            <div className={"bar2"}></div>
            <div className={"bar3"}></div>
          </span>
        );
    }
  }

export default MenuButton;