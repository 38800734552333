import React from 'react';
// import './App.css';
import { Route } from 'react-router-dom';

import Main from './components/page/Main';
import Customer from './components/page/Customer';
import Marketing from './components/page/Marketing';
import ServiceUse from './components/page/ServiceUse';
import Shopping from './components/page/Shopping';
import Intro from './components/page/Intro';
import EnterUse from './components/page/EnterUse';
import CompanyIntro from './components/page/CompanyIntro';
import Qna from './components/page/Qna';
import Charge from './components/page/Charge';
import Nft from './components/page/Nft';
import Terms from './components/page/Terms';
import Privacy from './components/page/Privacy';

import ScrollToTop from './components/util/ScrollToTop';

function App() {
  return (
    <div>
      <ScrollToTop>
        <Route exact path="/" component={Main} />
        <Route path="/Customer" component={Customer} />
        <Route path="/Marketing" component={Marketing} />
        <Route path="/ServiceUse" component={ServiceUse} />
        <Route path="/Shopping" component={Shopping} />
        <Route path="/Intro" component={Intro} />
        <Route path="/EnterUse" component={EnterUse} />
        <Route path="/CompanyIntro" component={CompanyIntro} />
        <Route path="/Qna" component={Qna} />
        <Route path="/Charge" component={Charge} />
        <Route path="/Nft" component={Nft} />
        <Route path="/Terms" component={Terms} />
        <Route path="/Privacy" component={Privacy} />
      </ScrollToTop>
    </div>
  );
}

export default App;
