import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealFade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

class Shopping extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupVisible: false,
      open: false
    }
  }
  ChangeNav = () => {
    this.setState(
      prevState => ({ open: !prevState.open })
    )
  };
  setLanguage = (lang) => {
    this.props.setLanguage(lang)
  }
  render() {
    const containerClasses = [];
    if (this.state.open) {
      containerClasses.push("open")
    }
    const { language, string } = this.props;
    return (
      <div className="ksmartcode-shopping">
        <div className="ksmartcode-shopping-header">
          <div className="ksmartcode-shopping-header-all">
            <div className="ksmartcode-shopping-header-left">
              <Link to='/' className="text-link">
                <img src={require('../../img/kslogo2.png')}
                  className="header-logo" />
              </Link>
            </div>
            <div className="ksmartcode-shopping-header-menu">
              <div className="ksmartcode-shopping-header-menu-center">
                <div className="ksmartcode-shopping-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.blockchainService}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/intro' className="text-link2">
                        {string.intro}
                      </Link>
                      <Link to='/companyIntro' className="text-link2">
                        {string.safetyTitle}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-shopping-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.serviceAll}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/serviceUse' className="text-link2">
                        {string.serviceUse}
                      </Link>
                      <Link to='/enterUse' className="text-link2">
                        {string.customUse}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-shopping-header-menu-center-element">
                  <Link to='/Charge' className="text-link2">
                    {string.serviceCharge}
                  </Link>
                </div>
                <div className="ksmartcode-shopping-header-menu-center-element">
                  <Link to='/marketing' className="text-link2">
                    {string.marketing}
                  </Link>
                </div>
                <div className="ksmartcode-shopping-header-menu-center-element">
                  <Link className="text-link2">
                    {string.shopping}
                  </Link>
                </div>
                <div className="ksmartcode-shopping-header-menu-center-element">
                  <Link to='/nft' offset='70' className="text-link2">
                    {string.nftMarket}
                  </Link>
                </div>
              </div>
              <div className="ksmartcode-shopping-header-menu-right">
                <div className="ksmartcode-shopping-header-menu-right-element">
                  <Link to='/customer' className="text-link3">
                    {string.customerCenter}
                  </Link>
                </div>
                <Link to='/qna' className="text-link">
                  <div className="ksmartcode-shopping-header-menu-right-element2">
                    <Link to='/qna' className="text-link">
                      {string.consulting}
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="ksmartcode-shopping-header-hide">
              <div className="ksmartcode-shopping-header-hide-menu">
                <div className={containerClasses.join(' ')}>
                  <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                    <MenuButton2 state={!this.state.open} /></div>
                  <div className={"sidenav"}>
                    <a href="/">{string.homePage}</a>
                    <a href="./intro">{string.intro}</a>
                    <a href="./companyintro">{string.safetyTitle}</a>
                    <a href="./serviceuse">{string.serviceUse}</a>
                    <a href="./enteruse">{string.customUse}</a>
                    <a href="./charge">{string.serviceCharge}</a>
                    <a href="./marketing">{string.marketing}</a>
                    <a href="./shopping">{string.shopping}</a>
                    <a href="./nft">{string.nftMarket}</a>
                    <a href="./customer">{string.customerCenter}</a>
                    <a href="./qna">{string.consulting}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 쇼핑몰 첫번째 섹션 */}
        <div className="ksmartcode-shopping-entry">
          <div className="ksmartcode-shopping-entry-all">
            <div className="ksmartcode-shopping-entry-guide">
              <RevealFade left duration={1000}>
                <div className="ksmartcode-shopping-entry-subText">
                  {string.shopping}
                </div>
              </RevealFade>
              <RevealFade left duration={1000} delay={500}>
                <div className="ksmartcode-shopping-entry-mainText">
                  {string.customerBelive1}
                  <br />
                  {string.customerBelive2}
                </div>
              </RevealFade>
            </div>
            <RevealFade duration={1000} delay={1500}>
              <div className="ksmartcode-shopping-entry-image">
                <Slide right duration={2000} delay={1500}>
                  <img src={require('../../img/shopping/shopping1.png')}
                    className="shopping-entry-section1-img" />
                </Slide>
                {string.useFee}
              </div>
            </RevealFade>
          </div>
        </div>
        {/* 쇼핑몰 두번째 섹션 */}
        <div className="ksmartcode-shopping-info">
          <div className="ksmartcode-shopping-info-all">
            <div className="ksmartcode-shopping-info-text-title">{string.blockChainQr1}</div>
            <div className="ksmartcode-shopping-info-text-title">{string.blockChainQr2}</div>
            <div className="ksmartcode-shopping-info-content">
              <div className="ksmartcode-shopping-info-content-element">
                {string.blockChainQrSub1}
              </div>
              <div className="ksmartcode-shopping-info-content-element">
                {string.blockChainQrSub2}
              </div>
            </div>

            <div className="ksmartcode-shopping-info2">
              <img src={require('../../img/shopping/shopping2.png')}
                className="shopping-info2-img" />
              <div className="ksmartcode-shopping-info2-element">
                {string.shoppingMallCurrency1}
                <br />
                {string.shoppingMallCurrency2}
                <br />
                {string.shoppingMallCurrency3}
              </div>
            </div>
            <div className="ksmartcode-shopping-info3">
              <img src={require('../../img/shopping/shopping3.png')}
                className="shopping-info3-img" />
            </div>
          </div>
        </div>

        <div className="ksmartcode-footer">
          <div className="ksmartcode-footer-all">
            <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
            <div className="ksmartcode-footer-address">{string.addressInfo}</div>
            <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
            <div className="ksmartcode-footer-term1">
              <Link to='/terms' offset='70' className="text-link5">
                {string.terms1}
              </Link>
            </div>
            <div className="ksmartcode-footer-term2">
              <Link to='/privacy' offset='70' className="text-link5">
                {string.terms2}
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};
export default withAlert()(connect(mapStateToProps)(Shopping));
