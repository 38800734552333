import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { Form, Input, Button, } from "antd";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealFade from 'react-reveal/Fade';
import RevealSlide from 'react-reveal/Slide';

class Nft extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            popupVisible: false,
            open: false
        }
        this.formRef = React.createRef();
    }

    OnCreateInquiry = (e) => {
        let info = JSON.stringify({
            ...this.formRef.current.getFieldsValue(),
            //추가 지정해서 보내기
            category: 'MAIN',
            title: '문의/제안서',
            content: '업종:' + this.formRef.current.getFieldValue('job')
        });

        httpPost(httpUrl.inquiryCreate, [], info).then(response => {
            console.log('result=' + JSON.stringify(response.data))
            if (response.data == "SUCCESS") {
                alert('문의가 성공적으로 접수되었습니다. 빠른시일내 연락드리겠습니다.')
                this.formRef.current.resetFields();
            }
            else {
                alert('알수없는 문제로 문의에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
            }
        }).catch(e => {
            console.log('err=' + JSON.stringify(e))
        })

    }

    ChangeNav = () => {
        this.setState(
            prevState => ({ open: !prevState.open })
        )
    };
    setLanguage = (lang) => {
        this.props.setLanguage(lang)
    }
    render() {
        const containerClasses = [];
        if (this.state.open) {
            containerClasses.push("open")
        }
        const { language, string } = this.props;

        return (
            <div className="ksmartcode-nft">
                <div className="ksmartcode-nft-header">
                    <div className="ksmartcode-nft-header-all">
                        <div className="ksmartcode-nft-header-left">
                            <Link to='/' className="text-link">
                                <img src={require('../../img/kslogo2.png')}
                                    className="header-logo" />
                            </Link>
                        </div>
                        <div className="ksmartcode-nft-header-menu">
                            <div className="ksmartcode-nft-header-menu-center">
                                <div className="ksmartcode-nft-header-menu-center-element">
                                    <div class="dropdown-page">
                                        <div class="dropdown-page-div">
                                            {string.blockchainService}
                                        </div>
                                        <div class="dropdown-page-content">
                                            <Link to='/intro' className="text-link2">
                                                {string.intro}
                                            </Link>
                                            <Link to='/companyIntro' className="text-link2">
                                                {string.safetyTitle}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="ksmartcode-nft-header-menu-center-element">
                                    <div class="dropdown-page">
                                        <div class="dropdown-page-div">
                                            {string.serviceAll}
                                        </div>
                                        <div class="dropdown-page-content">
                                            <Link to='/serviceUse' className="text-link2">
                                                {string.serviceUse}
                                            </Link>
                                            <Link to='/enterUse' className="text-link2">
                                                {string.customUse}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="ksmartcode-nft-header-menu-center-element">
                                    <Link to='/Charge' className="text-link2">
                                        {string.serviceCharge}
                                    </Link>
                                </div>
                                <div className="ksmartcode-nft-header-menu-center-element">
                                    <Link to='/marketing' className="text-link2">
                                        {string.marketing}
                                    </Link>
                                </div>
                                <div className="ksmartcode-nft-header-menu-center-element">
                                    <Link to='/shopping' offset='70' className="text-link2">
                                        {string.shopping}
                                    </Link>
                                </div>
                                <div className="ksmartcode-nft-header-menu-center-element">
                                    <Link className="text-link2">
                                        {string.nftMarket}
                                    </Link>
                                </div>
                            </div>
                            <div className="ksmartcode-nft-header-menu-right">
                                <div className="ksmartcode-nft-header-menu-right-element">
                                    <Link to='/customer' className="text-link3">
                                        {string.customerCenter}
                                    </Link>
                                </div>
                                <Link to='/qna' className="text-link">
                                    <div className="ksmartcode-nft-header-menu-right-element2">
                                        <Link to='/qna' className="text-link">
                                            {string.consulting}
                                        </Link>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="ksmartcode-nft-header-hide">
                            <div className="ksmartcode-nft-header-hide-menu">
                                <div className={containerClasses.join(' ')}>
                                    <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                                        <MenuButton2 state={!this.state.open} /></div>
                                    <div className={"sidenav"}>
                                        <a href="/">{string.homePage}</a>
                                        <a href="./intro">{string.intro}</a>
                                        <a href="./companyintro">{string.safetyTitle}</a>
                                        <a href="./serviceuse">{string.serviceUse}</a>
                                        <a href="./enteruse">{string.customUse}</a>
                                        <a href="./charge">{string.serviceCharge}</a>
                                        <a href="./marketing">{string.marketing}</a>
                                        <a href="./shopping">{string.shopping}</a>
                                        <a href="./nft">{string.nftMarket}</a>
                                        <a href="./customer">{string.customerCenter}</a>
                                        <a href="./qna">{string.consulting}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ksmartcode-nft-main" id='nft'>
                    <div className="ksmartcode-nft-main-all">
                        <RevealFade bottom duration={2000}>
                            <div className="ksmartcode-nft-main-text-title">{string.nftMarket}</div>
                        </RevealFade>
                        <div className="ksmartcode-nft-main-content">
                            <RevealFade bottom duration={2000}>
                                <div className="ksmartcode-nft-main-content-element1">
                                    {string.shoppingMallNft1}
                                    <br />
                                    {string.shoppingMallNft2}
                                </div>
                            </RevealFade>
                            <RevealFade bottom duration={2000}>
                                <div className="ksmartcode-nft-main-content-element2">
                                    {string.shoppingMallNft3}
                                    <br />
                                    {string.shoppingMallNft4}
                                    <br />
                                    {string.shoppingMallNft5}
                                    <br />
                                    {string.shoppingMallNft6}
                                </div>
                            </RevealFade>
                        </div>
                        <RevealFade bottom duration={2000}>
                            <div className="ksmartcode-nft-main-img">
                                <img src={require('../../img/shopping/nft1.png')}
                                    className="nft-main-img" />
                                <div className="ksmartcode-nft-main-img-element">
                                    {string.shoppingMallNft7}
                                </div>
                            </div>
                        </RevealFade>
                        <RevealFade bottom duration={2000}>
                            <div className="ksmartcode-nft-main-img">
                                <img src={require('../../img/shopping/nft2.png')}
                                    className="nft-main-img" />
                                <div className="ksmartcode-nft-main-img-element">
                                    {string.shoppingMallNft8}
                                </div>
                            </div>
                        </RevealFade>
                    </div>
                </div>

                <div className="ksmartcode-footer">
                    <div className="ksmartcode-footer-all">
                        <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
                        <div className="ksmartcode-footer-address">{string.addressInfo}</div>
                        <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
                        <div className="ksmartcode-footer-term1">
                            <Link to='/terms' offset='70' className="text-link5">
                                {string.terms1}
                            </Link>
                        </div>
                        <div className="ksmartcode-footer-term2">
                            <Link to='/privacy' offset='70' className="text-link5">
                                {string.terms2}
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string
    };
};
export default withAlert()(connect(mapStateToProps)(Nft));
