import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealFade from 'react-reveal/Fade';

class CompanyIntro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupVisible: false,
      open: false
    }
  }
  ChangeNav = () => {
    this.setState(
      prevState => ({ open: !prevState.open })
    )
  };
  setLanguage = (lang) => {
    this.props.setLanguage(lang)
  }
  render() {
    const containerClasses = [];
    if (this.state.open) {
      containerClasses.push("open")
    }
    const { language, string } = this.props;

    return (
      <div className="ksmartcode-companyintro">
        <div className="ksmartcode-companyintro-header">
          <div className="ksmartcode-companyintro-header-all">
            <div className="ksmartcode-companyintro-header-left">
              <Link to='/' className="text-link">
                <img src={require('../../img/kslogo2.png')}
                  className="header-logo" />
              </Link>
            </div>
            <div className="ksmartcode-companyintro-header-menu">
              <div className="ksmartcode-companyintro-header-menu-center">
                <div className="ksmartcode-serviceuse-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.blockchainService}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/intro' className="text-link2">
                        {string.intro}
                      </Link>
                      <Link to='/companyIntro' className="text-link2">
                        {string.safetyTitle}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-companyintro-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.serviceAll}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/serviceUse' className="text-link2">
                        {string.serviceUse}
                      </Link>
                      <Link to='/enterUse' className="text-link2">
                        {string.customUse}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-companyintro-header-menu-center-element">
                  <Link to='/Charge' className="text-link2">
                    {string.serviceCharge}
                  </Link>
                </div>
                <div className="ksmartcode-companyintro-header-menu-center-element">
                  <Link to='/marketing' className="text-link2">
                    {string.marketing}
                  </Link>
                </div>
                <div className="ksmartcode-companyintro-header-menu-center-element">
                  <Link to='/shopping' offset='70' className="text-link2">
                    {string.shopping}
                  </Link>
                </div>
                <div className="ksmartcode-companyintro-header-menu-center-element">
                  <Link to='/nft' offset='70' className="text-link2">
                    {string.nftMarket}
                  </Link>
                </div>
              </div>
              <div className="ksmartcode-companyintro-header-menu-right">
                <div className="ksmartcode-companyintro-header-menu-right-element">
                  <Link to='/customer' className="text-link3">
                    {string.customerCenter}
                  </Link>
                </div>
                <Link to='/qna' className="text-link">
                  <div className="ksmartcode-companyintro-header-menu-right-element2">
                    <Link to='/qna' className="text-link">
                      {string.consulting}
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="ksmartcode-companyintro-header-hide">
              <div className="ksmartcode-companyintro-header-hide-menu">
                <div className={containerClasses.join(' ')}>
                  <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                    <MenuButton2 state={!this.state.open} /></div>
                  <div className={"sidenav"}>
                    <a href="/">{string.homePage}</a>
                    <a href="./intro">{string.intro}</a>
                    <a href="./companyintro">{string.safetyTitle}</a>
                    <a href="./serviceuse">{string.serviceUse}</a>
                    <a href="./enteruse">{string.customUse}</a>
                    <a href="./charge">{string.serviceCharge}</a>
                    <a href="./marketing">{string.marketing}</a>
                    <a href="./shopping">{string.shopping}</a>
                    <a href="./nft">{string.nftMarket}</a>
                    <a href="./customer">{string.customerCenter}</a>
                    <a href="./qna">{string.consulting}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 케이스마트코드안내 첫번째 섹션 */}
        <div className="ksmartcode-companyintro-info">
          <div className="ksmartcode-companyintro-info-all">
            <RevealFade duration={2000}>
              <div className="ksmartcode-companyintro-info-guide">
                {string.manualTitle}
              </div>
              <div className="ksmartcode-companyintro-info-text-title">
                {string.manualMain1}
                <br />
                {string.manualMain2}
              </div>
              <div className="ksmartcode-companyintro-realtime-content">
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub1}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub2}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub3}</div>
              </div>
              {/* 케이스마트코드안내 두번째 섹션 */}
              <div className="ksmartcode-companyintro-realtime">
                <img src={require('../../img/companyintro/company1.png')}
                  className="companyintro-realtime-section3-img" />
              </div>
              <div className="ksmartcode-companyintro-realtime-content">
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub4}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub5}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub6}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub7}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub8}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub9}</div>
              </div>
              <div className="ksmartcode-companyintro-realtime">
                <img src={require('../../img/companyintro/company2.png')}
                  className="companyintro-realtime-section3-img" />
              </div>
              <div className="ksmartcode-companyintro-realtime-content">
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub10}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub11}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub12}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub13}</div>
              </div>
              <div className="ksmartcode-companyintro-realtime">
                <img src={require('../../img/companyintro/company3.png')}
                  className="companyintro-realtime-section3-img" />
              </div>
              <div className="ksmartcode-companyintro-realtime-content">
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub14}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub15}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub16}</div>
                <div className="ksmartcode-companyintro-realtime-content-element">{string.manualSub17}</div>
              </div>

            </RevealFade>
          </div>
        </div>

        <div className="ksmartcode-footer">
          <div className="ksmartcode-footer-all">
            <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
            <div className="ksmartcode-footer-address">{string.addressInfo}</div>
            <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
            <div className="ksmartcode-footer-term1">
              <Link to='/terms' offset='70' className="text-link5">
                {string.terms1}
              </Link>
            </div>
            <div className="ksmartcode-footer-term2">
              <Link to='/privacy' offset='70' className="text-link5">
                {string.terms2}
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};
export default withAlert()(connect(mapStateToProps)(CompanyIntro));
