import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { Form, Input, Button, } from "antd";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealFade from 'react-reveal/Fade';
import RevealSlide from 'react-reveal/Slide';

class Terms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            popupVisible: false,
            open: false,
            selectedType: 0,
        }
    }

    ChangeNav = () => {
        this.setState(
            prevState => ({ open: !prevState.open })
        )
    };
    setLanguage = (lang) => {
        this.props.setLanguage(lang)
    }
    render() {
        const containerClasses = [];
        if (this.state.open) {
            containerClasses.push("open")
        }
        const { language, string } = this.props;

        return (
            <div className="ksmartcode-terms">
                <div className="ksmartcode-terms-header">
                    <div className="ksmartcode-terms-header-all">
                        <div className="ksmartcode-terms-header-left">
                            <Link to='/' className="text-link">
                                <img src={require('../../img/kslogo2.png')}
                                    className="header-logo" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="ksmartcode-terms-main">
                    <div className="ksmartcode-terms-main-all">
                        <div className="ksmartcode-terms-main-menu">
                            <div className="ksmartcode-terms-main-menu-content1" onClick={() => this.setState({ selectedType: 0 })}>
                                {string.termPartner}
                            </div>
                            <div className="ksmartcode-terms-main-menu-content2" onClick={() => this.setState({ selectedType: 1 })}>
                                {string.termUser}
                            </div>
                        </div>
                        {/* 서비스 이용약관 ( 파트너용 ) */}
                        {this.state.selectedType == 0 && (
                            <div>
                                <div className="ksmartcode-terms-main-text-title">
                                    {string.termPartner}
                                </div>
                                {/* 제1조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle1title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle1sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element3">
                                        {string.partnerArticle1content1}
                                        <br />
                                        {string.partnerArticle1content2}
                                    </div>
                                </div>
                                {/* 제2조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle2title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle2sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element3">
                                        {string.partnerArticle2content1}
                                        {string.partnerArticle2content2}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle2element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle2element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle2element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle2element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle2element5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle2element6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle2element7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle2element8Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제3조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle3title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle3sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle3content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle3content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle3content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle3content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle3content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle3content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle3content7Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제4조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle4title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle4sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle4content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle4element1Text}
                                            {string.partnerArticle4element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle4element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle4element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle4content2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제5조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle5title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle5sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle5content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle5content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle5content3Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제6조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle6title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle6sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle6content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle6content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle6element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle6element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle6element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle6element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle6content3Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제7조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle7title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle7sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle7content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle7content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle7content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle7content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle7content5Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제8조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle8title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle8sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle8content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle8content2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제9조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle9title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle9sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle9content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle9content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle9content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle9content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle9content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle9content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle9content7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle9content8Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum9}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle9content9Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제10조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle10title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle10sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle10content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle10content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle10content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle10content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle10content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle10content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle10content7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle10element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle10element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle10element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle10element5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle10element6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle10element7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle10element8Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle10content8Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum9}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle10content9Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum10}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle10content10Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제11조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle11title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle11sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle11content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle11content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle11content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle11content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle11content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle11content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle11content7Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제12조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle12title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle12sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content8Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum9}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content9Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum10}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content10Text}
                                        </div>
                                    </div>

                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle12element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle12element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle12element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle12element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle12element5Text}
                                        </div>
                                    </div>

                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum11}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle12content11Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제13조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle13title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle13sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle13content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle13content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle13content3Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제14조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle14title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle14sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle14content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle14content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle14content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle14content4Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제15조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle15title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle15sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle15content}
                                        </div>
                                    </div>
                                </div>
                                {/* 제16조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle16title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle16sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle16content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle16content2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제17조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle17title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle17sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle17content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle17content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle17content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle17content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle17element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle17element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle17element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle17content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle17element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle17element5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle17element6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle17element7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle17content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle17content7Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제18조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle18title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle18sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle18content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle18content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle18content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle18content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle18content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle18content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle18content7Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제19조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle19title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle19sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle19content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle19content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle19content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle19content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle19content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle19content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle19content7Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제20조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle20title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle20sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle20content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle20element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.partnerArticle20element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle20content2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제21조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle21title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle21sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle21content}
                                        </div>
                                    </div>
                                </div>
                                {/* 제22조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.partnerArticle22title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticle2sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle22content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle22content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticle22content3Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 부칙 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.partnerArticleLast}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.partnerArticleDate}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        )}

                        {/* 서비스 이용약관 ( 사용자용 ) */}
                        {this.state.selectedType == 1 && (
                            <div>
                                <div className="ksmartcode-terms-main-text-title">
                                    {string.termUser}
                                </div>
                                {/* 제1조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle1title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle1sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element3">
                                        {string.userArticle1content1}
                                        <br />
                                        {string.userArticle1content2}
                                    </div>
                                </div>
                                {/* 제2조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle2title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle2sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element3">
                                        {string.partnerArticle2content1}
                                        {string.partnerArticle2content2}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle2element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            <a href="/www.ksmartcode.com/">
                                                {string.userArticle2element2Text}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle2element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle2element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle2element5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle2element6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle2element7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle2element8Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum9}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle2element9Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum10}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle2element10Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum11}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle2element11Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제3조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle3title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle3sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle3element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle3element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle3element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle3element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle3element5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle3element6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle3element7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle3element8Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제4조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle4title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle4sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle4content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle4element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle4element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle4content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle4content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle4content4Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제5조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle5title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle5sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle5content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle5content2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제6조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle6title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle6sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle6content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle6content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle6element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle6element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle6element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle6element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle6element5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle6element6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle6element7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle6content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle6content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle6content5Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제7조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle7title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle7sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle7content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle7content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle7element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle7element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle7element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle7element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle7element5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle7element6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle7element7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle7element8Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp9}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle7element9Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle7content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle7content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle7content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle7content6Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제8조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle8title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle8sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle8content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle8content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle8content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle8content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle8content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle8content6Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제9조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle9title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle9sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle9content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle9content2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제10조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle10title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle10sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle10content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle10content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle10content3Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제11조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle11title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle11sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle11content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle11content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle11content3Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제12조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle12title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle12sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle12content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle12content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle12content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle12content4Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제13조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle13title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle13sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle13content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle13element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle13element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle13element5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle13element6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle13element7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle13element8Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle13element10Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle13element11Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp9}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle13element12Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle13content2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제14조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle14title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle14sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content8Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum9}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content9Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum10}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content10Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle14element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle14element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle14element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle14element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle14element5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum11}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle14content11Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제15조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle15title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle15sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle15content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle15content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle15content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle15content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle15content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle15content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle15content7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle15content8Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum9}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle15content9Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제16조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle16title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle16sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle16content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle16content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle16content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle16element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle16element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle16element3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle16element4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle16content4Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제17조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle17title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle17sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle17content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle17content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle17element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle17element2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제18조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle18title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle18sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle18content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle18content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle18content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle18content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle18content5Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제19조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle19title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle19sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle19content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle19content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle19content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle19content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle19content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle19content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle19content7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle19content8Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제20조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle20title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle20sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle20content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle20content2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제21조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle21title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle21sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle21content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle21content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle21content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle21content4Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제22조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle22title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle22sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle22content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle22content2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle22element1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element5">
                                        <div className="ksmartcode-terms-main-content-alp">
                                            {string.articleAlp2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-alp-text">
                                            {string.userArticle22element2Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum3}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle22content3Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum4}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle22content4Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum5}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle22content5Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum6}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle22content6Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum7}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle22content7Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum8}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle22content8Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum9}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle22content9Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum10}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle22content10Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제23조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle23title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle23sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle23content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle23content2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 제24조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle24title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle24sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle24content}
                                        </div>
                                    </div>
                                </div>
                                {/* 제25조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle25title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle25sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle25content}
                                        </div>
                                    </div>
                                </div>
                                {/* 제26조 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element1">
                                        {string.userArticle26title}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticle26sub}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum1}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle26content1Text}
                                        </div>
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num">
                                            {string.articleNum2}
                                        </div>
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticle26content2Text}
                                        </div>
                                    </div>
                                </div>
                                {/* 부칙 */}
                                <div className="ksmartcode-terms-main-content">
                                    <div className="ksmartcode-terms-main-content-element2">
                                        {string.userArticleLast}
                                    </div>
                                    <div className="ksmartcode-terms-main-content-element4">
                                        <div className="ksmartcode-terms-main-content-num-text">
                                            {string.userArticleDate}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        )}
                    </div>
                </div>
                <div className="ksmartcode-footer2">
                    <div className="ksmartcode-footer2-all">
                        <div className="ksmartcode-footer2-ceo">{string.ceoInfo}</div>
                        <div className="ksmartcode-footer2-address">{string.addressInfo}</div>
                        <div className="ksmartcode-footer2-custom">{string.emailInfo}</div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string
    };
};
export default withAlert()(connect(mapStateToProps)(Terms));