import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealFade from 'react-reveal/Fade';

class ServiceUse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupVisible: false,
      open: false
    }
  }
  ChangeNav = () => {
    this.setState(
      prevState => ({ open: !prevState.open })
    )
  };
  setLanguage = (lang) => {
    this.props.setLanguage(lang)
  }
  render() {
    const containerClasses = [];
    if (this.state.open) {
      containerClasses.push("open")
    }
    const { language, string } = this.props;

    return (
      <div className="ksmartcode-serviceuse">
        <div className="ksmartcode-serviceuse-header">
          <div className="ksmartcode-serviceuse-header-all">
            <div className="ksmartcode-serviceuse-header-left">
              <Link to='/' className="text-link">
                <img src={require('../../img/kslogo2.png')}
                  className="header-logo" />
              </Link>
            </div>
            <div className="ksmartcode-serviceuse-header-menu">
              <div className="ksmartcode-serviceuse-header-menu-center">
                <div className="ksmartcode-serviceuse-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.blockchainService}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/intro' className="text-link2">
                        {string.intro}
                      </Link>
                      <Link to='/companyIntro' className="text-link2">
                        {string.safetyTitle}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-serviceuse-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.serviceAll}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/serviceUse' className="text-link2">
                        {string.serviceUse}
                      </Link>
                      <Link to='/enterUse' className="text-link2">
                        {string.customUse}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-serviceuse-header-menu-center-element">
                  <Link to='/Charge' className="text-link2">
                    {string.serviceCharge}
                  </Link>
                </div>
                <div className="ksmartcode-serviceuse-header-menu-center-element">
                  <Link to='/marketing' className="text-link2">
                    {string.marketing}
                  </Link>
                </div>
                <div className="ksmartcode-serviceuse-header-menu-center-element">
                  <Link to='/shopping' offset='70' className="text-link2">
                    {string.shopping}
                  </Link>
                </div>
                <div className="ksmartcode-serviceuse-header-menu-center-element">
                  <Link to='/nft' offset='70' className="text-link2">
                    {string.nftMarket}
                  </Link>
                </div>
              </div>
              <div className="ksmartcode-serviceuse-header-menu-right">
                <div className="ksmartcode-serviceuse-header-menu-right-element">
                  <Link to='/customer' className="text-link3">
                    {string.customerCenter}
                  </Link>
                </div>
                <Link to='/qna' className="text-link">
                  <div className="ksmartcode-serviceuse-header-menu-right-element2">
                    <Link to='/qna' className="text-link">
                      {string.consulting}
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="ksmartcode-serviceuse-header-hide">
              <div className="ksmartcode-serviceuse-header-hide-menu">
                <div className={containerClasses.join(' ')}>
                  <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                    <MenuButton2 state={!this.state.open} /></div>
                  <div className={"sidenav"}>
                    <a href="/">{string.homePage}</a>
                    <a href="./intro">{string.intro}</a>
                    <a href="./companyintro">{string.safetyTitle}</a>
                    <a href="./serviceuse">{string.serviceUse}</a>
                    <a href="./enteruse">{string.customUse}</a>
                    <a href="./charge">{string.serviceCharge}</a>
                    <a href="./marketing">{string.marketing}</a>
                    <a href="./shopping">{string.shopping}</a>
                    <a href="./nft">{string.nftMarket}</a>
                    <a href="./customer">{string.customerCenter}</a>
                    <a href="./qna">{string.consulting}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 서비스 이용방법 첫번째 섹션 */}
        <div className="ksmartcode-serviceuse-info">
          <div className="ksmartcode-serviceuse-info-all">
            <RevealFade duration={2000}>
              <div className="ksmartcode-serviceuse-info-guide">{string.serviceUseApp}</div>
              <div className="ksmartcode-serviceuse-info-text-title">
                {string.exactBlockChain1}
                <br />
                {string.exactBlockChain2}
              </div>
              <div className="ksmartcode-serviceuse-info-content">
                <div className="ksmartcode-serviceuse-info-content-element1">{string.exactBlockChainSub1}</div>
                <div className="ksmartcode-serviceuse-info-content-element1">{string.exactBlockChainSub2}</div>
                <div className="ksmartcode-serviceuse-info-content-element1">{string.exactBlockChainSub3}</div>
                <div className="ksmartcode-serviceuse-info-content-element1">{string.exactBlockChainSub4}</div>
              </div>
              <div className="ksmartcode-serviceuse-info-content-image">
                <div className="ksmartcode-serviceuse-info-content-image-content1">
                  <img src={require('../../img/serviceuse/serviceUse1.png')}
                    className="serviceuse-info-section1-img" />
                </div>
                <div className="ksmartcode-serviceuse-info-content-image-content2">
                  <img src={require('../../img/serviceuse/serviceUse2.png')}
                    className="serviceuse-info-section1-img" />
                </div>
              </div>
              <div className="ksmartcode-serviceuse-info-content-element2">{string.exactBlockChainContent1}</div>
              <div className="ksmartcode-serviceuse-info-content-element2">{string.exactBlockChainContent2}</div>
              {/* 서비스 이용방법 두번째 섹션 */}
              <div className="ksmartcode-serviceuse-scan-content">
                <div className="ksmartcode-serviceuse-scan-content-image1">
                  <img src={require('../../img/serviceuse/serviceUse3.png')}
                    className="serviceuse-scan-section2-img" />
                </div>
                <div className="ksmartcode-serviceuse-scan-content-image2">
                  <img src={require('../../img/serviceuse/serviceUse4.png')}
                    className="serviceuse-scan-section2-img" />
                </div>
              </div>
              <div className="ksmartcode-serviceuse-scan-content-element">{string.exactBlockChainContent3}</div>
              <div className="ksmartcode-serviceuse-scan-content-element">{string.exactBlockChainContent4}</div>
              <div className="ksmartcode-serviceuse-scan-content-element">{string.exactBlockChainContent5}</div>
              {/* 서비스 이용방법 세번째 섹션 */}
              <div className="ksmartcode-serviceuse-realtime">
                <img src={require('../../img/serviceuse/serviceUse5.png')}
                  className="serviceuse-realtime-section3-img" />
              </div>
              <div className="ksmartcode-serviceuse-realtime-content">
                <div className="ksmartcode-serviceuse-realtime-content-element">{string.exactBlockChainContent6}</div>
                <div className="ksmartcode-serviceuse-realtime-content-element">{string.exactBlockChainContent7}</div>
                <div className="ksmartcode-serviceuse-realtime-content-element">{string.exactBlockChainContent8}</div>
              </div>
            </RevealFade>
          </div>
        </div>

        <div className="ksmartcode-footer">
          <div className="ksmartcode-footer-all">
            <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
            <div className="ksmartcode-footer-address">{string.addressInfo}</div>
            <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
            <div className="ksmartcode-footer-term1">
              <Link to='/terms' offset='70' className="text-link5">
                {string.terms1}
              </Link>
            </div>
            <div className="ksmartcode-footer-term2">
              <Link to='/privacy' offset='70' className="text-link5">
                {string.terms2}
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};
export default withAlert()(connect(mapStateToProps)(ServiceUse));
