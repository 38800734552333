import { combineReducers } from "redux";
import { SET_LANGUAGE } from "../actions";

import StringKo from "../language/StringKo";
import StringEn from "../language/StringEn";
import StringJa from "../language/StringJa";

function getLanguage() {
  return navigator.language || navigator.userLanguage;
}
let lang = getLanguage();
if (lang) lang = lang.split("-")[0];
if (lang != 'ko' && lang != 'en' && lang != 'ja') lang = 'ko';

const initState = {
  language: lang,
  string: lang === "ja"
    ? StringJa
    : lang === "en"
    ? StringEn
    : StringKo
};

const stringReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return Object.assign(
        {},
        {
          language: action.language,
          string:
            action.language === "ja"
              ? StringJa
              : action.language === "en"
              ? StringEn
              : StringKo
        }
      );
    default:
      return state;
  }
};

const reducer = combineReducers({ stringReducer });

export default reducer;
