import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealSlide from 'react-reveal/Slide';
import RevealFade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';

class EnterUse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupVisible: false,
      open: false
    }
  }
  ChangeNav = () => {
    this.setState(
      prevState => ({ open: !prevState.open })
    )
  };
  setLanguage = (lang) => {
    this.props.setLanguage(lang)
  }
  render() {
    const containerClasses = [];
    if (this.state.open) {
      containerClasses.push("open")
    }
    const { language, string } = this.props;
    return (
      <div className="ksmartcode-enteruse">
        <div className="ksmartcode-enteruse-header">
          <div className="ksmartcode-enteruse-header-all">
            <div className="ksmartcode-enteruse-header-left">
              <Link to='/' className="text-link">
                <img src={require('../../img/kslogo2.png')}
                  className="header-logo" />
              </Link>
            </div>
            <div className="ksmartcode-enteruse-header-menu">
              <div className="ksmartcode-enteruse-header-menu-center">
                <div className="ksmartcode-enteruse-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.blockchainService}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/intro' className="text-link2">
                        {string.intro}
                      </Link>
                      <Link to='/companyIntro' className="text-link2">
                        {string.safetyTitle}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-enteruse-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.serviceAll}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/serviceUse' className="text-link2">
                        {string.serviceUse}
                      </Link>
                      <Link to='/enterUse' className="text-link2">
                        {string.customUse}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-enteruse-header-menu-center-element">
                  <Link to='/Charge' className="text-link2">
                    {string.serviceCharge}
                  </Link>
                </div>
                <div className="ksmartcode-enteruse-header-menu-center-element">
                  <Link to='/marketing' className="text-link2">
                    {string.marketing}
                  </Link>
                </div>
                <div className="ksmartcode-enteruse-header-menu-center-element">
                  <Link to='/shopping' offset='70' className="text-link2">
                    {string.shopping}
                  </Link>
                </div>
                <div className="ksmartcode-enteruse-header-menu-center-element">
                  <Link to='/nft' offset='70' className="text-link2">
                    {string.nftMarket}
                  </Link>
                </div>
              </div>
              <div className="ksmartcode-enteruse-header-menu-right">
                <div className="ksmartcode-enteruse-header-menu-right-element">
                  <Link to='/customer' className="text-link3">
                    {string.customerCenter}
                  </Link>
                </div>
                <Link to='/qna' className="text-link">
                  <div className="ksmartcode-enteruse-header-menu-right-element2">
                    <Link to='/qna' className="text-link">
                      {string.consulting}
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="ksmartcode-enteruse-header-hide">
              <div className="ksmartcode-enteruse-header-hide-menu">
                <div className={containerClasses.join(' ')}>
                  <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                    <MenuButton2 state={!this.state.open} /></div>
                  <div className={"sidenav"}>
                    <a href="/">{string.homePage}</a>
                    <a href="./intro">{string.intro}</a>
                    <a href="./companyintro">{string.safetyTitle}</a>
                    <a href="./serviceuse">{string.serviceUse}</a>
                    <a href="./enteruse">{string.customUse}</a>
                    <a href="./charge">{string.serviceCharge}</a>
                    <a href="./marketing">{string.marketing}</a>
                    <a href="./shopping">{string.shopping}</a>
                    <a href="./nft">{string.nftMarket}</a>
                    <a href="./customer">{string.customerCenter}</a>
                    <a href="./qna">{string.consulting}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 기업회원이용방법 첫번째 섹션 */}
        <div className="ksmartcode-enteruse-qr">
          <div className="ksmartcode-enteruse-qr-all">
            <div className="ksmartcode-enteruse-qr-guide">
              <RevealFade bottom duration={1500}>
                <div className="ksmartcode-enteruse-qr-text-title1">{string.enteruseQrTitle}</div>
              </RevealFade>
              <RevealFade bottom duration={1500} delay={500}>
                <div className="ksmartcode-enteruse-qr-text-title2">{string.enteruseQrSub1}
                  <div className="ksmartcode-enteruse-qr-text-title3">
                    {string.enteruseQrSub2}
                  </div>
                </div>
              </RevealFade>
            </div>
            <RevealFade bottom duration={1500} delay={1000}>
              <img src={require('../../img/enteruse/enteruse1.png')}
                className="enteruse-qr-img" />
            </RevealFade>
          </div>
        </div>
        {/* 기업회원이용방법 두번째 섹션 */}
        <div className="ksmartcode-enteruse-charge" id='serviceCharge'>
          <div className="ksmartcode-enteruse-charge-guide">
            <RevealFade bottom duration={2000}>
              <div className="ksmartcode-enteruse-charge-guide-element">
                {string.enteruseNftText1}
                <br />
                {string.enteruseNftText2}
                <br />
                {string.enteruseNftText3}
              </div>
            </RevealFade>
          </div>
          <RevealSlide bottom>
            <div className="ksmartcode-enteruse-charge-mainText">{string.enteruseChargeTitle}</div>
          </RevealSlide>
          <div className="ksmartcode-enteruse-charge-all">
            <div className="ksmartcode-enteruse-charge-allfield">
              {/* 이벤트 필드 */}
              <RevealFade bottom duration={1500}>
                <div className="ksmartcode-enteruse-charge-field">
                  <div className="ksmartcode-enteruse-charge-field-event">
                    <div className="ksmartcode-enteruse-charge-number-event">{string.enteruseChargeContentNumberEvent}</div>
                    <div className="ksmartcode-enteruse-charge-numbertext">{string.enteruseChargeContentTitleEvent}</div>
                  </div>
                  <div className="ksmartcode-enteruse-charge-field-content-event">
                    <div className="ksmartcode-enteruse-charge-enter-text1">
                      {string.enteruseChargeContent1ContentEvent1}
                    </div>
                    <div className="ksmartcode-enteruse-charge-enter-cancel">
                      {string.enteruseChargeContent1Content2}
                    </div>
                    <div>
                      <div className="ksmartcode-enteruse-charge-enter-main-event">
                        {string.enteruseChargeContent1ContentEvent3}
                      </div>
                      <div className="ksmartcode-enteruse-charge-enter-sub">
                        {string.enteruseChargeContent1Content4}
                      </div>
                      <div className="ksmartcode-enteruse-charge-enter-amount">
                        {string.enteruseChargeContent1Content5}
                      </div>
                    </div>
                  </div>
                </div>
              </RevealFade>
              {/* 첫번째 필드 */}
              <RevealFade bottom duration={1500}>
                <div className="ksmartcode-enteruse-charge-field">
                  <div className="ksmartcode-enteruse-charge-field-01">
                    <div className="ksmartcode-enteruse-charge-number">{string.enteruseChargeContentNumber1}</div>
                    <div className="ksmartcode-enteruse-charge-numbertext">{string.enteruseChargeContentTitle1}</div>
                  </div>
                  <div className="ksmartcode-enteruse-charge-field-content">
                    <div className="ksmartcode-enteruse-charge-enter-text1">
                      {string.enteruseChargeContent1Content1}
                    </div>
                    <div className="ksmartcode-enteruse-charge-enter-cancel">
                      {string.enteruseChargeContent1Content2}
                    </div>
                    <div>
                      <div className="ksmartcode-enteruse-charge-enter-main">
                        {string.enteruseChargeContent1Content3}
                      </div>
                      <div className="ksmartcode-enteruse-charge-enter-sub">
                        {string.enteruseChargeContent1Content4}
                      </div>
                      <div className="ksmartcode-enteruse-charge-enter-amount">
                        {string.enteruseChargeContent1Content5}
                      </div>
                    </div>

                    <div className="ksmartcode-enteruse-charge-enter-text2">
                      {string.enteruseChargeContent1Content6}
                    </div>
                    <div className="ksmartcode-enteruse-charge-enter-cancel2">
                      {string.enteruseChargeContent1Content7}
                    </div>

                    <div>
                      <div className="ksmartcode-enteruse-charge-enter-main">
                        {string.enteruseChargeContent1Content8}
                      </div>
                      <div className="ksmartcode-enteruse-charge-enter-sub">
                        {string.enteruseChargeContent1Content9}
                      </div>
                      <div className="ksmartcode-enteruse-charge-enter-amount">
                        {string.enteruseChargeContent1Content10}
                      </div>
                    </div>
                  </div>
                </div>
              </RevealFade>
              {/* 두번째필드 */}
              <RevealFade bottom duration={2000}>
                <div className="ksmartcode-enteruse-charge-field">
                  <div className="ksmartcode-enteruse-charge-field-02">
                    <div className="ksmartcode-enteruse-charge-number">{string.chargeContentNumber2}</div>
                    <div className="ksmartcode-enteruse-charge-numbertext">{string.enteruseChargeContentTitle2}</div>
                  </div>
                  <div className="ksmartcode-enteruse-charge-field-content">
                    <div className="ksmartcode-enteruse-charge-user-text">
                      {string.enteruseChargeContentTitle2Sub}
                    </div>

                    <div>
                      <div className="ksmartcode-enteruse-charge-user-main-text1">
                        {string.enteruseChargeContent2Content1}
                      </div>
                      <div className="ksmartcode-enteruse-charge-user-main-text2">
                        {string.enteruseChargeContent2Content2}
                      </div>
                      <div className="ksmartcode-enteruse-charge-user-main-text3">
                        {string.enteruseChargeContent2Content3}
                      </div>
                      <div className="ksmartcode-enteruse-charge-user-main-text4">
                        {string.enteruseChargeContent2Content4}
                      </div>
                    </div>

                    <div>
                      <div className="ksmartcode-enteruse-charge-user-main-text5">
                        {string.enteruseChargeContent2Content5}
                      </div>
                      <div className="ksmartcode-enteruse-charge-user-main-text6">
                        {string.enteruseChargeContent2Content6}
                      </div>
                      <div className="ksmartcode-enteruse-charge-user-main-text7">
                        {string.enteruseChargeContent2Content7}
                      </div>
                      <div className="ksmartcode-enteruse-charge-user-main-text8">
                        {string.enteruseChargeContent2Content8}
                      </div>
                    </div>

                    <div>
                      <div className="ksmartcode-enteruse-charge-user-main-text9">
                        {string.enteruseChargeContent2Content9}
                      </div>
                      <div className="ksmartcode-enteruse-charge-user-main-text10">
                        {string.enteruseChargeContent2Content10}
                      </div>
                      <div className="ksmartcode-enteruse-charge-user-main-text11">
                        {string.enteruseChargeContent2Content11}
                      </div>
                      <div className="ksmartcode-enteruse-charge-user-main-text12">
                        {string.enteruseChargeContent2Content12}
                      </div>
                    </div>

                  </div>
                </div>
              </RevealFade>
              {/* 세번째필드 */}
              <RevealFade bottom duration={2500}>
                <div className="ksmartcode-enteruse-charge-field">
                  <div className="ksmartcode-enteruse-charge-field-03">
                    <div className="ksmartcode-enteruse-charge-number">{string.chargeContentNumber3}</div>
                    <div className="ksmartcode-enteruse-charge-numbertext">{string.enteruseChargeContentTitle3}</div>
                  </div>
                  <div className="ksmartcode-enteruse-charge-field-content">
                    <div className="ksmartcode-enteruse-charge-independent-main">
                      {string.enteruseChargeContent3Content1}
                      <div className="ksmartcode-enteruse-charge-independent-sub1">
                        {string.enteruseChargeContent3Content2}
                      </div>
                      <div className="ksmartcode-enteruse-charge-independent-sub1">
                        {string.enteruseChargeContent3Content3}
                      </div>
                      <div className="ksmartcode-enteruse-charge-independent-sub2">
                        {string.enteruseChargeContent3Content4}
                      </div>
                    </div>
                  </div>
                </div>
              </RevealFade>
            </div>
          </div>
        </div>

        {/* 세번째 섹션 */}
        <div className="ksmartcode-enteruse-nft">
          <div className="ksmartcode-enteruse-nft-all">
            <div className="ksmartcode-enteruse-nft-content2">
              <RevealFade bottom duration={2000}>
                <div className="ksmartcode-enteruse-nft-content-element1">
                  {string.enteruseNftText4}
                  <br />
                  {string.enteruseNftText5}
                </div>
              </RevealFade>
            </div>
            <RevealFade duration={1500}>
              <div className="ksmartcode-enteruse-nft-img">
                <img src={require('../../img/enteruse/enteruse3.png')}
                  className="enteruse-nft-img1" />
              </div>
            </RevealFade>
            <Bounce top duration={2000}>
              <div className="ksmartcode-enteruse-nft-center">
                <img src={require('../../img/enteruse/bottom.png')}
                  className="enteruse-nft-img-center" />
              </div>
            </Bounce>
            <RevealFade duration={1500}>
              <div className="ksmartcode-enteruse-nft-img">
                <img src={require('../../img/enteruse/enteruse4.png')}
                  className="enteruse-nft-img2" />
              </div>
            </RevealFade>
          </div>
        </div>

        <div className="ksmartcode-footer">
          <div className="ksmartcode-footer-all">
            <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
            <div className="ksmartcode-footer-address">{string.addressInfo}</div>
            <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
            <div className="ksmartcode-footer-term1">
              <Link to='/terms' offset='70' className="text-link5">
                {string.terms1}
              </Link>
            </div>
            <div className="ksmartcode-footer-term2">
              <Link to='/privacy' offset='70' className="text-link5">
                {string.terms2}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};
export default withAlert()(connect(mapStateToProps)(EnterUse));
