const StringKo = {
  //공통
  android: "안드로이드",
  iphone: "아이폰",
  korean: "한국어",
  english: "영어",
  chinese: "중국어",
  japanese: "일본어",
  vetnam: "베트남어",
  use: "사용하기",
  guide: "이용가이드",
  franchise: "가맹점보기",
  ksmartCode: "E-Smart Code",
  homePage: "홈페이지",
  search: "검색",
  title: "제목",
  email: "E-Mail",
  content: "내용",
  agreement: "개인정보 수집 이용 동의",


  //header
  blockchainService: "블록체인 정품인증 서비스",
  intro: "회사 소개",
  serviceAll: "서비스 안내",
  serviceUse: "앱 이용 안내",
  customUse: "기업회원이용안내",
  serviceCharge: "서비스 요금",
  marketing: "고객 마케팅",
  shopping: "정품 쇼핑몰",
  asking: "문의/제안서",
  enterPriseLogin: "기업 로그인",
  customCenter: "고객센터",
  consulting: "상담 문의",
  nftMarket: "NFT 마켓",
  freeExperience: "무료체험",


  //   첫번째 섹션
  blockChainTitle1: "정품인증.강력하고 투명한",
  blockChainTitle2: "블록체인 QR 시스템",
  blockChainSub1: "새로운 정품인증의 서비스의 기준 E-Smart Code",
  blockChainTitle3: "블록체인의 강력한",
  blockChainTitle4: "보안성과 안전성",
  blockChainSub2: "QR시스템과의 연계로 정품인증의 한차원높은 인증서비스",
  blockChainTitle5: "손쉽게 언제어디서든",
  blockChainTitle6: "정품인증앱을 통한 진위여부판단",
  blockChainSub3: "고객의 높은 편의성과 신뢰성제공",
  blockChainTitle7: "정품인증과 동시에",
  blockChainTitle8: "일대일 고객마케팅구현",
  blockChainSub4: "정품인증몰과의 연계로 소비자의 높은신뢰성구축",

  //body

  //   두번째 섹션
  newsTitle: "뉴스활용 안내",
  newsMainTitle1: "이미 많은 기업들이 블록체인 시스템으로",
  newsMainTitle2: "정품인증을 도입하였습니다",
  newsContentMain1: "나이키, 블록체인 기반",
  newsContentSub1: "정품 인증 시스템 특허 취득",
  newsContentLink1: "기사로 이동 >",
  newsContentMain2: "뉴발란스, 카르다노 블록체인으로",
  newsContentSub2: "정품 인증한다",
  newsContentLink2: "기사로 이동 >",
  newsContentMainCenter1: "현대모비스, 블록체인 기술로 정품 인증관리한다",
  newsContentLinkCenter: "기사로 이동 >",
  newsContentMain3: "씨게이트-IBM, 블록체인 및 보안 기술",
  newsContentSub3: "활용해 하드 드라이브 위조 방지",
  newsContentLink3: "기사로 이동 >",
  newsContentMain4: "블록체인 시스템 도입한 까르푸,",
  newsContentSub4: "매출 올랐다",
  newsContentLink4: "기사로 이동 >",



  //   세번째 섹션
  safetyTitle: "E-Smart Code 안내",
  safetyMainTitle1: "블록체인의",
  safetyMainTitle2: "강력한 보안성과 안전성",
  safetySubTitle: "QR 시스템과의 연계로 정품인증의 한차원높은 인증서비스",
  safetyLink: "자세히 알아보기 >",

  //   네번째 섹션
  certTitle: "E-Smart Code App 이용방법",
  certMainTitle1: "손쉽게 언제어디서든",
  certMainTitle2: "진위여부판단",
  certSubTitle1: "손쉽게 언제어디서든 정품인증앱을 통한",
  certSubTitle2: "진위여부판단으로 고객의 높은 편의성과 신뢰성제공",
  certLink: "자세히 알아보기 >",

  //  다섯번째 섹션
  marketingTitle: "고객관리 마케팅",
  marketingMainTitle1: "정품인증과 동시에",
  marketingMainTitle2: "일대일 고객마케팅구현",
  marketingSubTitle: "정품인증몰과의 연계로 소비자의 높은신뢰성 구축",
  marketingLink: "자세히 알아보기 >",

  // 여섯번째 섹션
  shopTitle: "정품 쇼핑몰",
  shopMainTitle: "고객이 신뢰할수 있는 정품쇼핑몰에 입점하세요",
  shopContentMain1: "오직 블록체인 QR시스템을",
  shopContentSub1: "적용한 기업들만 입점해 있습니다.",
  shopContentFee: "이용 수수료 1%~15%",
  shopContentMain2: "정품 쇼핑몰은",
  shopContentSub2: "가상화폐결제도 가능합니다.",
  shopContentMain3: "정품 쇼핑몰은",
  shopContentSub3: "자체발행 코인도 지원합니다.",
  shopLink: "자세히 알아보기 >",

  //   일곱번째 섹션
  chargeTitle: "서비스이용요금",
  chargeMainTitle: "기업에 맞는 요금제를 확인해보세요",
  chargeContentNumber1: "01",
  chargeContentTitle1: "기업회원 요금제",
  chargeContent1Content1: "1년 약정",
  chargeContent1Content2: "",
  chargeContent1Content3: "50% 할인",
  chargeContent1Content4: "(별도문의)",
  chargeContent1Content5: "",
  chargeContent1Content6: "2년 약정",
  chargeContent1Content7: "",
  chargeContent1Content8: "60% 할인",
  chargeContent1Content9: "(별도문의)",
  chargeContent1Content10: "",

  chargeContentNumber2: "02",
  chargeContentTitle2: "개인회원 요금제",
  chargeContentTitle2Sub: "건당 500원 발급, 하루한건 무료",
  chargeContent2Content1: "10건",
  chargeContent2Content2: "",
  chargeContent2Content3: "20% 할인",
  chargeContent2Content4: "(별도문의)",
  chargeContent2Content5: "100건",
  chargeContent2Content6: "",
  chargeContent2Content7: "30% 할인",
  chargeContent2Content8: "(별도문의)",
  chargeContent2Content9: "1000건",
  chargeContent2Content10: "",
  chargeContent2Content11: "50% 할인",
  chargeContent2Content12: "(별도문의)",

  chargeContentNumber3: "03",
  chargeContentTitle3: "커스터마이징",
  chargeContent3Content1: "쇼핑몰.가상화폐 결제자연동",
  chargeContent3Content2: "자체가상화폐 결제연동 개발 별도협의",
  chargeContent3Content3: "가상화폐 결제연동 별도협의",
  chargeContent3Content4: "vat 별도 금액입니다.",

  //  여덟번째 섹션
  consultingTitle: "문의 / 제안서",
  consultingMain1: "지금 바로",
  consultingMain2: "무료상담 받아보세요",
  consultingSub: "연락처를 남겨주시면 상담사가 바로연락드리겠습니다",
  consultingContent1: "회사 이름",
  consultingContent2: "휴대전화 번호",
  consultingContent3: "지역  Ex)강남구 청담동",
  consultingContent4: "업종 ",
  consultingCheck: "개인정보 수집 이용 동의서",
  consultingButton: "상담 신청하기",

  //Footer
  ceoInfo: "(주) 스텔라이트 | 대표 김학우",
  addressInfo: "서울시 강남구 영동대로 602, 6층 E6호 (삼성동) | 사업자 등록번호 229-88-01520 ",
  emailInfo: "Email: stellite@e-smartcode.com | 고객센터: 010-9783-7227 (운영시간: 10:00-18:00)",
  terms1: "서비스 이용약관",
  terms2: "개인정보 보호정책",

  // 고객센터
  customerCenter: "고객센터",
  oneononeQuestion: "1:1 문의",
  howCanIHelpYou1: "무엇을 도와드릴까요?",
  howCanIHelpYou2: "E-Smart Code를 이용하면서 궁금한 점을 검색하세요",
  howCanIHelpYou3: "검색어를 입력하세요",
  frequentQuestion: "자주 묻는 질문",
  frequentQuestionMain1: "E-스마트코드란?",
  frequentQuestionMain1Sub1: "기존 블록체인을 전공하고 개발기획을 담당하던 인력들이 다시모여 블록체인정품인증분야에 비전을 느끼고",
  frequentQuestionMain1Sub2: "(주)스텔라이트를 만들어 E스마트코드를 개발하였습니다. E-스마트코드란 기존 정품인증툴들이 갖고있던 단점들을",
  frequentQuestionMain1Sub3: "보완하고자 새롭게 떠오르는 블록체인이란 기술과 기존에 널리 쓰이는qr코드 기술을 융합하여 강력하고 투명한 블록체인qr시스템을 만들고",
  frequentQuestionMain1Sub4: "누구나 널리 쉽게 사용할수 있게 오픈화된 플랫폼입니다.",
  frequentQuestionMain2: "사용방법은 어떻게 되나요?",
  frequentQuestionMain2Sub1: "기업 파트너 회원 들은 저희 스텔라이트와 사전 미팅후(전화상담등) 이용방법안내등을 숙지하시고 계약서 작성후 세팅을 도와드립니다.",
  frequentQuestionMain2Sub2: "개인회원은 저희 E스마트코드앱을 IOS,안드로이드 에서 다운받으신후 실행하여 E-스마트코드가 세팅되어져 있는",
  frequentQuestionMain2Sub3: "제품및 유통이력에 간단한 스캔으로 회원가입없이 진위여부를 판별하실수 있습니다.",
  frequentQuestionMain3: "기업의 매출에 도움이 되나요?",
  frequentQuestionMain3Sub1: "기업의 브랜드 마케터의 과제중 하나는 브랜드 스토리입니다. 어떻게 가치를 만들어 낼것인가 에 대한 미래답으로 블록체인으로 활용하시면 고객의 신뢰가 ",
  frequentQuestionMain3Sub2: "곧 매출로 연결 될것입니다. 고객들의 원하는 니즈중 하나는 바로 정직성과 투명성 입니다. 저희는 블록체인기술로 그부분들을 기업에 입장에서 채워나갈것입니다.",
  frequentQuestionMain4: "정품쇼핑몰이란 무엇인가요?",
  frequentQuestionMain4Sub1: "저희 E-스마트코드를 사용하고 입점하신 모든 기업과 개인들이 사용할수 있는 정품쇼핑몰입니다.",
  frequentQuestionMain4Sub2: "다른쇼핑몰과의 차별화된점은 블록체인 정품인증 시스템이 완료된기업과 개인이 판매서비스를 가능케하여 한차원더높은 퀄리티로 고객들에게 다가갑니다.",
  frequentQuestionMain5: "NFT 마켓이란 무엇인가요?",
  frequentQuestionMain5Sub1: "NFT(대체불가능한토큰)란 단순하게 요즘 디지털화된 데이터를 사고팔수 있는 정품쇼핑몰과도 연계된 마켓을 말하며 ",
  frequentQuestionMain5Sub2: "누구나 저희 E스마트코드앱을 통하여 제작발행 가능하고. 저희 정품쇼핑몰과 연계된 NFT마켓은 차후 (E-NFT마켓) NFT 담보대출, 이자, P2P매매 가 이루어집니다.",

  doYouNeedHelp1: "도움이 필요하신가요?",
  doYouNeedHelp2: "지금 문의하세요",
  oneononeQuestion2: "1:1 문의하기",

  //고객관리 마케팅
  managementMarketing: "고객관리 마케팅",
  infoExcess1: "정보 과잉의 시대, 블록체인은 고객들이",
  infoExcess2: "정보탐색을 가장 적극적으로 하는",
  infoExcess3: "채널이 될 수 있습니다.",
  infoExcessSub1: "기업의 브랜드 마케더의 과제 중 하나는 브랜드 스토리입니다. 어떻게 가치를 만들어",
  infoExcessSub2: "낼것인가 에 대한 미래의 답을 블록체인 마케팅으로 활용하세요",
  infoExcessSub3: "E-Smart Code를 활용하여 블록체인의 마케팅 선도주자가 될수있고",
  infoExcessSub4: "고객의 충성도를 이끌어낼수 있습니다.",
  purchaseRegist: "구매등록정보",
  variousCRM: "다양한 CRM",
  infoAcquisition: "정보 획득",
  analysis: "고객 분석",
  blockChainUtil1: "블록체인을 활용하여 고객데이터를 세분화를 실시하여 신규고객획득,우수고객 유지,",
  blockChainUtil2: "고객가치증진, 잠재고객 활성화 ,평생고객화와 같은 사이클을 통하여 고객을",
  blockChainUtil3: "적극적으로 관리하고 유도할수있습니다.",
  blockChainUtil4: "블록체인은 고객과의 지속적인 관계를 유지하면서",
  blockChainUtil5: "원산지/검사결과/성분 정보 등을 통한 고객의 제품 신뢰도 업(up)이 됩니다.",
  eventInfo1: "소중한 고객정보를 한눈에 관리하세요",
  eventInfo2: "맞춤 마케팅으로 매출을 올려보세요",
  specialEvent1: "특별한 이벤트에",
  specialEvent2: "초대합니다!",
  birthdayEvent1: "생일축하 쿠폰이",
  birthdayEvent2: "도착했습니다!",
  eventInfoSub1: "기업은 E-Smart Code App을 활용하여 구매,정품위변조 등의 행위에 대한 포인트를",
  eventInfoSub2: "제공할수있고, 고객정보를 활용한 기념일,생일 등 이벤트를 제공할수있습니다.",
  exemple: "Ex)예시",
  exempleSub1: "구매금액의 5% 포인트 적립: 5천 포인트 당 5천원 쿠폰 발급",
  exempleSub2: "첫정품인증 1만원 할인 쿠폰 제공(단,신규 회원에 한함)",
  exempleSub3: "생일기념, 시즌 쿠폰 제공",
  exempleSub4: "골드,실버,브론즈 차등 혜택 제공",
  exempleSub5: "멤버만을 위한 특별한 이벤트 초대",
  infoExchange1: "기업과 고객은 연동된 앱으로 실시간 푸쉬를 통해 상호 연계되어 정보를 교환합니다.",
  infoExchange2: "기업은 지역별,시간별,제품별 고객은 원하는 기업의 정보나 이벤트를 실시간으로",
  infoExchange3: "받을 수 있습니다. 또한 다양한 SNS연계로 다양한 생태계를 만들어 갈수있습니다.",

  // 서비스이용방법
  serviceUseApp: "E-스마트코드앱이용방법",
  exactBlockChain1: "편리하고 정확한 블록체인",
  exactBlockChain2: "정품인증 E-스마트코드",
  exactBlockChainSub1: "기업은 복잡한 절차없이 정품인증 및 제품에 대한 원산지생산인증,공장제조인증,",
  exactBlockChainSub2: "물류운송인증,판매입고인증,소비자정품인증 등을 등록하고",
  exactBlockChainSub3: "고객은 누구라도 언제어디서든 손쉽게 정품인증앱을 통해 정확한",
  exactBlockChainSub4: "진위여부와 생산 이력등을 그 즉시 알수 있습니다.",
  exactBlockChainContent1: "먼저 기업회원은 기업에 맞는 요금제를 선택하고 E-스마트코드 기업회원으로",
  exactBlockChainContent2: "가입후 내부관리자사이트에서 기업정보등을 등록,관리 를 손쉽게 진행하게 됩니다.",
  exactBlockChainContent3: "고객이 스캔한 제품결과가 위변조,기등록 제품일 경우, 신고하기 서비스를 통하여",
  exactBlockChainContent4: "고객의위치기반/고객앱정보 등을 바탕으로 기업은 신속하고 긴밀한유통기반을 만들어",
  exactBlockChainContent5: "빠른 응대가 가능해 집니다.",
  exactBlockChainContent6: "E-스마트코드를 통해 기업은 인증된 판매처,대리점 현황,유통판매 채널별,지역별,",
  exactBlockChainContent7: "제품별 통계를 시각화하여 쉽게 데이터화 할 수 있으며 정품,위변조,구매등록 등",
  exactBlockChainContent8: "인증현황 통계 및 상세정보를 바탕으로 실시간 정보를 확인할수 있습니다.",

  //쇼핑몰
  customerBelive1: "고객이 신뢰할수 있는",
  customerBelive2: "정품쇼핑몰에 입점하세요.",
  useFee: "이용 수수료는 1%-15%입니다. ",
  blockChainQr1: "오직 블록체인 QR시스템을 적용한",
  blockChainQr2: "기업들만 입점해있습니다.",
  blockChainQrSub1: "E-Smart Code는 자체내 쇼핑몰과 카카오쇼핑 등과의 연계로",
  blockChainQrSub2: "기업의 다양한 수익창출에 기여하겠습니다.",
  shoppingMallCurrency1: "정품쇼핑몰은 가상화폐결제도 가능합니다.",
  shoppingMallCurrency2: "뿐만 아니라 자체발행코인도 지원합니다.",
  shoppingMallCurrency3: "현재 쇼핑몰 연계작업으로 빠른시간안에 찾아 뵙겠습니다.",
  shoppingMallNft1: "저희 E-Smart Code는 NFT, (Non-Fungible Token 대체 불가능한 토큰)",
  shoppingMallNft2: "마켓 거래도 활성화합니다.",
  shoppingMallNft3: "누구나 블록체인 인증기반으로 디지털자산을 제작 발행 하고",
  shoppingMallNft4: "p2p연계를 통한 거래가 가능하며 기업이든 개인이든",
  shoppingMallNft5: "누구나 자신의 디지털자산을 E스마트코드의",
  shoppingMallNft6: "블록체인인증을통해 공개 및 판매 임대서비스 가능합니다.",
  shoppingMallNft7: "지난해 12월 발행된 바이낸스 이벤트 토큰은 우리 돈 500만원의 가격을 호가하고 있다. /출처=바이낸스 홈페이지",
  shoppingMallNft8: "NFT 거래장터 오픈시(Opensea)에서 판매 중인 ‘365DAYS.WTF’의 DAYS 토큰들. /캡처=오픈시",

  // 회사소개
  blockChainQrSystem1: "블록체인 이제 ",
  blockChainQrSystem2: "실생활로 다가옵니다.",
  blockChainQrSystemSub: "(주)스텔라이트",
  ksmartCodeTeam1: "안녕하십니까? 블록체인 정품인증 기업 (주)스텔라이트 입니다.",
  ksmartCodeTeam2: "저희 스텔라이트는 정품인증이라는 분야에서 기존 정품인증툴들이 갖고 있던",
  ksmartCodeTeam3: "기술력의한계를 극복하고자 보안성과 안전성에서 가장 각광 받고 있는 블록체인과",
  ksmartCodeTeam4: "qr코드를 연계하여 완벽하고 사용이 편리한 e-smartcode 를 개발했습니다.",
  ksmartCodeMission1: "Team Mission",
  ksmartCodeMission2: "스텔라이트는 블록체인이라는 혁신적 기술력을 기반으로 사회적 가치를 창출해 나가는 것입니다.",
  ksmartCodeMission3: "그 첫걸음으로 e-스마트코드를 출시하여 전 세계 정품인증분야를 주도하여",
  ksmartCodeMission4: "제품.소프트웨어.이력등의 문제를 풀어줄 가장 완벽한 해결책을 제시하고자 합니다.",
  ksmartCodeMission5: "저희 스텔라이트팀은 블록체인정품인증을 통해 바꾸고 싶은 세상의 모습에 강한 비전을 느끼고",
  ksmartCodeMission6: "이기술력을 바탕으로 더 높은 성장을 위해 더 높은 목표를 세우며 치열하게 끊임없이 고민하겠습니다.",
  ksmartCodeMission7: "스텔라이트팀은 블록체인의 투명성과 같이 합리적이고 정직하게 성장하는 조직이 되겠습니다.",
  ksmartCodeMission8: "블록체인이라는 글로벌 혁신의 날개를 달고 세계 무대로 힘차게 날아오를 스텔라이트를 응원해 주십시요!",
  ksmartCodeMission9: "감사합니다.",
  ksmartCodePartner1Name: "김학우",
  ksmartCodePartner1Job: "CEO",
  ksmartCodePartner1Sub1: "현)스텔라이트 대표이사",
  ksmartCodePartner1Sub2: "현)사이먼벤처스 대표이사",
  ksmartCodePartner1Sub3: "현)스텝바이스텝 마케팅 대표",
  ksmartCodePartner1Sub4: "전)블랍스 마케팅 디렉터",
  ksmartCodePartner2Name: "김민우",
  ksmartCodePartner2Job: "개발팀 리더",
  ksmartCodePartner2Sub1: "현)블릭트 대표이사",
  ksmartCodePartner2Sub2: "전)삼성SDS 개발팀 리더",
  ksmartCodePartner2Sub3: "전)블랍스 대표이사",
  ksmartCodePartner2Sub4: "전)연세대학교 건축공학전공",


  //E스마트코드 안내
  manualTitle: "블록체인의 강력한 보안성과 안정성",
  manualMain1: "QR시스템과의 연계로",
  manualMain2: "정품인증의 한차원높은 인증서비스",
  manualSub1: "우리는 4차 산업 그리고 포스트 코로나 비대면(비접촉) 시대에 살고 있습니다.",
  manualSub2: "저희 E-Smart Code는 블록체인 QR시스템으로 앞서 올 새로운 시대에 발맞춰",
  manualSub3: "새로운 유통 / 물류혁신을 제공합니다.",
  manualSub4: "그동안의 유통 / 물류 단계는 질서 없이 관리되어 왔으며",
  manualSub5: "명확한 데이터라 하기 어려웠고 기존의 정품인증을 위한 다양한 QR코드 또한",
  manualSub6: "복제 방지를 위해 워터마크나 홀로그램 등을 도입하는 많은 노력이 있었지만",
  manualSub7: "완벽한 방지 기술의 부재로 제조사의 브랜드에 신뢰가 하락할 요소가 다분하였고",
  manualSub8: "정품 인증 / 물류정보를 소비자가 이를 완벽히 신뢰하지 못하는 상황이",
  manualSub9: "발생한 충분한 요소가 있어 왔습니다.",
  manualSub10: "하지만 저희 E-Smart Code는 완벽한 블록체인 QR시스템으로",
  manualSub11: "QR전자서명과 연계하여 강력하고 높은 보안성 및 안정성",
  manualSub12: "그리고 언제 어디서나 손쉽게 제품의 진위 여부 판단의 편의성으로",
  manualSub13: "고객에게 높은 신뢰성과 보안성을 가진 서비스를 제공합니다.",
  manualSub14: "E-Smart Code는 정품인증뿐만 아니라 제조 및 유통과정, 최종 소비자 정보 등을",
  manualSub15: "다양하게 기록함으로써 이정보를 바탕으로 기업은 투명하고 안전하게",
  manualSub16: "관리 감독이 가능케되고, 또한 블록체인의 정확한 정보를 바탕으로",
  manualSub17: "고객 일대일 맞춤 추적 마케팅을 구현하여 기업의 이익에 도움을 줍니다",

  // 기업회원 이용방법
  enteruseQrTitle: "편리하고 정확한",
  enteruseQrSub1: "블록체인 정품인증",
  enteruseQrSub2: "E-스마트 코드",

  enteruseQrcontent1: "기업은 복잡한 절차 없이 정품 인증 및 제품에 대한 기록을 등록하고",
  enteruseQrcontent2: "고객은 누구라도 언제 어디서든 손쉽게 정품 인증 앱을 통해 정확한",
  enteruseQrcontent3: "진위 여부와 생산 이력 등을 그 즉시 알 수 있습니다.",

  enteruseChargeTitle: "요금제",
  enteruseChargeContentNumberEvent: "Event",
  enteruseChargeContentNumber1: "01",
  enteruseChargeContentTitleEvent: "요금제 이벤트",
  enteruseChargeContentTitle1: "기업회원 요금제",
  enteruseChargeContent1ContentEvent1: "최초 가입시",
  enteruseChargeContent1ContentEvent3: "1개월 무료",
  enteruseChargeContent1Content1: "1년 약정",
  enteruseChargeContent1Content2: "",
  enteruseChargeContent1Content3: "50% 할인",
  enteruseChargeContent1Content4: "(별도문의)",
  enteruseChargeContent1Content5: "",
  enteruseChargeContent1Content6: "2년 약정",
  enteruseChargeContent1Content7: "",
  enteruseChargeContent1Content8: "60% 할인",
  enteruseChargeContent1Content9: "(별도문의)",
  enteruseChargeContent1Content10: "",

  enteruseChargeContentNumber2: "02",
  enteruseChargeContentTitle2: "개인회원 요금제",
  enteruseChargeContentTitle2Sub: "건당 500원 발급, 하루한건 무료",
  enteruseChargeContent2Content1: "10건",
  enteruseChargeContent2Content2: "",
  enteruseChargeContent2Content3: "20% 할인",
  enteruseChargeContent2Content4: "(별도문의)",
  enteruseChargeContent2Content5: "100건",
  enteruseChargeContent2Content6: "",
  enteruseChargeContent2Content7: "30% 할인",
  enteruseChargeContent2Content8: "(별도문의)",
  enteruseChargeContent2Content9: "1000건",
  enteruseChargeContent2Content10: "",
  enteruseChargeContent2Content11: "50% 할인",
  enteruseChargeContent2Content12: "(별도문의)",

  enteruseChargeContentNumber3: "03",
  enteruseChargeContentTitle3: "커스터마이징",
  enteruseChargeContent3Content1: "쇼핑몰.가상화폐 결제자연동",
  enteruseChargeContent3Content2: "자체가상화폐 결제연동 개발 별도협의",
  enteruseChargeContent3Content3: "가상화폐 결제연동 별도협의",
  enteruseChargeContent3Content4: "vat 별도 금액입니다.",

  enteruseNftText1: "먼저 기업회원은 기업에 맞는 요금제를 선택하시고",
  enteruseNftText2: "E-스마트 코드 기업회원으로 가입 후",
  enteruseNftText3: "내부 관리자 사이트에서 기업 정보를 등록하여 손쉽게 관리할 수 있습니다.",

  enteruseNftText4: "고객의 니즈의 부합하는 정품인증으로 고객의 데이터를 수집,관리할수있고",
  enteruseNftText5: "관리자에서 손쉽게 확인할수 있습니다.",






  // 서비스 이용약관, 개인정보 보호정책 (공통)
  articleNum1: '1.',
  articleNum2: '2.',
  articleNum3: '3.',
  articleNum4: '4.',
  articleNum5: '5.',
  articleNum6: '6.',
  articleNum7: '7.',
  articleNum8: '8.',
  articleNum9: '9.',
  articleNum10: '10.',
  articleNum11: '11.',
  articleNum12: '12.',
  articleAlp1: 'a.',
  articleAlp2: 'b.',
  articleAlp3: 'c.',
  articleAlp4: 'd.',
  articleAlp5: 'e.',
  articleAlp6: 'f.',
  articleAlp7: 'g.',
  articleAlp8: 'h.',
  articleAlp9: 'i.',
  articleAlp10: 'j.',
  articleAlp11: 'k.',
  articleAlp12: 'l.',


  // 서비스 이용약관 (파트너용)
  termPartner: 'e스마트코드 이용약관 (파트너용)',
  // 제1조
  partnerArticle1title: '제1조',
  partnerArticle1sub: '목적',
  partnerArticle1content1: '본 약관은 주식회사 스텔라이트(이하 회사)가 제공하는 E스마트코드 서비스(파트너용)를 이용함에 있어',
  partnerArticle1content2: '회사와 파트너의 권리·의무, 책임사항 및 기타 필요한 사항을 규정함을 목적으로 한다.',
  // 제2조
  partnerArticle2title: '제2조',
  partnerArticle2sub: '정의',
  partnerArticle2content1: '이 약관에서 사용하는 용어의 정의는 다음과 같다. 본 조에 의하여 정의되지 않은',
  partnerArticle2content2: '이 약관상의 용어의 의미는 일반적인 거래관행에 따른다.',
  partnerArticle2element1Text: '서비스란, 파트너의 매장또는,웹사이트상에서 자사제품 구매 고객에게 상품 및 용역(이하 상품 등)등의 이력및진위여부등을 할수있게하는 e스마트코드 가입을 유도하고, 고객은 그에따른 관련된정보를 신속하게얻을수있고 각 파트너들은 고객의 활동을 장려하기 위한 사용가능한 포인트및자사코인또는 상품등을 지원할수 있다 회사는 파트너가 매장을 효율적으로 운영할 수 있도록 고객정보에 기반한 통계분석 및 마케팅 프로모션 등을 지원하는 서비스(관련 부가서비스 포함)를 의미한다.',
  partnerArticle2element2Text: '서비스 이용계약이란, 본 약관에 따른 서비스 및 이에 부수하는 서비스의 제공 또는 이용을 위하여 회사와 파트너가 체결하는 계약으로, 회사가 정한 양식(서비스 신청서, CMS 신청서 등)에 따라 파트너가 필요한 정보를 기입한 후 쌍방이 계약내용에 동의한다는 의사표시로써 체결된다.',
  partnerArticle2element3Text: '파트너란, 각기업의 주체 운영·관리할 권한이 있는 자로서 이 약관에 따라 회사와 서비스 이용계약을 체결하는 당사자를 의미한다.',
  partnerArticle2element4Text: '매장이란 운영·관리하고 있는 전체 지점(온오프라인 직영점 및 가맹점 모두 포함)을 의미하며, 향후 파트너가 개설·운영할 지점도 이에 포함되는 것으로 본다.',
  partnerArticle2element5Text: '포인트란, 고객이 매장에서 상품 등을 구매할 경우 제공·적립되는 마일리지로, E스마트코드 가입 고객은 적립한 포인트를 이용해 해당 매장에서 회사와 파트너가 사전 협의한 범위 내의 상품 등을 구매할 수 있다.',
  partnerArticle2element6Text: '쿠폰이란, 파트너가 E스마트코드 가입 고객에게 마케팅을 목적으로 지급하는 쿠폰을 의미하며, 고객은 쿠폰을 사용하여 해당 매장에서 상품 등을 구매할 수 있다.',
  partnerArticle2element7Text: '광고란, 광고주의 요청에 따라 브랜드 및 상품에 관한 컨텐츠를 매장 내 태블릿PC 화면에 게시함으로써 브랜드 및 상품을 홍보하는 행위를 의미한다.',
  partnerArticle2element8Text: '광고주란, 태블릿 PC 화면에 게시될 브랜드 및 상품에 관한 광고를 요청하는 자를 의미한다.',
  // 제3조
  partnerArticle3title: '제3조',
  partnerArticle3sub: '약관의 명시와 설명 및 개정',
  partnerArticle3content1Text: '회사는 이 약관의 내용과 상호 및 대표자 이름, 주소, 전화번호, 이메일, 사업자등록번호, 개인정보관리책임자 등을 파트너가 쉽게 알 수 있도록 서비스 화면(또는 인터넷 사이트)에 게시한다. 다만, 약관의 구체적 내용은 파트너가 연결화면을 통하여 볼 수 있도록 할 수 있다.',
  partnerArticle3content2Text: '회사는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제 등에 관한 법률』, 『전자거래 기본법』, 『전자서명법』, 『정보통신망 이용촉진 등에 관한 법률』, 『소비자기본법』 등 관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있다.',
  partnerArticle3content3Text: '회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 사이트의 초기화면(또는 인터넷 사이트)에 그 적용일자 7일 이전부터 적용일자 전일까지 공지하기로 한다.',
  partnerArticle3content4Text: '제3항에 의해 변경된 약관은 관련 법령에 특별한 규정 기타 부득이한 사유가 없는 한 그 적용일자 이전으로 소급하여 적용되지 아니한다.',
  partnerArticle3content5Text: '제3항에 따라 공지된 적용일자 이후에 파트너가 명시적으로 거부의사를 표명하지 않을 경우에는 개정된 약관에 동의하는 것으로 간주하며, 변경된 약관에 동의하지 않는 파트너는 탈퇴를 요청할 수 있다. 제3항의 조치에도 불구하고 약관의 개정 사실을 알지 못함으로써 발생하는 파트너의 피해에 대하여 회사는 책임을 지지 않는다.',
  partnerArticle3content6Text: '회사는 제공하는 서비스 내의 개별 서비스에 대한 별도의 약관 또는 이용조건을 둘 수 있으며 개별 서비스에서 별도로 적용되는 약관에 대한 동의는 파트너가 개별 서비스를 최초로 이용할 경우 별도의 동의절차를 거치게 된다. 이 경우 개별 서비스에 대한 이용약관 등이 이 약관에 우선한다.',
  partnerArticle3content7Text: '이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관련법령의 규정과 일반 상관례에 따른다.',
  // 제4조
  partnerArticle4title: '제4조',
  partnerArticle4sub: '서비스의 제공 및 변경',
  partnerArticle4content1Text: '회사는 다음 각 호와 같은 서비스를 제공하기로 한다.',
  partnerArticle4element1Text: '포인트 제공 및 적립 지원 서비스, ',
  partnerArticle4element2Text: '고객정보에 기반한 통계분석 등 고객관리 서비스',
  partnerArticle4element3Text: '맞춤형 마케팅 서비스',
  partnerArticle4element4Text: '이에 수반되는 기타 서비스 일체',
  partnerArticle4content2Text: '회사는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 서비스의 내용을 변경할 수 있다. 이 경우 변경된 서비스의 내용을 명시하여 현재의 서비스의 내용을 게시한 곳에 즉시 공지한다.',
  // 제5조
  partnerArticle5title: '제5조',
  partnerArticle5sub: '서비스의 중단',
  partnerArticle5content1Text: '회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검·교체, 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있으며, 회사가 서비스를 중단할 경우 즉시 파트너에게 그러한 사실을 통지한다.',
  partnerArticle5content2Text: '회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 파트너가 입은 손해에 대하여 배상하기로 한다. 단, 회사의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니하다.',
  partnerArticle5content3Text: '사업 종목의 전환, 사업의 종료 또는 포기, 회사의 폐업, 합병, 분할, 영업양도 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 이를 파트너에게 통지하고 회사에서 제시한 조건에 따라 파트너에게 보상한다.',
  // 제6조
  partnerArticle6title: '제6조',
  partnerArticle6sub: '파트너 제휴',
  partnerArticle6content1Text: '본 약관에 따라 회사와 제휴하여 파트너가 되고자 하는 자(이하 가입희망자)는 회사가 정한 가입양식에 따라 정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청한다.',
  partnerArticle6content2Text: '회사는 가입희망자에게 본 약관을 명시하고, 본 약관의 중요한 내용인 다음 각 호의 사항을 가입희망자에게 설명하기로 한다.',
  partnerArticle6element1Text: '서비스 이용계약의 체결에 관한 사항',
  partnerArticle6element2Text: '서비스 이용기간, 이용방법, 서비스를 위한 기기의 설치에 관한 사항',
  partnerArticle6element3Text: '파트너가 부담하는 서비스 이용료 및 추가 비용에 관한 사항',
  partnerArticle6element4Text: '서비스 이용계약의 위반 및 해지 사유, 위약금에 관한 사항',
  partnerArticle6content3Text: '파트너는 회원정보에 변경이 있는 경우, 회원정보 수정 등의 방법으로 그 변경사항을 반영하여야 한다. 회원정보 수정을 하지 않음으로써 발생하는 파트너의 손해에 대하여 회사는 법적 책임을 부담하지 않는다.',
  // 제7조
  partnerArticle7title: '제7조',
  partnerArticle7sub: '서비스 이용계약',
  partnerArticle7content1Text: '회사와 파트너는 본 약관 외에 별도의 ‘서비스 이용계약’을 체결하기로 하며, 서비스의 이용기간 등 구체적인 사항은 서비스 이용계약에서 정한 바에 따른다.',
  partnerArticle7content2Text: '회사 또는 파트너 일방이 계약만료일 1개월 전까지 상대방에 대하여 계약 종료 또는 변경 등에 대한 명시적 의사를 표시하지 않은 경우, 서비스 이용계약은 계약만료일 익일부터 1년간 동일한 조건으로 자동 연장된다 (단, 계약기간 내 본 약관이 변경될 경우, 연장된 계약에는 변경된 약관의 조건이 적용된다).',
  partnerArticle7content3Text: '파트너는 서비스 이용계약상 ‘특약조건’을 준수하여야 하며, 계약기간 내 위 특약조건을 달성하지 못할 경우, 제2항에도 불구하고, 회사는 별도의 통지 없이 서비스 이용계약을 종료할 수 있다.',
  partnerArticle7content4Text: '서비스 이용계약의 내용을 변경하고자 할 때에는, 회사와 파트너 간 합의를 거친 후 서면으로 약정할 것이 요구된다. 다만, 계약 내용의 경미한 사항의 변경은 서면 합의만으로도 가능하다.',
  partnerArticle7content5Text: '회사와 파트너가 서비스 이용계약을 체결 또는 변경하기로 합의한 경우, 파트너는 이에 관한 내용이 가맹계약(파트너와 매장 사이에 체결된 매장운영에 관한 약정을 의미하며, 위 명칭에 한하지 아니함)에 반영되도록 하여야 한다. 매장이 위 서비스 이용계약에 따른 내용을 거부하거나, 가맹계약에 서비스 이용계약에 관한 사항이 누락되는 등의 사유로 인하여 회사가 손해를 입을 경우, 파트너는 자신의 책임과 비용으로 이를 배상하기로 한다.',
  // 제8조
  partnerArticle8title: '제8조',
  partnerArticle8sub: '서비스 이용료',
  partnerArticle8content1Text: '파트너는 회사가 제공하는 서비스에 대한 대가로 이용요금(이하 서비스 이용료)을 지급하기로 하며, 파트너가 가입하는 서비스 패키지에 따라 서비스 이용료를 달리 정하되 그 구체적인 사항은 서비스 이용계약에서 별도로 정하기로 한다.',
  partnerArticle8content2Text: '파트너는 회사가 서비스 개시를 위하여 투입하는 제반비용(초기 설치 비용 등) 전액을 서비스 이용계약 개시 후 도래하는 첫 이용료 지급일에 함께 지급하기로 한다.',
  // 제9조
  partnerArticle9title: '제9조',
  partnerArticle9sub: '회사의 의무',
  partnerArticle9content1Text: '회사는 서비스 전반에 관한 MCP(Master Contents Provider)로서 서비스를 위한 기기, 소프트웨어 및 컨텐츠의 공급, 운영 및 총괄을 담당한다.',
  partnerArticle9content2Text: '회사가 파트너에게 광고주의 요청에 따른 광고를 매장 내 태블릿PC화면에 게시할 것을 요청할 경우, 서비스 제공의 목적이나 기능이 최대한 훼손되지 않도록 노력하여야 한다.',
  partnerArticle9content3Text: '회사는 파트너가 불법광고 등이 게시됨으로 인하여 피해를 입지 않도록 상시적으로 광고 컨텐츠를 관리하여야 한다. 만일 파트너가 특정 광고의 게시를 거부하는 의사를 서면으로 신청을 할 경우, 회사는 5 영업일 이내에 해당 광고를 수정 또는 삭제하여야 한다.',
  partnerArticle9content4Text: '회사는 발신번호의 변작 방지를 위하여 번호인증을 통한 발신번호 사전등록 서비스를 제공·운영한다.',
  partnerArticle9content5Text: '회사는 회원가입 시 타인 명의 도용 등 부정가입을 방지하기 위하여 본인인증 서비스 사업자가 제공하는 본인인증서비스를 제공·운영한다.',
  partnerArticle9content6Text: '회사는 제4항의 발신번호 사전등록 및 제5항의 본인인증 절차를 모두 이행한 파트너에 대하여만 서비스를 제공한다.',
  partnerArticle9content7Text: '회사는 발신번호를 변작하여 송신한 자에 대하여 전기통신사업법 제84조의2에 따라 서비스 제공 중지, 신고 또는 관련자료 제출 조치를 취할 수 있다.',
  partnerArticle9content8Text: '회사는 스팸 또는 피싱으로 판단한 메시지 등을 차단할 수 있으며, 해당 메시지 등 관련 정보를 1년 간 보관할 수 있다.',
  partnerArticle9content9Text: '회사는 파트너가 본 약관에 따라 서비스를 이용하는지 여부를 확인하기 위하여 필요한 범위 내에서 이를 감독하거나 파트너에게 관련 자료를 요청할 수 있다.',
  // 제10조
  partnerArticle10title: '제10조',
  partnerArticle10sub: '파트너의 의무',
  partnerArticle10content1Text: '파트너는 E스마트코드에 가입한 구매 고객에게 포인트 또는 쿠폰을 제공함으로써 고객의 E스마트코드 가입을 유도하고, 고객이 적립한 포인트 또는 쿠폰을 이용하여 회사와 사전 협의한 바에 따라 매장 내 상품 등을 구매할 수 있도록 하여야 한다. 포인트 또는 쿠폰의 제공에 따른 비용은 파트너가 전액 부담하며, 회사는 서비스 제공 및 관리를 지원하는 역할만을 담당한다.',
  partnerArticle10content2Text: '파트너는, 파트너가 인정하는 결제수단으로 대가를 미리 지급한 고객에게 포인트를 충전해주는 서비스(이하 포인트 충전 서비스)를 제공할 수 있다. 단, 포인트 충전 서비스를 운영하고자 하는 파트너는 전자금융거래법 등 관련법령을 준수하여야 하며, 파트너가 정한 각 정책에 따라 파트너의 비용과 책임으로 운영하여야 한다.',
  partnerArticle10content3Text: '파트너는 회사가 광고주의 요청에 따라 특정 브랜드 및 상품을 홍보하는 광고를 매장 내 태블릿PC 화면에 게시할 수 있음을 사전 인지하였으며, 회사가 이를 요청할 경우 승낙하기로 한다.',
  partnerArticle10content4Text: '파트너는 서비스 이용계약에 따른 방법에 의하지 아니하고 비정상적인 방법으로 서비스를 이용하거나 회사의 정보처리시스템에 접근하는 행위를 하여서는 아니 된다.',
  partnerArticle10content5Text: '파트너는 발신번호의 변작 방지를 위해 발신번호를 사전등록한 후 서비스를 이용하여야 한다.',
  partnerArticle10content6Text: '파트너는 회원가입 시 타인 명의 도용 등 부정가입을 방지하기 위하여 본인인증 서비스 사업자가 제공하는 본인인증서비스로 인증을 거친 후 서비스를 이용한다',
  partnerArticle10content7Text: '파트너가 마케팅 목적으로 고객에게 메시지를 발송할 경우, 다음 각호의 행위를 하여서는 아니 된다.',
  partnerArticle10element1Text: '음란 또는 폭력적 메시지, 사행행위 유도, 청소년보호법에서 규정하는 청소년유해매체물, 기타 공서양속에 반하는 정보를 전송, 도달, 유포하는 행위',
  partnerArticle10element2Text: '타인의 의사에 반하여 광고성 정보 및 유사한 내용을 지속적으로 전송하는 행위',
  partnerArticle10element3Text: '타인의 지적재산권 등을 침해하거나 범죄행위를 목적으로 하거나 범죄행위를 교사하는 행위',
  // partnerArticle10element4Text: 위의 3으로 합병,
  partnerArticle10element5Text: '선량한 풍속 또는 기타 사회질서를 해치는 행위',
  partnerArticle10element6Text: '서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위',
  partnerArticle10element7Text: '회사의 동의 없이 회사의 서비스를 이용하여 얻은 정보를 복사, 복제, 변경, 번역, 출판, 방송 및 기타의 방법으로 사용하거나 이를 타인에게 제공하는 행위',
  partnerArticle10element8Text: '불법 선거 운동을 하는 행위',
  partnerArticle10content8Text: '파트너는 회사 또는 제3자의 권리를 침해 또는 침해할 우려가 있거나, 거래 질서를 위협하는 행위를 하여서는 아니된다.',
  partnerArticle10content9Text: '파트너는 본 조에 따라 매장과 고객이 e스마트코드를 원활하게 이용할 수 있는데 필요한 직원 교육 및 관리 업무를 이행하여야 한다.',
  partnerArticle10content10Text: '회사는 본 조를 위반한 파트너에 대하여 서비스 이용계약의 해제 및 민∙형사상 법적 조치를 취할 수 있다.',
  // 제11조
  partnerArticle11title: '제11조',
  partnerArticle11sub: '고객정보의 수집, 이용 및 처리',
  partnerArticle11content1Text: '서비스를 통해 수집한 고객의 개인정보, 매장이용내역 등 일체의 정보(이하 고객정보)에 대한 권리는 회사에게 귀속된다.',
  partnerArticle11content2Text: '회사는 수집한 고객정보를 파트너가 마케팅, 고객분석 등에 이용할 수 있도록 가공하여 제공하되, 고객의 개인정보(성명, 전화번호 등)는 비식별화(마스킹)하여 제공하는 것을 원칙으로 한다.',
  partnerArticle11content3Text: '파트너는 회사가 전항에 따라 수집, 가공 및 비식별화 과정을 완료하여 제공하는 고객정보만을 조회할 권한이 있으며, 회사가 수집한 모든 고객정보의 열람 및 교부를 요청할 수는 없다.',
  partnerArticle11content4Text: '제3항에도 불구하고, 파트너가 고객으로부터 개인정보의 제3자(파트너) 제공에 관한 동의를 징구한 경우, 파트너는 회사에게 당해 고객의 개인정보를 요구할 수 있다. 단, 파트너는 고객이 동의한 범위를 초과하여 고객정보를 이용할 수 없다.',
  partnerArticle11content5Text: '파트너는 본 약관과 무관하게 수집한 고객정보를 본건 서비스를 통해 수집한 고객정보와 통합하여 처리할 수 없다. 단, 해당 고객정보의 주체로부터 사전 동의를 구한 경우는 예외적으로 허용된다.',
  partnerArticle11content6Text: '파트너가 개인정보보호법 등 관련법령을 위반하여 고객정보를 수집·이용·처리함으로써 회사 또는 제3자에게 손해를 입힌 경우, 파트너는 자기의 책임과 비용으로 이를 배상하기로 한다.',
  partnerArticle11content7Text: '파트너는 서비스 이용계약이 종료 또는 해지된 이후에는 고객정보를 조회하거나 요구할 수 없다.',
  // 제12조
  partnerArticle12title: '제12조',
  partnerArticle12sub: '개인정보보호',
  partnerArticle12content1Text: '회사는 서비스 제공을 위하여 필요 최소한의 범위 내에서 개인정보를 수집한다.',
  partnerArticle12content2Text: '회사는 개인정보를 수집하기 전 정보주체에게 그 목적을 고지하고 동의를 받기로 한다.',
  partnerArticle12content3Text: '회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 사전에 정보주체에게 그 목적을 고지하고 동의를 받기로 한다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 한다.',
  partnerArticle12content4Text: '회사가 정보주체의 동의를 받아야 하는 경우에는 개인정보 관리책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』에서 규정한 사항을 미리 명시하거나 고지해야 하며 정보주체는 언제든지 이 동의를 철회할 수 있다.',
  partnerArticle12content5Text: '정보주체는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취하여야 한다. 정보주체의 오류 정정 요구가 있는 경우, 회사는 그 오류를 정정할 때까지 해당 개인정보를 이용하지 않는다.',
  partnerArticle12content6Text: '회사는 개인정보의 보호를 위하여 정보주체의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며, 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 정보주체의 손해에 대하여 회사는 배상할 책임을 부담한다. 단, 회사에게 고의 또는 과실이 없는 경우에는 그러하지 아니하다.',
  partnerArticle12content7Text: '회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 처리목적을 달성하거나 보관기간이 도과한 경우에는 당해 개인정보를 지체 없이 파기하기로 한다.',
  partnerArticle12content8Text: '회사는 개인정보의 수집·이용·처리에 관한 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수 수집항목이 아닌 개인정보의 수집·이용·처리에 관한 정보주체의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않는다.',
  partnerArticle12content9Text: '회사의 사이트 이외의 링크된 외부사이트에서는 회사의 개인정보처리방침이 적용되지 않는다. 정보주체는 링크된 외부사이트의 개인정보 취급과 관련하여서는 해당 외부사이트의 개인정보처리방침을 확인할 책임을 부담한다.',
  partnerArticle12content10Text: '회사는 다음과 같은 경우에 법이 허용하는 범위 내에서 정보주체의 개인정보를 제3자에게 제공할 수 있다.',
  partnerArticle12element1Text: '수사기관이나 기타 정부기관으로부터 적법한 절차에 따른 정보제공을 요청받은 경우',
  partnerArticle12element2Text: '정보주체가 관련 법령을 위반하는 등의 부정행위를 확인하기 위해 필요한 경우',
  partnerArticle12element3Text: '서비스 제공 또는 관리에 필요한 최소한의 개인정보(성명, 주소, 전화번호)를 제공하여야 하는 경우',
  partnerArticle12element4Text: '서비스 이용이 종료된 이후에도 반품, 교환, 환불, 취소 등을 위하여 필요한 경우',
  partnerArticle12element5Text: '기타 관련 법령에 의해 요구되는 경우',
  partnerArticle12content11Text: '기타 본 조에서 정하지 않은 사항은 회사의 개인정보처리방침에 따른다.',
  // 제13조
  partnerArticle13title: '제13조',
  partnerArticle13sub: '기기설치 등',
  partnerArticle13content1Text: '회사는 서비스의 제공을 위하여 필요한 기기를 파트너가 요청하는 장소에 설치하기로 한다.',
  partnerArticle13content2Text: '제1항의 기기 설치를 위하여 투입된 일체의 비용(단말기 비용, 운송비 및 설치비 등)은 파트너가 부담하기로 한다.',
  partnerArticle13content3Text: '파트너가 기기의 설치 및 이용 방법에 관하여 질의하거나 문제해결을 요구할 경우, 회사는 유선 등 비대면 응대를 원칙으로 한다. 예외적으로, 회사가 방문 출장하여 응대하게 될 경우에는 파트너에게 실비(출장비 등)를 청구할 수 있다.',
  // 제14조
  partnerArticle14title: '제14조',
  partnerArticle14sub: '유지보수',
  partnerArticle14content1Text: '회사는 파트너가 서비스를 정상적으로 이용할 수 있도록 지원하며, 서비스에 장애가 발생할 경우 최선을 다하여 이를 보수하기로 한다.',
  partnerArticle14content2Text: '회사는 서비스장애 신고 접수처(전화번호 1670-6463)를 운영하며, 당일 처리 장애 접수는 해당 영업일 17시 이전까지 접수된 건에 한한다.',
  partnerArticle14content3Text: '파트너는 서비스 이용에 관한 장애가 발생할 경우, 즉시 회사에게 해당 사실을 알리고 장애 접수를 하여야 한다. 파트너가 장애 접수를 지체함에 따라 회사 또는 제3자가 입는 손해는 파트너의 비용으로 배상하기로 한다.',
  partnerArticle14content4Text: '파트너의 고의 또는 과실로 서비스 및 관련 기기가 도난, 분실, 파손되거나 장애가 발생한 경우, 이를 복구하기 위하여 필요한 일체의 비용은 파트너가 부담한다. 파손 또는 장애가 발생한 기기는 제조사 A/S 규정에 따르는 것을 원칙으로 하며, 이에 근거하여 필요한 비용을 산정한다.',
  // 제15조
  partnerArticle15title: '제15조',
  partnerArticle15sub: '손해배상',
  partnerArticle15content: '회사 또는 파트너 중 어느 일방이 본 약관에 정한 사항을 이행하지 아니하거나 위반하여 상대방에게 손해를 입힌 경우, 위반당사자의 책임과 비용으로 상대방이 입은 손해를 배상하기로 한다.',
  // 제16조
  partnerArticle16title: '제16조',
  partnerArticle16sub: '지연손해금',
  partnerArticle16content1Text: '파트너가 본 약관, 서비스 이용계약상 부담하는 채무의 이행을 지체함에 따라 부담하는 지연손해금은 총 연체금액에 대하여 연 2%의 비율로 산정하며, 해당 지연손해금은 익월 서비스 이용료 지급일에 함께 지급하기로 한다.',
  partnerArticle16content2Text: '파트너가 제1항에 따라 지급하여야 할 지연손해금은 회사와 사전 협의한 서비스 이용계약의 내용에 따른 방법으로 지급하기로 한다.',
  // 제17조
  partnerArticle17title: '제17조',
  partnerArticle17sub: '계약해지 및 위약금',
  partnerArticle17content1Text: '회사 또는 파트너는 본 약관 또는 서비스 이용계약에서 정한 계약해지 사유 없이 임의로 계약을 해지할 수 없다. 단, 파트너는 서비스 이용계약 체결일로부터 7일 이내에 계약취소를 요청할 수 있으며 이 경우 위약금은 면제하기로 한다.',
  partnerArticle17content2Text: '파트너가 서비스 이용계약의 중요사항을 위반하여 회사로부터 그 시정요구를 받았음에도 그러한 요구를 받은 날로부터 5일 이내에 이를 시정하지 않을 경우, 회사는 서비스 이용계약의 해지를 통지할 수 있다.',
  partnerArticle17content3Text: '.파트너가 제1항 및 제2항을 위반함으로써 계약기간 내에 서비스 이용계약이 해지된 경우, 회사는 파트너에게 총 서비스 이용료의 10%를 위약금으로 청구할 수 있다. 단, 파트너의 폐업으로 인한 해지의 경우, 회사가 요구하는 증빙서류(폐업사실증명원 등)를 제출한 경우에 한하여 위약금을 면제할 수 있다.',
  partnerArticle17content4Text: '서비스 이용계약이 해지된 경우, 회사는 파트너에게 잔여 서비스 이용료(계약해지일부터 계약만료일까지 일할 계산)를 아래 각호에서 정한 바에 정산하기로 한다.',
  partnerArticle17element1Text: '잔여 서비스 이용료가 제3항의 위약금보다 많을 경우, 회사는 위약금을 공제한 후 남은 이용료를 지급하기로 한다.',
  partnerArticle17element2Text: '잔여 서비스 이용료가 제3항의 위약금보다 적을 경우, 회사는 잔여 서비스 이용료를 전액 수취하고, 이를 초과하는 위약금 부분은 파트너에게 청구하기로 한다.',
  partnerArticle17element3Text: '회사가 서비스 제공을 위하여 투입한 기기 설치비 등 초기 비용은 잔여 서비스 이용료 산정 시 제외한다.',
  partnerArticle17content5Text: '제1항에도 불구하고 회사 또는 파트너 일방에게 다음 각 호의 사유가 발생한 경우, 상대방은 서면 통지로써 서비스 이용계약을 해지할 수 있다.',
  partnerArticle17element4Text: '어느 당사자에 대하여 채무자 회생 및 파산에 관한 법률에 따른 회생절차 또는 파산절차가 개시된 경우',
  partnerArticle17element5Text: '어느 당사자에 대하여 발행어음이나 수표가 부도 또는 거래 정지된 경우',
  partnerArticle17element6Text: '행정기관으로부터 영업정지 또는 영업면허, 영업등록 등의 취소처분을 받은 때',
  partnerArticle17element7Text: '가압류, 가처분, 경매 등을 당하여 본 계약 목적달성이 곤란하다고 판단되는 경우',
  partnerArticle17content6Text: '파트너가 정당한 사유 없이 서비스 이용계약 기간 중 서비스 이용료를 30일 이상 연체할 경우, 서비스 이용계약을 일방적으로 해지한 것으로 간주하여 회사는 별도의 통지없이 제1항, 제3항 및 제4항에 따라 처리할 수 있다.',
  partnerArticle17content7Text: '본 조에 따라 서비스 이용계약이 해지된 경우라 하더라도, 계약위반자의 손해배상 및 위약금 지급의무는 계속 유효하다.',
  // 제18조
  partnerArticle18title: '제18조',
  partnerArticle18sub: '회사의 면책',
  partnerArticle18content1Text: '회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제된다.',
  partnerArticle18content2Text: '회사는 파트너의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않는다.',
  partnerArticle18content3Text: '회사는 파트너가 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 서비스를 통하여 알게 된 정보를 이용함에 따라 입은 손해에 대하여 책임을 지지 않는다.',
  partnerArticle18content4Text: '회사는 파트너가 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않는다.',
  partnerArticle18content5Text: '회사는 파트너 간 또는 파트너와 제3자 상호 간에 본 약관에서 정한 범위를 초과하여 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제된다.',
  partnerArticle18content6Text: '파트너와 고객 사이에 포인트 충전 서비스에 관한 분쟁이 발생할 경우, 회사는 해당 분쟁을 해결하거나 중재할 의무가 없으며, 이에 관하여 아무런 법적 책임도 부담하지 않는다.',
  partnerArticle18content7Text: '회사는 무료로 제공되는 서비스 이용과 관련하여 관련 법령에 특별한 규정이 없는 한 책임을 지지 않는다.',
  // 제19조
  partnerArticle19title: '제19조',
  partnerArticle19sub: '정보보호 및 비밀유지',
  partnerArticle19content1Text: '회사는 서비스를 제공하는 과정에서 수집, 가공, 제공한 모든 정보(고객정보 포함)에 대한 일체의 권리를 보유한다.',
  partnerArticle19content2Text: '파트너는 본 약관에 따른 서비스 및 이에 부수한 업무를 위한 목적으로만 회사가 제공한 고객정보 등 정보를 이용할 수 있으며, 회사의 사전 동의 없이 위 목적 외에 수령한 정보를 이용하여서는 아니 된다.',
  partnerArticle19content3Text: '파트너는 서비스 이용과 관련하여 회사로부터 수령한 일체의 정보를 서비스 이용계약이 종료됨과 동시에 회사에 반환하거나 파기하여야 한다.',
  partnerArticle19content4Text: '회사는 서비스 이용계약을 체결하기 전, 신용정보기관으로부터 필요 최소한 범위 내에서 파트너의 신용정보 제공을 요청할 수 있으며, 파트너는 이에 동의하기로 한다.',
  partnerArticle19content5Text: '회사와 파트너는 본 약관에 따른 서비스와 관련하여 알게 된 상대방의 영업비밀 또는 정보에 대하여 상대방의 사전 서면 동의가 없는 한 제3자에게 이를 유출하거나 서비스 이용 이외의 목적으로 이용하여서는 아니되며(이하 비밀유지의무), 비밀유지의무를 위반한 당사자는 이에 따른 모든 법적 책임을 부담하기로 한다.',
  partnerArticle19content6Text: '제5항에 따른 비밀유지의무는 서비스 이용계약의 종료일로부터 3년 간 유효하다.',
  partnerArticle19content7Text: '회사 또는 파트너 중 어느 일방이 본 약관 및 서비스 이용에 관한 사항을 제3자 또는 언론매체에 공개할 경우에는 반드시 상대방과 사전 합의하여 일정, 공개내용, 정보수취인 등을 결정하여야 한다.',
  // 제20조
  partnerArticle20title: '제20조',
  partnerArticle20sub: '통지',
  partnerArticle20content1Text: '회사 또는 파트너 일방이 다음 각 호에 해당하는 사유가 발생한 경우에는 관련 증빙서류를 첨부하여 상대방에게 통지하여야 한다.',
  partnerArticle20element1Text: '주소, 상호, 대표자 등 사업자등록증에 명시된 사항이 변경된 경우',
  partnerArticle20element2Text: '기타 중요한 사항에 변경이 있는 경우',
  partnerArticle20content2Text: '본 약관 및 서비스 이용계약에 따른 모든 통지는 서비스 이용계약에 기재된 주소로 서면 또는 전자우편으로 발송하는 것을 원칙으로 한다.',
  // 제21조
  partnerArticle21title: '제21조',
  partnerArticle21sub: '권리, 의무의 양도금지',
  partnerArticle21content: '회사와 파트너는 상대방의 사전 서면동의 없이 이 약관상의 권리 또는 의무의 전부 또는 일부를 제3자에게 양도, 이전, 증여하거나 이를 담보로 제공할 수 없다.',
  // 제22조
  partnerArticle22title: '제22조',
  partnerArticle22sub: '회사의 면책',
  partnerArticle22content1Text: '이 약관에서 규정하지 않은 사항은 신의원칙에 따라 회사와 파트너가 협의하여 결정하되, 협의되지 아니한 사항은 관계법령 및 일반 거래 관행에 의한다.',
  partnerArticle22content2Text: '본 약관의 변경 또는 수정은 회사와 파트너가 서면으로 합의한 바에 따른다.',
  partnerArticle22content3Text: '본 약관에 대한 해석 및 분쟁은 대한민국의 법률을 적용하며, 소송이 제기되는 경우에는 서울중앙지방법원을 전속관할로 한다.',
  // 부칙
  partnerArticleLast: '부칙',
  partnerArticleDate: '이 약관은 2021년 02월 22일부터 시행한다',


  // 서비스 이용약관 사용자용
  termUser: 'e스마트코드 이용약관 (사용자용)',
  // 제1조
  userArticle1title: '제1조',
  userArticle1sub: '목적',
  userArticle1content1: '본 약관은 주식회사 스텔라이트(이하 회사)가 제공하는 E스마트코드 서비스(사용자용)를 이용함에 있어',
  userArticle1content2: '회사와 회원의 권리·의무, 책임사항 및 기타 필요한 사항을 규정함을 목적으로 한다.',
  // 제2조
  userArticle2title: '제2조',
  userArticle2sub: '정의',
  userArticle2content1: '이 약관에서 사용하는 용어의 정의는 다음과 같다. 본 조에 의하여 정의되지 않은',
  userArticle2content2: '이 약관상의 용어의 의미는 일반적인 거래관행에 따른다.',
  userArticle2element1Text: '사이트란, 회사가 서비스를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 설정한 가상의 영업장을 의미한다. 현재 회사가 운영하는 사이트는 아래와 같으며, 더불어 서비스하는 안드로이드, iOS 환경의 서비스를 포함한 모바일 웹과 앱을 포함한다.',
  userArticle2element2Text: 'https://www.e-smartcode.com/',
  userArticle2element3Text: '매장이란 운영·관리하고 있는 전체 지점(온오프라인 직영점 및 가맹점 모두 포함)을 의미하며, 향후 파트너가 개설·운영할 지점도 이에 포함되는 것으로 본다. 추후 회사에서 공지 및 운영하는 기타 웹사이트포함',
  userArticle2element4Text: '서비스란, E스마트코드 서비스에 가입한 고객(회원)이 서비스 제휴 기업 내 상품 및 용역(이하 상품 등)을 구매하기전 해당 기업 내 상품 및 용역(이하 상품 등) 의 진위여부및 물류인증확인등, 고객이 그에 관련된 정보를 알수 있도록 회사가 제공하는 인터넷 기반의 서비스(관련 부가서비스 포함)를 의미한다.',
  userArticle2element5Text: '회원이란, 회사의 서비스에 접속하여 이 약관에 따라 회사와 서비스 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을 의미한다.',
  userArticle2element6Text: '아이디(ID)란, 회사의 서비스 이용 시 신분을 증명하기 위하여 회원 스스로가 설정하여 회사에 등록한 문자, 숫자 또는 그 조합을 의미한다.',
  userArticle2element7Text: '비밀번호(Password)란, 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여 사이트에 등록한 영문과 숫자의 조합을 말한다.',
  userArticle2element8Text: '파트너란, 각 기업을 관리·운영할 권한이 있어 E스마트코드 이용약관(파트너용)에 따라 회사와 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하기로 제휴한 자를 의미한다.',
  userArticle2element9Text: '혜택이란, 파트너가 회원에게 마케팅을 목적으로 지급하는 쿠폰 등을 의미하며, 고객은 쿠폰을 사용하여 각 파트너기업 또는 협의된 온라인 오프라인에서 등에서 상품 등으로 교환하거나 할인을 받을 수 있다.',
  userArticle2element10Text: '등급이란, 매장 별로 회원에게 부여하는 지위로, 회원의 구매이력에 따른 차등적 혜택을 제공하기 위한 기준이 된다.',
  userArticle2element11Text: '모바일 앱이란, 회사가 회원에게 모바일 환경에서 서비스를 제공하기 위하여 관리·운영하는 어플리케이션(App)을 의미하며, 회원은 모바일 앱을 통하여 매장별 적립 포인트 및 혜택 확인 등 다양한 기능을 이용할 수 있다.',
  // 제3조
  userArticle3title: '제3조',
  userArticle3sub: '약관의 명시와 설명 및 개정',
  userArticle3element1Text: '회사는 이 약관의 내용과 상호 및 대표자 이름, 주소, 전화번호, 이메일, 사업자등록번호, 개인정보관리책임자 등을 회원이 쉽게 알 수 있도록 사이트에 게시한다. 다만, 약관의 구체적 내용은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있다.',
  userArticle3element2Text: '회사는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제 등에 관한 법률』, 『전자거래 기본법』, 『전자서명법』, 『정보통신망 이용촉진 등에 관한 법률』, 『소비자기본법』 등 관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있다.',
  userArticle3element3Text: '회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 사이트의 초기화면(또는 인터넷 사이트)에 그 적용일자 7일 이전부터 적용일자 전일까지 공지하기로 한다. 다만, 회원에게 불리한 약관의 개정의 경우에는 공지 외에 일정기간 내 전자우편, 전자메시지 등 전자적 수단을 통해 회원에게 변경사항을 통지하기로 한다',
  userArticle3element4Text: '제3항에 의해 변경된 약관은 관련 법령에 특별한 규정 기타 부득이한 사유가 없는 한 그 적용일자 이전으로 소급하여 적용되지 아니한다.',
  userArticle3element5Text: '제3항에 따라 공지된 적용일자 이후에 회원이 명시적으로 거부의사를 표명하지 않을 경우에는 개정된 약관에 동의하는 것으로 간주하며, 변경된 약관에 동의하지 않는 회원은 탈퇴를 요청할 수 있다. 제3항의 조치에도 불구하고 약관의 개정 사실을 알지 못함으로써 발생하는 회원의 피해에 대하여 회사는 책임을 지지 않는다.',
  userArticle3element6Text: '회원이 변경된 약관에 명시적으로 거부의사를 표명한 경우, 회사는 해당 회원에 대하여 변경된 약관을 적용할 수 없다. 다만, 회사가 변경 전 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사가 해당 고객과의 서비스 이용계약을 해지할 수 있다.',
  userArticle3element7Text: '회사는 제공하는 서비스 내의 개별 서비스에 대한 별도의 약관 또는 이용조건을 둘 수 있으며 개별 서비스에서 별도로 적용되는 약관에 대한 동의는 회원이 개별 서비스를 최초로 이용할 경우 별도의 동의절차를 거치게 된다. 이 경우 개별 서비스에 대한 이용약관 등이 이 약관에 우선한다.',
  userArticle3element8Text: '이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관련법령의 규정과 일반 상관례에 따른다.',
  // 제4조
  userArticle4title: '제4조',
  userArticle4sub: '서비스의 제공, 변경, 종료',
  userArticle4content1Text: '회사는 다음 각 호와 같은 서비스를 제공하기로 한다.',
  userArticle4element1Text: '포인트 적립 및 이용 지원 , 혜택 제공 및 이용 지원 서비스',
  // userArticle4element2Text: 위에 1로 합병,
  userArticle4element3Text: '제휴 매장 검색 서비스, 이에 수반되는 기타 서비스 일체',
  // userArticle4element4Text: 위에 3로 합병,
  // userArticle4element5Text: 위에 3로 합병,
  userArticle4content2Text: '회사는 신규 서비스로의 전환, 서비스 포기, 회사의 폐업, 합병, 분할, 영업양도, 회사 정책의 변경 등 상당한 이유가 있을 경우, 제공하고 있는 서비스의 전부 또는 일부를 변경 또는 종료할 수 있다. 이 경우, 회사는 회원에게 서비스 변경 또는 종료를 통지하고 회사에서 제시한 조건에 따라 회원에게 보상한다.',
  userArticle4content3Text: '회사는 무료로 제공하는 서비스의 전부 또는 일부를 회사의 정책 및 운영상 이유로 임의로 변경 또는 종료할 수 있으며, 이로 인한 보상은 실시하지 않는다.',
  userArticle4content4Text: '회사는 서비스를 변경 또는 종료할 경우, 변경 또는 종료될 서비스의 내용, 사유, 시기 등을 사이트 내에 명시하여 변경일(또는 종료일)로부터 30일 전부터 사전 공지하기로 한다.',
  // 제5조
  userArticle5title: '제5조',
  userArticle5sub: '서비스의 일시적 중단',
  userArticle5content1Text: '회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검·교체, 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있다.',
  userArticle5content2Text: '회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 회원이 입은 손해에 대하여 배상한다. 단, 회사의 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니하다.',
  // 제6조
  userArticle6title: '제6조',
  userArticle6sub: '회원가입 등',
  userArticle6content1Text: '회원 가입을 희망하는 자(이하 "가입신청자")는 회사가 정한 가입양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청한다.',
  userArticle6content2Text: '회사는 제1항과 같이 회원으로 가입할 것을 신청한 가입신청자 중 다음 각 호에 해당하지 않는 한 회원으로 등록한다. 만약 회사가 회원가입신청의 승인을 거부하거나 유보하는 경우, 회사는 해당 가입신청자에게 이를 통지한다.',
  userArticle6element1Text: '가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우',
  userArticle6element2Text: '회원의 요청에 의하여 탈퇴한 때로부터 1개월이 지나지 아니한 경우',
  userArticle6element3Text: '가입신청자가 기입한 회원정보에 명백한 허위 기재, 누락 또는 오기가 있는 경우',
  userArticle6element4Text: '회사가 본인확인 절차를 실시하여 본인 확인이 되지 않거나 본인이 아님이 확인된 경우',
  userArticle6element5Text: '이미 가입된 회원과 정보가 동일한 경우',
  userArticle6element6Text: '이 약관의 목적을 초과하여 부정한 용도 또는 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우',
  userArticle6element7Text: '기타 가입신청자를 회원으로 등록하는 것이 기술상 불가능하거나, 사이트의 운영 또는 관리에 현저한 지장을 준다고 판단되는 경우',
  userArticle6content3Text: '회원가입 계약의 성립시기는 회사의 승낙이 가입신청자에게 도달한 시점으로 한다.',
  userArticle6content4Text: '회원은 언제든지 본인의 개인정보를 열람하고 수정할 수 있다. 다만, 서비스 관리를 위해 필요한 단말기 식별번호(디바이스 아이디 또는 IMEI), 아이디 등은 수정할 수 없다.',
  userArticle6content5Text: '회원은 회원정보에 변경이 있는 경우, 회원정보 수정 등의 방법으로 그 변경사항을 반영하여야 한다. 회원정보 수정을 하지 않음으로써 발생하는 회원의 손해에 대하여 회사는 아무런 책임을 지지 않는다.',
  // 제7조
  userArticle7title: '제7조',
  userArticle7sub: '회원 탈퇴, 자격 제한 등',
  userArticle7content1Text: '회원은 회사에 언제든지 탈퇴를 요청할 수 있으며, 회사는 회원의 회원탈퇴 요청 즉시 회원탈퇴를 처리한다. 회원탈퇴 시 소진되지 않은 포인트 및 혜택은 회원의 의사에 따라 이에 대한 권리를 포기한 것으로 간주하여 탈퇴와 동시에 전부 소멸된다.',
  userArticle7content2Text: '회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원의 자격을 제한 또는 상실시킬 수 있다. 회원이 회원자격을 상실할 경우 보유한 포인트, 혜택은 전부 소멸된다.',
  userArticle7element1Text: '제6조 제2항의 규정에 따른 회사의 이용계약 거부 사유가 있는 경우',
  userArticle7element2Text: '회원정보에 허위 내용을 입력하거나 타인의 정보를 도용한 경우',
  userArticle7element3Text: '사이트의 이용과 관련하여 회원이 부담하는 채무를 기일에 이행하지 않는 경우',
  userArticle7element4Text: '회사나 다른 회원 및 제3자의 권리나 명예, 신용 등 기타 정당한 이익을 침해하거나 법령 또는 미풍양속에 위배되는 행위를 하는 경우',
  userArticle7element5Text: '다른 사람의 사이트 이용을 방해하거나 그 정보를 도용하는 등 부정한 행위를 하는 경우',
  userArticle7element6Text: '회사의 원활한 서비스 제공을 방해하는 행위를 하거나 시도한 경우',
  userArticle7element7Text: '주소, 전자우편 등 회원정보를 잘못 입력하거나 변경하지 않는 등 회원의 귀책사유로 인하여 회사가 상당한 노력을 기울였음에도 불구하고 회원에게 통지 또는 연락을 할 수 없게 된 경우',
  userArticle7element8Text: '이 약관 제13조 회원의 의무를 위반하는 행위를 하는 경우',
  userArticle7element9Text: '이 약관의 규정 또는 회사의 이용정책 등을 위반하거나 법령 기타 공서양속에 반하는 행위를 하는 경우',
  userArticle7content3Text: '회사는 회원 자격의 제한을 위하여 회원의 서비스 이용을 단계적으로 제한(경고, 일시정지, 영구정지 등)할 수 있다.',
  userArticle7content4Text: '회사가 회원 자격을 제한시킨 후 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원 자격을 상실시킬 수 있다.',
  userArticle7content5Text: '회원의 탈퇴, 자격 상실 등으로 서비스 이용계약이 종료된 경우, 이에 관하여 발생한 손해는 회원 자신이 부담하여야 하며, 회사는 일체의 법적 책임을 부담하지 않는다.',
  userArticle7content6Text: '회원은 본 조에 따른 자격 제한 또는 상실 조치에 대하여 회사가 정한 절차에 따라 이의를 신청할 수 있다. 회사는 회원의 이의가 정당하다고 인정할 경우 즉시 서비스의 이용을 재개하기로 한다.',
  // 제8조
  userArticle8title: '제8조',
  userArticle8sub: '휴면계정 관리',
  userArticle8content1Text: '회사는 회원이 최종접속일로부터 12개월 이상 사이트에 로그인 하지 않을 경우, 휴면계정으로 간주하고 회사가 제공하는 서비스 이용을 제한 또는 상실시킬 수 있다.',
  userArticle8content2Text: '휴면계정으로 전환된 회원은 회사가 제공하는 모든 서비스를 이용할 수 없으며, 별도의 본인인증 절차를 통해 휴면계정에서 활동계정으로 변경 시 지속적인 서비스를 받을 수 있다.',
  userArticle8content3Text: '회사는 휴면계정으로 전환하기 최소 30일 전에는 전자우편, SMS 등을 통해 휴면계정 전환대상 예정인 회원에게 사전 통지한다.',
  userArticle8content4Text: '회사는 휴면계정으로 전환된 회원의 정보 중 개인정보(이용자ID, 비밀번호, 이름, 연락처 등)는 분리하여 보관한다.',
  userArticle8content5Text: '회원이 휴면계정 전환 후 3년의 기간 동안 로그인을 통해 휴면계정 해지를 신청하지 않을 시, 회사는 해당 회원의 개인정보를 모두 파기한다.',
  userArticle8content6Text: '휴면계정 전환 이전에 지급되었던 포인트 및 혜택은 유효기간 만료 시 자동 소멸되며 복구되지 않는다.',
  // 제9조
  userArticle9title: '제9조',
  userArticle9sub: '회원에 대한 통지',
  userArticle9content1Text: '회사가 회원에 대한 통지를 하는 경우, 본 약관에 별도 규정이 없는 한 서비스 내 전자우편, SMS, 전자쪽지 등으로 할 수 있다.',
  userArticle9content2Text: '회사는 불특정다수 회원에 대한 통지의 경우 7일 이상 사이트 내 공지사항에 게시함으로써 개별 통지에 갈음할 수 있다. 다만, 회원 본인의 거래에 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지한다.',
  // 제10조
  userArticle10title: '제10조',
  userArticle10sub: '정보의 제공 및 광고의 게재',
  userArticle10content1Text: '회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내 공지사항, 서비스 화면, 모바일 앱, 전자우편, SMS, 전화 등의 방법으로 회원에게 제공할 수 있다. 다만, 회원은 관련법령에 따라 회사에게 부과된 의무이행을 위한 절차안내, 고객문의 등에 대한 답변, 기타 거래관련정보 등을 제외하고는 언제든지 수신 거절을 할 수 있다.',
  userArticle10content2Text: '회사는 서비스의 운영과 관련하여 서비스 화면, 모바일 앱, 전자우편, SMS 등을 통하여 광고를 게시 또는 전송할 수 있다. 단, 고객의 사전 동의를 받지 않는 영리목적의 광고성 정보를 게시 또는 전송하지 않는다.',
  userArticle10content3Text: '전항에도 불구하고, 회사가 본 약관에 따른 재화 또는 용역의 거래관계를 통하여 회원으로부터 직접 연락처를 수집하고, 6개월 이내에 자신이 취급하고 회원과 거래한 것과 동종의 재화 또는 용역에 대한 영리목적의 광고성 정보를 전송하려는 경우 회원의 사전동의가 없더라도 광고성 정보를 전송할 수 있다.',
  // 제11조
  userArticle11title: '제11조',
  userArticle11sub: '회사의 의무',
  userArticle11content1Text: '회사는 이 약관이 금지하거나 관련 법령 기타 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는 데 최선을 다한다.',
  userArticle11content2Text: '회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보(신용정보 포함)보호를 위한 보안시스템을 갖추어야 하며, 개인정보처리방침을 공시하고 준수한다.',
  userArticle11content3Text: '회사는 회원으로부터 제기되는 의견이나 불만이 객관적으로 정당하다고 인정될 경우에는 회원의 불만을 해결하기 위해 노력한다. 회원이 제기한 의견이나 불만사항에 대해서는 회원에게 처리과정 및 결과를 전달한다.',
  // 제12조
  userArticle12title: '제12조',
  userArticle12sub: '회원의 아이디 및 비밀번호에 대한 의무',
  userArticle12content1Text: '아이디와 비밀번호에 관한 관리책임은 회원 본인에게 있으며, 회원은 이를 소홀히 하여 발생한 모든 법적 책임을 부담한다.',
  userArticle12content2Text: '회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나, 회사 또는 회사의 운영자로 오인할 우려가 있는 경우에는 해당 아이디의 이용을 제한할 수 있다.',
  userArticle12content3Text: '회원은 자신의 아이디 및 비밀번호를 제3자에게 제공, 공개하거나 제3자가 이용하도록 해서는 아니된다.',
  userArticle12content4Text: '회원이 자신의 아이디 및 비밀번호의 유출 또는 제3자 사용에 대해 인지하고도 비밀번호를 변경하지 않은 경우 또는 이와 같은 사정을 회사에 통지하지 않거나 회사의 조치에 응하지 않은 경우 등 회원이 본 조의 의무를 준수하지 않아 발생하는 모든 손실이나 손해 등 불이익에 대한 책임은 회원에게 있다.',
  // 제13조
  userArticle13title: '제13조',
  userArticle13sub: '회원의 의무',
  userArticle13content1Text: '회원은 다음 각 호의 행위를 하여서는 아니된다.',
  userArticle13element1Text: '회원가입 신청 또는 회원정보 변경 시 허위 내용을 등록하는 행위, 타인의 정보를 도용하는 행위',
  // userArticle13element2Text: 위 1로 합병,
  userArticle13element3Text: '회사가 게시한 정보를 변경하는 행위, 다른 회원의 개인정보 및 계정정보를 수집하는 행위',
  // userArticle13element4Text: 위 3로 합병,
  userArticle13element5Text: '회사의 사전동의 없이 영리 목적으로 서비스를 이용하거나, 광고성 정보를 전송하기 위하여 서비스를 이용하는 행위',
  userArticle13element6Text: '리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통한 서비스의 복제, 분해, 모방 및 기타 변경 등을 하는 행위',
  userArticle13element7Text: '자동접속 프로그램을 사용하는 등 비정상적인 방법으로 서비스를 이용하여 회사의 서버에 부하를 일으켜 정상적인 서비스의 제공 또는 이용을 방해하는 행위',
  userArticle13element8Text: '본인이 아닌 제3자에게 접속 권한을 부여하는 행위, 회사 또는 제3자의 지적재산권을 침해하는 행위',
  // userArticle13element9Text: 위8로 합병,
  userArticle13element10Text: '회사 또는 제3자의 명예를 훼손하거나 업무를 방해하는 행위',
  userArticle13element11Text: '외설 또는 폭력적인 메시지, 화상, 음성, 기타 미풍양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위',
  userArticle13element12Text: '기타 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위',
  userArticle13content2Text: '회원은 관계법령, 본 약관, 이용안내 및 서비스와 관련하여 회사가 통지하는 사항 등을 준수하여야 하며, 위 사항을 위반하거나 기타 회사의 업무에 방해되는 행위를 하여서는 아니 된다.',
  // 제14조
  userArticle14title: '제14조',
  userArticle14sub: '개인정보보호',
  userArticle14content1Text: '회사는 서비스 제공을 위하여 필요 최소한의 범위 내에서 개인정보를 수집한다.',
  userArticle14content2Text: '회사는 개인정보를 수집하기 전 정보주체에게 그 목적을 고지하고 동의를 받기로 한다.',
  userArticle14content3Text: '회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 사전에 정보주체에게 그 목적을 고지하고 동의를 받기로 한다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 한다.',
  userArticle14content4Text: '회사가 정보주체의 동의를 받아야 하는 경우에는 개인정보 관리책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』에서 규정한 사항을 미리 명시하거나 고지해야 하며 정보주체는 언제든지 이 동의를 철회할 수 있다.',
  userArticle14content5Text: '정보주체는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취하여야 한다. 정보주체의 오류 정정 요구가 있는 경우, 회사는 그 오류를 정정할 때까지 해당 개인정보를 이용하지 않는다.',
  userArticle14content6Text: '회사는 개인정보의 보호를 위하여 정보주체의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며, 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 정보주체의 손해에 대하여 회사는 배상할 책임을 부담한다. 단, 회사에게 고의 또는 과실이 없는 경우에는 그러하지 아니하다.',
  userArticle14content7Text: '회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 처리목적을 달성하거나 보관기간이 도과한 경우에는 해당 개인정보를 지체 없이 파기하기로 한다.',
  userArticle14content8Text: '회사는 개인정보의 수집·이용·처리에 관한 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수 수집항목이 아닌 개인정보의 수집·이용·처리에 관한 정보주체의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않는다.',
  userArticle14content9Text: '회사의 사이트 이외의 링크된 외부사이트에서는 회사의 개인정보처리방침이 적용되지 않는다. 정보주체는 링크된 외부사이트의 개인정보 취급과 관련하여서는 해당 외부사이트의 개인정보처리방침을 확인할 책임을 부담한다.',
  userArticle14content10Text: '회사는 다음과 같은 경우에 법이 허용하는 범위 내에서 정보주체의 개인정보를 제3자에게 제공할 수 있다.',
  userArticle14element1Text: '수사기관이나 기타 정부기관으로부터 적법한 절차에 따른 정보제공을 요청받은 경우',
  userArticle14element2Text: '정보주체가 관련 법령을 위반하는 등의 부정행위를 확인하기 위해 필요한 경우',
  userArticle14element3Text: '서비스 제공 또는 관리에 필요한 최소한의 개인정보(성명, 주소, 전화번호)를 제공하여야 하는 경우',
  userArticle14element4Text: '서비스 이용이 종료된 이후에도 반품, 교환, 환불, 취소 등을 위하여 필요한 경우',
  userArticle14element5Text: '기타 관련 법령에 의해 요구되는 경우',
  userArticle14content11Text: '기타 본 조에서 정하지 않은 사항은 회사의 개인정보처리방침에 따른다.',
  // 제15조스마트
  userArticle15title: '제15조',
  userArticle15sub: '포인트 적립 및 이용',
  userArticle15content1Text: '회원은 상품 등을 구입하기전 E스마트코드를 통해 진위여부확인을 하고 그에따른 보상으로 파트너가 약정한 바에 따라 포인트를 제공받을수있다',
  userArticle15content2Text: '회원이 특정 상품 등을 구매하거나 포인트 또는 혜택을 결제 수단으로 사용하는 경우, 별도의 포인트 적립률이 적용되거나 포인트가 적립되지 않을 수 있다. 이 경우 회사 또는 파트너는 회원의 구매 또는 결제 시 안내할 수 있다.',
  userArticle15content3Text: '회원은 회사에서 정한 기준에 따라 포인트를 합산하여 적립 및 사용할 수 있으며, 적립한 포인트는 회사와 파트너가 협의한 범위 내의 상품 등으로 교환하거나 구매대금의 일부로 사용할 수 있다. 단, 파트너는 매장별로 혜택 사용을 위한 조건(최소 구매금액 설정, 다른 포인트 또는 혜택과의 중복사용 불가 등)을 부가할 수 있다.',
  userArticle15content4Text: '회원은 본인이 소유한 포인트를 타인에게 양도, 대여 또는 담보의 목적으로 이용할 수 없다.',
  userArticle15content5Text: '회원은 다른 회원이 적립한 포인트를 부정하게 이용하여서는 아니 되며, 회사는 포인트에 대한 적법한 권리가 있는 회원으로부터 부정이용 신고 등을 접수한 경우에는 즉시 이를 해결하기 위한 조치를 취하기로 한다.',
  userArticle15content6Text: '회원은 적립된 포인트를 금전 등 다른 지급수단의 형태로 교환 또는 환불해 줄 것을 요청할 수 없다.',
  userArticle15content7Text: '포인트 적립률 및 기준은 회사의 내부 방침에 따라 임의로 변경될 수 있으며, 회사는 회원에게 사이트, 모바일 앱, SMS를 통하여 사전에 변경내용을 고지한다.',
  userArticle15content8Text: '회원정보의 변경으로 인한 포인트 이전 등의 처리는 회사의 내부 방침에 따른다.',
  userArticle15content9Text: '기타 이 약관 및 사이트의 이용안내에 규정되지 않은 사항에 대해서는 소비자 피해보상규정에서 정한 바에 따른다.',
  // 제16조
  userArticle16title: '제16조',
  userArticle16sub: '포인트 충전',
  userArticle16content1Text: '회원은 회사 또는 파트너가 인정하는 결제수단으로 파트너에게 대가를 미리 지급하여 포인트를 충전할 수 있다.',
  userArticle16content2Text: '전자금융거래법에 의거 포인트의 충전한도는 회원의 실명이 확인된 경우에는 최대 200만원, 그렇지 않은 경우에는 최대 50만원으로 한다. 포인트 충전한도는 전자금융거래법 등 관련 법령이 개정되면 변경될 수 있다.',
  userArticle16content3Text: '다음 각호에 해당하면 회사 또는 파트너 회원의 충전을 제한할 수 있다.',
  userArticle16element1Text: '최대 충전한도를 초과한 경우',
  userArticle16element2Text: '비정상적인 방법으로 서비스를 이용하는 것으로 판단되는 경우',
  userArticle16element3Text: '타인의 명의, 계좌정보 등을 도용하여 포인트를 충전하는 것으로 판단되는 경우',
  userArticle16element4Text: '기타 관련 법령에 의해 요구되는 경우',
  userArticle16content4Text: '회원은 본 조의 포인트 충전에 관한 사항은 제휴 파트너의 각 정책에 따라 운영되고 있으며, 회원과 파트너 사이에 포인트 충전에 관한 분쟁이 발생할 경우에도 회사는 해당 분쟁을 해결하거나 중재할 의무가 없으며, 이에 관하여 아무런 법적 책임을 부담하지 않는 것임을 이해하였으며, 이에 동의한다.',
  // 제17조
  userArticle17title: '제17조',
  userArticle17sub: '포인트 정정, 취소',
  userArticle17content1Text: '적립된 포인트에 오류가 있을 경우 회원은 오류발생 시점부터 7일 이내 회사나 파트너에 정정 신청을 하여야 하며, 회사나 파트너는 회원의 정정 신청일로부터 최대 30일 이내에 조치를 취하기로 한다. 단, 회원은 필요한 경우 포인트 적립 오류를 증명할 수 있는 객관적 자료(영수증 등)를 제시하여야 한다.',
  userArticle17content2Text: '회원이 상품 등의 구매취소, 반품, 기타의 사유에 의해 포인트의 적립이 취소되는 경우 취소분만큼 기 지급된 포인트는 자동으로 차감된다. 포인트 잔액이 포인트 취소분 보다 부족한 경우 포인트는 마이너스 포인트로 관리되며, 추후 적립되는 포인트와 상계된다. 다만, 다음 각 호의 경우 마이너스 1포인트 당 1원으로 회사 또는 파트너가 회원에게 청구할 수 있다.',
  userArticle17element1Text: '회원이 탈퇴한 경우 또는 자격상실 된 경우',
  userArticle17element2Text: '회사 또는 파트너 매장의 정책상 마이너스 포인트 외의 방법으로 관리하기로 정한 경우',
  // 제18조
  userArticle18title: '제18조',
  userArticle18sub: '포인트 유효기간 및 소멸',
  userArticle18content1Text: '포인트의 유효기간은 회사 또는 파트너의 각 정책에 따르며, 회사 또는 파트너는 매장별 포인트의 유효기간을 회원의 구매 또는 결제 시 안내하여야 한다. 위 유효기간 내에 사용되지 않은 포인트는 유효기간 만료일의 익월에 먼저 적립된 포인트부터 월 단위로 자동 소멸된다.',
  userArticle18content2Text: '전항에도 불구하고 회원이 다음 각 호의 사유에 해당하는 경우, 포인트가 각 호에서 정한 바에 따라 소멸된다.',
  userArticle18content3Text: '회원탈퇴로 인한 포인트 소멸 : 회원탈퇴 요청일 현재까지 적립된 포인트는 사용이 불가하며, 적립된 포인트의 유효기간 만료 이내에 재가입하더라도 복원되지 않는다.',
  userArticle18content4Text: '자격상실로 인한 포인트 소멸 : 적립된 포인트는 복구가 불가한 상태로 즉시 소멸되며, 적립된 포인트의 유효기간 만료 이내에 재가입하더라도 복원되지 않는다.',
  userArticle18content5Text: '회사는 유효기간 만료에 따른 소멸예정 포인트, 소멸시기 등 포인트 소멸과 관련된 내용을 회원이 모바일 앱에서 확인할 수 있도록 조치한다.',
  // 제19조
  userArticle19title: '제19조',
  userArticle19sub: '혜택 제공 및 이용',
  userArticle19content1Text: '회원은 파트너의 혜택 제공에 대하여 동의 또는 거부할 수 있으며, 파트너는 동의를 표시한 회원에 대하여 SMS, 전자우편 또는 그 밖의 수단으로 혜택을 전송할 수 있다.',
  userArticle19content2Text: '회원은 매장별로 정한 기준에 따라 혜택을 사용함으로써 해당 매장의 상품 등으로 교환하거나 할인된 가격으로 구매할 수 있다. 단, 파트너는 매장별로 혜택 사용을 위한 조건(최소 구매금액 설정, 포인트 또는 다른 혜택과의 중복사용 불가 등)을 부가할 수 있다.',
  userArticle19content3Text: '회원은 유효기간 내에 혜택을 사용하여야 하며, 유효기간이 경과한 혜택은 자동 소멸된다. 단, 회원탈퇴 또는 자격상실 시 지급받은 혜택은 자동으로 소멸된다.',
  userArticle19content4Text: '회원이 과실 또는 고의로 수령한 혜택을 삭제한 경우에는 매장에서 신분증을 통해 본인확인 절차를 거친 후에 혜택의 복구를 요청할 수 있다. 단, 파트너는 매장별 상황을 고려하여 복구 요청을 거절할 수 있다.',
  userArticle19content5Text: '회원은 본인이 소유한 혜택을 다른 회원에게 양도할 수 없다.',
  userArticle19content6Text: '회원은 수령한 혜택을 금전 등 다른 지급수단의 형태로 교환 또는 환불해 줄 것을 요청할 수 없다.',
  userArticle19content7Text: '회원정보의 변경으로 인한 혜택 이전 등의 처리는 회사의 내부 방침에 따른다.',
  userArticle19content8Text: '기타 이 약관 및 사이트의 이용안내에 규정되지 않은 사항에 대해서는 소비자 피해보상규정에서 정한 바에 따른다.',
  // 제20조
  userArticle20title: '제20조',
  userArticle20sub: '모바일 앱 이용',
  userArticle20content1Text: '회원은 모바일 앱을 이용하여 매장별 포인트 적립 및 혜택 지급 현황을 확인하거나, 회원의 현재 위치 주변에 소재한 제휴 매장을 검색할 수 있다.',
  userArticle20content2Text: '회원이 모바일 앱을 이용하고자 하는 경우, 회원은 회사가 회원의 위치정보를 이용한 서비스 제공을 목적으로 하는 E스마트코드 위치정보 이용약관에 동의하고, 본인의 위치정보를 회사에 제공하여야 한다.',
  // 제21조
  userArticle21title: '제21조',
  userArticle21sub: '권리의 귀속',
  userArticle21content1Text: '서비스에 관한 일체의 저작권 등 지적재산권은 회사에 귀속된다.',
  userArticle21content2Text: '회사가 제공하는 서비스의 디자인, 회사가 만든 텍스트, 스크립트, 그래픽, 회원 상호 간 전송 기능 등 회사가 제공하는 서비스에 관련된 모든 상표, 서비스 마크, 로고 등에 관한 저작권 등 지적재산권은 대한민국 및 외국의 법령에 기하여 회사가 보유하고 있거나 사용할 권한이 있다.',
  userArticle21content3Text: '회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 콘텐츠 등을 이용할 수 있는 이용권한만을 부여하며, 회원은 이를 양도, 판매, 담보제공하는 등으로 처분할 수 없다.',
  userArticle21content4Text: '회원은 서비스를 이용하면서 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 아니된다.',
  // 제22조
  userArticle22title: '제22조',
  userArticle22sub: '회사의 면책',
  userArticle22content1Text: '회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제된다.',
  userArticle22content2Text: '회사는 아래 각 호에서 정하는 바에 따라 파트너의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않는다.',
  userArticle22element1Text: '파트너(온.오프라인매장 포함)의 휴업, 폐업, 회사와의 약정해지, 서비스 관리부실 등의 사유로 인하여 회원이 적립한 포인트 및 수령한 혜택의 이용이 불가할 수 있으며, 회사는 이와 관련하여 회원이 입은 손해에 대하여 책임을 부담하지 않는다.',
  userArticle22element2Text: '파트너(온.오프라인매장 포함)와 회원 사이에 포인트 및 혜택의 제공, 이용 또는 이와 관련한 일체의 분쟁이 발생할 경우, 회사는 해당 분쟁을 해결하거나 중재할 의무가 없으며, 이에 관하여 아무런 법적 책임도 부담하지 않는다.',
  userArticle22content3Text: '회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 서비스를 통하여 알게 된 정보를 이용함에 따라 입은 손해에 대하여 책임을 지지 않는다.',
  userArticle22content4Text: '회사는 회원이 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않는다.',
  userArticle22content5Text: '회사는 회원 간 또는 회원과 제3자 상호 간에 본 약관에서 정한 범위를 초과하여 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제된다.',
  userArticle22content6Text: '회사는 무료로 제공되는 서비스 이용과 관련하여 관련 법령에 특별한 규정이 없는 한 책임을 지지 않는다.',
  userArticle22content7Text: '회사는 통신기기, 회선 및 컴퓨터의 장애, 접속의 폭주, 제3자의 해킹, 전송된 데이터의 오류 및 생략, 누락, 파괴 등 부득이한 사정으로 서비스를 정상적으로 제공하지 못하여 발생하는 손해에 대하여 책임을 지지 않는다.',
  userArticle22content8Text: '회사는 서비스 대한 제3자의 모든 불법적인 접속, 전송, 이용 등으로부터 발생하는 손해에 대하여 책임을 지지 않는다.',
  userArticle22content9Text: '회사는 제3자가 서비스 내 화면 또는 링크된 웹사이트를 통하여 광고한 상품 등의 내용과 품질에 대하여 감시 의무 및 기타 어떠한 책임도 지지 않는다.',
  userArticle22content10Text: '회사는 회원 간의 회원정보 등록, 서비스 이용 과정에서 발생하는 명예훼손, 개인정보침해 또는 기타 불법행위로 인하여 발생하는 손해에 대하여 책임을 지지 않는다.',
  // 제23조
  userArticle23title: '제23조',
  userArticle23sub: '손해배상',
  userArticle23content1Text: '회사 또는 회원 중 어느 일방이 본 약관에 정한 사항을 이행하지 아니하거나 위반하여 상대방에게 손해를 입힌 경우, 위반당사자의 책임과 비용으로 상대방이 입은 손해를 배상하기로 한다.',
  userArticle23content2Text: '상대방이 위반당사자의 위반행위로 인하여 제3자로부터 손해배상의 청구 또는 소송의 제기 등 각종 이의제기를 받을 경우, 위반당사자는 자신의 책임과 비용으로 상대방을 면책시켜야 한다.',
  // 제24조
  userArticle24title: '제24조',
  userArticle24sub: '해외 이용',
  userArticle24content: '회사는 회원이 대한민국의 영토 이외의 지역(이하 해외)에서 서비스를 이용하고자 하는 경우, 서비스의 품질 및 사용성을 보장하지 않는다. 따라서 회원은 해외에서 서비스를 이용하고자 하는 경우 스스로의 판단과 책임 하에 서비스 이용 여부를 결정하여야 하고, 서비스의 이용 과정에서 현지 법령을 준수할 책임은 회원 본인에게 있다.',
  // 제25조
  userArticle25title: '제25조',
  userArticle25sub: '권리, 의무의 양도금지',
  userArticle25content: '회사와 회원은 상대방의 사전 서면동의 없이 이 약관상의 권리 또는 의무의 전부 또는 일부를 제3자에게 양도, 이전, 증여하거나 이를 담보로 제공할 수 없다.',
  // 제26조
  userArticle26title: '제26조',
  userArticle26sub: '기타',
  userArticle26content1Text: '이 약관에서 규정하지 아니한 사항은 관계법령 및 일반 거래 관행에 의한다.',
  userArticle26content2Text: '본 약관에 대한 해석 및 분쟁은 대한민국의 법률을 적용하며, 소송이 제기되는 경우에는 서울중앙지방법원을 전속관할로 한다.',
  // 부칙
  userArticleLast: '부칙',
  userArticleDate: '이 약관은 2021년 02월 22일부터 시행한다',



  // 개인정보 보호정책
  privacyPolicy: '스텔라이트 개인정보 취급방침',
  privacyPolicysub: '주식회사 스텔라이트(이하 회사)는 개인정보보호법 등 관련법령에 따라 정보주체의 개인정보 및 권익을 보호하고, 개인정보와 관련한 정보주체의 고충을 원활하게 처리할 수 있도록 다음과 같은 개인정보 처리방침을 두고 있습니다.',
  // 제1조
  Policy1title: '제1조',
  Policy1sub: '개인정보 처리 항목',
  Policy1content1text: '회사는 서비스를 위한 목적에 필요한 범위에서 최소한의 개인정보를 다음과 같이 처리합니다. 선택항목을 입력하지 않아도 회원가입이나 기본적 서비스 이용에 제한이 없으나, 부가적인 서비스의 이용에는 제한이 있을 수 있습니다.',
  Policy1element1text: '서비스 회원가입 및 관리',
  Policy1element1textsub1: '- 필수항목 : 이름, 생년월일, 성별, 휴대폰전화번호, 아이디, 비밀번호',
  Policy1element1textsub2: '- 선택항목 : 주소, 이메일, 사업자등록번호, 계좌번호',
  Policy1element2text: '민원사무 처리 신청',
  Policy1element2textsub1: '- 이름, 아이디, 주소, 연락처 등 상담을 위해 필요한 정보',
  Policy1element3text: '서비스 제공 및 결제',
  Policy1element3textsub1: '- 이름, 아이디, 이메일, 주소, 연락처 등 서비스 수령을 위한 정보',
  Policy1element3textsub2: '- 사업자등록번호, 계좌정보 등 결제/환급을 위한 정보',
  Policy1element4text: '마케팅 및 광고에의 활용',
  Policy1element4textsub1: '- 이름, 연락처, 성별, 생년월일, 서비스 이용정보 등',
  Policy1element5text: '서비스 개발 및 개선',
  Policy1element5textsub1: '- 성별, 나이, 지역, 서비스 이용이력 등',
  Policy1element5textsub2: '- 회사는 위 개인정보 처리와 관련하여 특정 개인을 알아볼 수 없도록 비식별화 조치를 취한 후 조치가 완료된 정보를 이용 및 제3자에게 제공합니다.',
  Policy1content2text: '서비스 이용 과정에서 IP주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다.',
  // 제2조
  Policy2title: '제2조',
  Policy2sub1: '개인정보 수집·이용 및 목적',
  Policy2sub2: '회사는 서비스를 위한 목적에 필요한 범위에서 최소한의 개인정보를 수집 및 이용합니다. 수집한 개인정보는 다음의 목적 외 용도로는 이용되지 않으며, 이용 목적이 변경될 경우 회원의 사전 동의를 구합니다.',
  Policy2content1text: '서비스 회원가입 및 관리',
  Policy2content1textsub: '- 서비스 가입의사 확인, 회원자격 유지·관리, 서비스 부정이용 방지, 고충처리, 분쟁 조정을 위한 기록 보존 등',
  Policy2content2text: '민원사무 처리',
  Policy2content2textsub: '- 민원인의 신원 확인, 민원사항 확인, 처리결과 통보 등',
  Policy2content3text: '서비스 제공 및 결제',
  Policy2content3textsub: '- 서비스 제공, 본인인증, 맞춤 서비스 제공, 결제 또는 환급 등',
  Policy2content4text: '마케팅 및 광고에의 활용',
  Policy2content4textsub: '- 이벤트 및 광고성 정보 제공 및 참여기회 제공, 마케팅 및 광고 콘텐츠제작 및 게재 등',
  Policy2content5text: '서비스 회원가입 및 관리',
  Policy2content5textsub: '- 신규 서비스 및 개발, 맞춤 서비스 제공, 서비스의 유효성 확인, 접속 빈도파악, 회원의 서비스 이용에 대한 통계 분석 등',
  // 제3조
  Policy3title: '제3조',
  Policy3sub1: '개인정보의 제공 및 위탁',
  Policy3content1text: '회사는 회원의 동의, 법률의 특별한 규정 등 개인정보보호법제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다. 회원이 회사에서 제공하는 서비스를 이용하기 위하여 제3자에 개인정보를 제공하는 목록은 다음과 같으며, 아래 서비스를 이용하지 않는 회원의 개인정보는 제3자에 제공되지 않습니다.',
  Policy3block1: '제공 받는 자 (1)',
  Policy3block2: '제공목적',
  Policy3block3: '제공정보',
  Policy3block4: '보유 및 이용기간',
  Policy3blocktext1: '카카오',
  Policy3blocktext2: '카카오의 옐로아이디를 통하여 매장에서 구매/적립내역을 카카오톡을 통해 확인문자를 전송하거나, 매장에서 쿠폰 또는 공지사항을 이용자에게 전송 등 옐로아이디 서비스를 사용하여 매장과 이용자를 연결시켜 줄 수 있도록 서비스 제공하기 위하여',
  Policy3blocktext3: '전화번호',
  Policy3blocktext4: '서비스 해지 시점 후 3개월',
  Policy3blocktext5: '서비스 가맹점 또는 브랜드',
  Policy3blocktext6: '브랜드 멤버십 혜택 및 이벤트를 진행하기 위하여',
  Policy3blocktext7: '전화번호, 서비스 이용기록, 생일, 성별, 이름',
  Policy3blocktext8: '서비스 해지 시점 후 3개월',
  Policy3blocktext9: '페이스북 (2)',
  Policy3blocktext10: '페이스북 및 인스타그램의 기능을 활용하여 e스마트코드 회원에게 e스마트코드 가맹점의 상품 및 쿠폰을 소개하는 서비스를 제공하기 위하여',
  Policy3blocktext11: '전화번호',
  Policy3blocktext12: '제휴 해지 시점 후 3개월',
  Policy3content1subtext1: '서비스의 개인정보를 제공 받는 자는 1) 이용자 방문 가맹점 및 2) 이용자가 제3자 정보 제공에 동의한 가맹점에 한정되며, 그러한 가맹점의 변동에 따른 개인정보취급방침의 수시개정이 어려워 가맹점을 페이지 링크로 명시합니다.',
  Policy3content1subtext2: '페이스북은 ㈜스텔라이트로부터 제공받는 전화번호를 공식적인 암호화 기술(Hashing) 처리하여 다른 용도로 사용하지 않고, 원칙적으로 ㈜스텔라이트와의 계약 종료 즉시 삭제합니다.',
  Policy3content2text: '회사는 원활한 서비스 제공을 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있으며, 회사가 수탁업체에 위탁하는 업무와 관련된 서비스를 이용하지 않는 회원의 개인정보는 수탁업체에 제공되지 않습니다. 회사는 위탁계약 시 개인정보보호법제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치 및 책임부담 등이 명확히 규정된 문서에 따라 처리하고 있습니다.',
  Policy3block5: '수탁업체',
  Policy3block6: '위탁업무 내용',
  Policy3blocktext13: '㈜중소기업유통센터',
  Policy3blocktext14: '제품의 A/S 접수 및 상담, 고객만족도조사, 민원처리, 해피콜(사후확인, 설치 배송안내 등) 등 콜센터 업무일체',
  Policy3blocktext15: '㈜인포뱅크',
  Policy3blocktext16: '적립확인 문자, e스마트코드 서비스 공지사항, 또는 문자 쿠폰 전송 시 SMS, LMS, MMS 등의 문자를 전송',
  Policy3blocktext17: '한국정보통신㈜',
  Policy3blocktext18: '한국정보통신의 이지포스를 이용한 물품 거래시 자동 적립 서비스를 제공',
  Policy3blocktext19: 'CJ대한통운㈜',
  Policy3blocktext20: '태블릿을 포함한 서비스 기기의 배송',
  Policy3blocktext21: '㈜SK플래닛',
  Policy3blocktext22: '모바일 전자지갑에서 적립 포인트 열람, 매장 찾기 기능 제공',
  Policy3content3text: '회사는 본 조의 개인정보의 제3자 제공 및 위탁업무의 내용이 변경될 경우에는 지체 없이 개인정보처리방침을 통하여 고지하겠습니다.',
  // 제4조
  Policy4title: '제4조',
  Policy4sub1: '개인정보의 보유 및 파기',
  Policy4sub2: '이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.',
  Policy4content1text: '회사는 원칙적으로 회원의 개인정보를 회원 탈퇴 시까지 보유하며, 회원 탈퇴 시 지체없이 파기합니다. 단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.',
  Policy4element1text: '전자상거래 등에서 소비자 보호에 관한 법률',
  Policy4element1textsub1: '- 표시·광고에 관한 기록 : 6개월',
  Policy4element1textsub2: '- 계약 또는 청약철회 등에 관한 기록 : 5년 보관',
  Policy4element1textsub3: '- 대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관',
  Policy4element1textsub4: '- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관',
  Policy4element2text: '전자금융거래법',
  Policy4element2textsub1: '- 전자금융에 관한 기록 : 5년 보관',
  Policy4element3text: '통신비밀보호법',
  Policy4element3textsub1: '- 로그인 기록 등 방문 기록 : 3개월',
  Policy4content2text: '회원탈퇴, 서비스 종료, 이용자에게 동의 받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기합니다. 법령에서 보존의무를 부과한 정보는 해당 법령에서 정한 기간이 경과한 후 지체없이 재생이 불가능한 방법으로 파기합니다.',
  Policy4content3text: '회사는 ‘개인정보 유효기간제’에 따라 1년 간 로그인하지 않거나 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리하여 보관 및 관리합니다.',
  // 제5조
  Policy5title: '제5조',
  Policy5sub: '정보주체 및 법정대리인의 권리와 행사 방법',
  Policy5content1text: '회원은 회사에 대해 언제든지 서면, 전자우편 등의 방법으로 개인정보 열람·정정·삭제·처리정리 요구 등의 권리를 행사할 수 있습니다.',
  Policy5content2text: '제1항에 따른 권리 행사는 회원의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.',
  Policy5content3text: '회원이 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.',
  Policy5content4text: '개인정보 열람 및 처리정지 요구는 개인정보 보호법 제37조제2항 및 관련 법령에 의하여 회원의 권리가 제한될 수 있습니다.',
  // 제6조
  Policy6title: '제6조',
  Policy6sub1: '개인정보의 안정성 확보조치',
  Policy6sub2: '회사는 개인정보를 안전하게 관리하기 위하여 최선을 다하며 다음과 같은 조치를 취하고 있습니다.',
  Policy6elementtextsub1: '- 관리적 조치 : 내부관리계획 수립·시행',
  Policy6elementtextsub2: '- 물리적 조치 : 개인정보시스템의 물리적 보관 장소에 대한 비인가자 출입 통제',
  Policy6elementtextsub3: '- 기술적 조치 : 개인정보에 대한 접근 제한, 중요정보에 대한 암호화, 보안프로그램 설치 등',
  // 제7조
  Policy7title: '제7조',
  Policy7sub1: '개인정보 처리책임자',
  Policy7sub2: '회사는 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 처리책임자를 지정하고 있습니다.',
  Policy7block1: '개인정보 보호책임자(담당자)',
  Policy7blocktext1: '- 이름 : 김학우',
  Policy7blocktext2: '- 소속 : 스텔라이트',
  Policy7blocktext3: '- 직위 : 대표',
  Policy7blocktext4: '- 연락처 : admin@ksmartcode.com',
  // 제8조
  Policy8title: '제8조',
  Policy8sub: '개인정보처리방침 적용범위',
  Policy8content1text: '본 개인정보처리방침은 회사가 제공하는 제반 서비스에 적용됩니다.',
  Policy8content2text: '회사가 제공하는 부가적 서비스 중 별도의 회원가입이 필요한 서비스 및 회사의 사이트에 링크되어 있는 제휴 사이트 이용자의 개인정보를 수집하는 행위에 대해서는 본 개인정보 처리방침이 적용되지 않습니다.',
  // 제9조
  Policy9title: '제9조',
  Policy9sub1: '개정 전 고지의무',
  Policy9sub2: '본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 최소 7일 전에 ‘공지사항’을 통해 사전 공지하겠습니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자 동의를 다시 받을 수도 있습니다.',
  Policy9elementtextsub1: '- 공고일자 : 2021년 2월 22일',
  Policy9elementtextsub2: '- 시행일자 : 2021년 2월 22일',


};

export default StringKo;
