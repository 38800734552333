import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import Flash from 'react-reveal/Flash';
import RevealFade from 'react-reveal/Fade';

class Intro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupVisible: false,
      open: false
    }
  }
  ChangeNav = () => {
    this.setState(
      prevState => ({ open: !prevState.open })
    )
  };
  setLanguage = (lang) => {
    this.props.setLanguage(lang)
  }
  render() {
    const containerClasses = [];
    if (this.state.open) {
      containerClasses.push("open")
    }
    const { language, string } = this.props;

    return (
      <div className="ksmartcode-intro">
        <div className="ksmartcode-intro-header">
          <div className="ksmartcode-intro-header-all">
            <div className="ksmartcode-intro-header-left">
              <Link to='/' className="text-link">
                <img src={require('../../img/kslogo2.png')}
                  className="header-logo" />
              </Link>
            </div>
            <div className="ksmartcode-intro-header-menu">
              <div className="ksmartcode-intro-header-menu-center">
                <div className="ksmartcode-intro-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.blockchainService}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/intro' className="text-link2">
                        {string.intro}
                      </Link>
                      <Link to='/companyIntro' className="text-link2">
                        {string.safetyTitle}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-intro-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.serviceAll}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/serviceUse' className="text-link2">
                        {string.serviceUse}
                      </Link>
                      <Link to='/enterUse' className="text-link2">
                        {string.customUse}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-intro-header-menu-center-element">
                  <Link to='/Charge' className="text-link2">
                    {string.serviceCharge}
                  </Link>
                </div>
                <div className="ksmartcode-intro-header-menu-center-element">
                  <Link to='/marketing' className="text-link2">
                    {string.marketing}
                  </Link>
                </div>
                <div className="ksmartcode-intro-header-menu-center-element">
                  <Link to='/shopping' offset='70' className="text-link2">
                    {string.shopping}
                  </Link>
                </div>
                <div className="ksmartcode-intro-header-menu-center-element">
                  <Link to='/nft' offset='70' className="text-link2">
                    {string.nftMarket}
                  </Link>
                </div>
              </div>
              <div className="ksmartcode-intro-header-menu-right">
                <div className="ksmartcode-intro-header-menu-right-element">
                  <Link to='/customer' className="text-link3">
                    {string.customerCenter}
                  </Link>
                </div>
                <Link to='/qna' className="text-link">
                  <div className="ksmartcode-intro-header-menu-right-element2">
                    <Link to='/qna' className="text-link">
                      {string.consulting}
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="ksmartcode-intro-header-hide">
              <div className="ksmartcode-intro-header-hide-menu">
                <div className={containerClasses.join(' ')}>
                  <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                    <MenuButton2 state={!this.state.open} /></div>
                  <div className={"sidenav"}>
                    <a href="/">{string.homePage}</a>
                    <a href="./intro">{string.intro}</a>
                    <a href="./companyintro">{string.safetyTitle}</a>
                    <a href="./serviceuse">{string.serviceUse}</a>
                    <a href="./enteruse">{string.customUse}</a>
                    <a href="./charge">{string.serviceCharge}</a>
                    <a href="./marketing">{string.marketing}</a>
                    <a href="./shopping">{string.shopping}</a>
                    <a href="./nft">{string.nftMarket}</a>
                    <a href="./customer">{string.customerCenter}</a>
                    <a href="./qna">{string.consulting}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 회사소개 첫번째 섹션 */}
        <div className="ksmartcode-intro-main">
          <div className="ksmartcode-intro-main-all">
            <Flash duration={2000}>
              <div className="ksmartcode-intro-main-title">
                {string.blockChainQrSystem1}
                <br />
                {string.blockChainQrSystem2}
              </div>
            </Flash>
            <RevealFade duration={2500} delay={2500}>
              <div className="ksmartcode-intro-main-sub2">
                {string.blockChainQrSystemSub}
              </div>
            </RevealFade>
          </div>
        </div>

        {/* 회사소개 두번째 섹션 */}
        <div className="ksmartcode-intro-sub">
          <div className="ksmartcode-intro-sub-all">
            <div className="ksmartcode-intro-sub-content">
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-text">
                  {string.ksmartCodeTeam1}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-text">
                  {string.ksmartCodeTeam2}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-text">
                  {string.ksmartCodeTeam3}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-text">
                  {string.ksmartCodeTeam4}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <img src={require('../../img/intro/intro2.png')}
                  className="intro-section2-img" />
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-element1">
                  {string.ksmartCodeMission1}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-element2">
                  {string.ksmartCodeMission2}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-element2">
                  {string.ksmartCodeMission3}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-element2">
                  {string.ksmartCodeMission4}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-element2">
                  {string.ksmartCodeMission5}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-element2">
                  {string.ksmartCodeMission6}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-element2">
                  {string.ksmartCodeMission7}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-element2">
                  {string.ksmartCodeMission8}
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-intro-sub-element2">
                  {string.ksmartCodeMission9}
                </div>
              </RevealFade>
            </div>

            <div className="ksmartcode-intro-sub-partner">
              <div className="ksmartcode-intro-sub-partner-block1">
                <RevealFade bottom duration={1000}>
                  <img src={require('../../img/intro/img-team01.png')}
                    className="intro-section2-team-img" />
                </RevealFade>
                <RevealFade bottom duration={1000}>
                  <div className="ksmartcode-intro-sub-partner-name">
                    {string.ksmartCodePartner1Name}
                  </div>
                </RevealFade>
                <RevealFade bottom duration={1000}>
                  <div className="ksmartcode-intro-sub-partner-job">
                    {string.ksmartCodePartner1Job}
                  </div>
                </RevealFade>
                <div className="ksmartcode-intro-sub-partner-ceo">
                  <RevealFade bottom duration={1000}>
                    <li> {string.ksmartCodePartner1Sub1}</li>
                  </RevealFade>
                  <RevealFade bottom duration={1000}>
                    <li> {string.ksmartCodePartner1Sub2}</li>
                  </RevealFade>
                  <RevealFade bottom duration={1000}>
                    <li> {string.ksmartCodePartner1Sub3}</li>
                  </RevealFade>
                  <RevealFade bottom duration={1000}>
                    <li> {string.ksmartCodePartner1Sub4}</li>
                  </RevealFade>
                </div>
              </div>

              <div className="ksmartcode-intro-sub-partner-block2">
                <RevealFade bottom duration={1000}>
                  <img src={require('../../img/intro/img-team02.png')}
                    className="intro-section2-team-img" />
                </RevealFade>
                <RevealFade bottom duration={1000}>
                  <div className="ksmartcode-intro-sub-partner-name">
                    {string.ksmartCodePartner2Name}
                  </div>
                </RevealFade>
                <RevealFade bottom duration={1000}>
                  <div className="ksmartcode-intro-sub-partner-job">
                    {string.ksmartCodePartner2Job}
                  </div>
                </RevealFade>
                <div className="ksmartcode-intro-sub-partner-ceo">
                  <RevealFade bottom duration={1000}>
                    <li>{string.ksmartCodePartner2Sub1}</li>
                  </RevealFade>
                  <RevealFade bottom duration={1000}>
                    <li>{string.ksmartCodePartner2Sub2}</li>
                  </RevealFade>
                  <RevealFade bottom duration={1000}>
                    <li>{string.ksmartCodePartner2Sub3}</li>
                  </RevealFade>
                  <RevealFade bottom duration={1000}>
                    <li>{string.ksmartCodePartner2Sub4}</li>
                  </RevealFade>
                </div>
              </div>
            </div>

          </div>
        </div>



        <div className="ksmartcode-footer">
          <div className="ksmartcode-footer-all">
            <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
            <div className="ksmartcode-footer-address">{string.addressInfo}</div>
            <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
            <div className="ksmartcode-footer-term1">
              <Link to='/terms' offset='70' className="text-link5">
                {string.terms1}
              </Link>
            </div>
            <div className="ksmartcode-footer-term2">
              <Link to='/privacy' offset='70' className="text-link5">
                {string.terms2}
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};
export default withAlert()(connect(mapStateToProps)(Intro));
