import React from "react";
import { withAlert } from 'react-alert'
import { connect } from "react-redux";
import { Route, Link } from 'react-router-dom';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { Form, Input, Button, } from "antd";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import MenuButton2 from './../btn/MenuButton2';
import RevealFade from 'react-reveal/Fade';

const FormItem = Form.Item;

class Customer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupVisible: false,
      open: false,
      qnaType01: false,
      qnaType02: false,
      qnaType03: false,
      qnaType04: false,
      qnaType05: false,
    }
    this.formRef = React.createRef();
  }

  OnCreateInquiry = (e) => {
    let info = JSON.stringify({
      ...this.formRef.current.getFieldsValue(),
      //추가 지정해서 보내기
      category: 'CUSTOMER',
      title: '1대1 문의',
    });

    httpPost(httpUrl.inquiryCreate, [], info).then(response => {
      console.log('result=' + JSON.stringify(response.data))
      if (response.data == "SUCCESS") {
        alert('문의가 성공적으로 접수되었습니다. 빠른시일내 연락드리겠습니다.')
        this.formRef.current.resetFields();
      }
      else {
        alert('알수없는 문제로 문의에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
      }
    }).catch(e => {
      console.log('err=' + JSON.stringify(e))
    })

  }

  ChangeNav = () => {
    this.setState(
      prevState => ({ open: !prevState.open })
    )
  };
  setLanguage = (lang) => {
    this.props.setLanguage(lang)
  }
  render() {
    const containerClasses = [];
    if (this.state.open) {
      containerClasses.push("open")
    }
    const { language, string } = this.props;

    const { form } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    return (
      <div className="ksmartcode-customer">
        <div className="ksmartcode-customer-header">
          <div className="ksmartcode-customer-header-all">
            <div className="ksmartcode-customer-header-left">
              <Link to='/' className="text-link">
                <img src={require('../../img/kslogo2.png')}
                  className="header-logo" />
              </Link>
            </div>
            <div className="ksmartcode-customer-header-menu">
              <div className="ksmartcode-customer-header-menu-center">
                <div className="ksmartcode-customer-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.blockchainService}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/intro' className="text-link2">
                        {string.intro}
                      </Link>
                      <Link to='/companyIntro' className="text-link2">
                        {string.safetyTitle}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-customer-header-menu-center-element">
                  <div class="dropdown-page">
                    <div class="dropdown-page-div">
                      {string.serviceAll}
                    </div>
                    <div class="dropdown-page-content">
                      <Link to='/serviceUse' className="text-link2">
                        {string.serviceUse}
                      </Link>
                      <Link to='/enterUse' className="text-link2">
                        {string.customUse}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-customer-header-menu-center-element">
                  <Link to='/Charge' className="text-link2">
                    {string.serviceCharge}
                  </Link>
                </div>
                <div className="ksmartcode-customer-header-menu-center-element">
                  <Link to='/marketing' className="text-link2">
                    {string.marketing}
                  </Link>
                </div>
                <div className="ksmartcode-customer-header-menu-center-element">
                  <Link to='/shopping' offset='70' className="text-link2">
                    {string.shopping}
                  </Link>
                </div>
                <div className="ksmartcode-customer-header-menu-center-element">
                  <Link to='/nft' offset='70' className="text-link2">
                    {string.nftMarket}
                  </Link>
                </div>
              </div>
              <div className="ksmartcode-customer-header-menu-right">
                <div className="ksmartcode-customer-header-menu-right-element">
                  <Link to='/customer' className="text-link3">
                    {string.customerCenter}
                  </Link>
                </div>
                <Link to='/qna' className="text-link">
                  <div className="ksmartcode-customer-header-menu-right-element2">
                    <Link to='/qna' className="text-link">
                      {string.consulting}
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="ksmartcode-customer-header-hide">
              <div className="ksmartcode-customer-header-hide-menu">
                <div className={containerClasses.join(' ')}>
                  <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                    <MenuButton2 state={!this.state.open} /></div>
                  <div className={"sidenav"}>
                    <a href="/">{string.homePage}</a>
                    <a href="./intro">{string.intro}</a>
                    <a href="./companyintro">{string.safetyTitle}</a>
                    <a href="./serviceuse">{string.serviceUse}</a>
                    <a href="./enteruse">{string.customUse}</a>
                    <a href="./charge">{string.serviceCharge}</a>
                    <a href="./marketing">{string.marketing}</a>
                    <a href="./shopping">{string.shopping}</a>
                    <a href="./nft">{string.nftMarket}</a>
                    <a href="./customer">{string.customerCenter}</a>
                    <a href="./qna">{string.consulting}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 고객센터 첫번째 섹션 */}
        <div className="ksmartcode-customer-search">
          <div className="ksmartcode-customer-search-all">
            <RevealFade bottom duration={2000}>
              <div className="ksmartcode-customer-search-text-title">{string.howCanIHelpYou1}</div>
            </RevealFade>
            <RevealFade bottom duration={2000} delay={500}>
              <div className="ksmartcode-customer-search-text-subtitle">{string.howCanIHelpYou2}</div>
            </RevealFade>
            <RevealFade bottom duration={2000} delay={1000}>
              <div className="ksmartcode-customer-search-input">
                <input type="text" id="search" placeholder={string.howCanIHelpYou3}></input>
                <div className="ksmartcode-customer-search-input-button" onClick={() => { }}>{string.search}</div>
              </div>
            </RevealFade>
          </div>
        </div>

        {/* 고객센터 두번째 섹션 */}
        <div className="ksmartcode-customer-asking">
          <div className="ksmartcode-customer-asking-all">
            <RevealFade duration={2000}>
              <div className="ksmartcode-customer-asking-text-title">{string.frequentQuestion}</div>
            </RevealFade>
            <RevealFade right duration={2000}>
              <div className="ksmartcode-customer-asking-content" onClick={() => { this.setState({ qnaType01: !this.state.qnaType01 }) }}>{string.frequentQuestionMain1}</div>
            </RevealFade>
            {this.state.qnaType01 && (
              <div className="ksmartcode-customer-asking-content-element">
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain1Sub1}
                </div>
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain1Sub2}
                </div>
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain1Sub3}
                </div>
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain1Sub4}
                </div>
              </div>
            )}
            <RevealFade left duration={2000}>
              <div className="ksmartcode-customer-asking-content" onClick={() => { this.setState({ qnaType02: !this.state.qnaType02 }) }}>{string.frequentQuestionMain2}</div>
            </RevealFade>
            {this.state.qnaType02 && (
              <div className="ksmartcode-customer-asking-content-element">
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain2Sub1}
                </div>
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain2Sub2}
                </div>
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain2Sub3}
                </div>
              </div>
            )}<RevealFade right duration={2000}>
              <div className="ksmartcode-customer-asking-content" onClick={() => { this.setState({ qnaType03: !this.state.qnaType03 }) }}>{string.frequentQuestionMain3}</div>
            </RevealFade>
            {this.state.qnaType03 && (
              <div className="ksmartcode-customer-asking-content-element">
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain3Sub1}
                </div>
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain3Sub2}
                </div>
              </div>
            )}<RevealFade left duration={2000}>
              <div className="ksmartcode-customer-asking-content" onClick={() => { this.setState({ qnaType04: !this.state.qnaType04 }) }}>{string.frequentQuestionMain4}</div>
            </RevealFade>
            {this.state.qnaType04 && (
              <div className="ksmartcode-customer-asking-content-element">
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain4Sub1}
                </div>
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain4Sub2}
                </div>
              </div>
            )}<RevealFade right duration={2000}>
              <div className="ksmartcode-customer-asking-content" onClick={() => { this.setState({ qnaType05: !this.state.qnaType05 }) }}>{string.frequentQuestionMain5}</div>
            </RevealFade>
            {this.state.qnaType05 && (
              <div className="ksmartcode-customer-asking-content-element">
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain5Sub1}
                </div>
                <div className="ksmartcode-customer-asking-content-element-text">
                  {string.frequentQuestionMain5Sub2}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* 고객센터 세번째 섹션 */}
        <div className="ksmartcode-customer-question">
          <div className="ksmartcode-customer-question-all">
            <RevealFade duration={2000}>
              <div className="ksmartcode-customer-question-guide">
                {string.oneononeQuestion}
                <div className="ksmartcode-customer-question-mainText">
                  {string.doYouNeedHelp1}
                  <br />
                  {string.doYouNeedHelp2}
                </div>
              </div>
            </RevealFade>
            <RevealFade duration={2000}>
              <div className="ksmartcode-customer-question-input">
                <Form ref={this.formRef} {...formItemLayout} onFinish={this.OnCreateInquiry}>
                  <FormItem
                    name="title"
                    rules={[{ required: true, message: '제목을 입력해주세요' }]}>
                    <input type="text" id="info1" placeholder={string.title} />
                  </FormItem>
                  <FormItem
                    name="email"
                    rules={[{ required: true, message: '이메일을 입력해주세요' }]}>
                    <input type="text" id="info2" placeholder={string.email} />
                  </FormItem>
                  <FormItem
                    name="content"
                    rules={[{ required: true, message: '내용을 입력해주세요' }]}>
                    <input type="text" id="info2" placeholder={string.content} />
                  </FormItem>
                  <Button className="customer-infobtn" type="primary" htmlType="submit">
                    {string.consultingButton}
                  </Button>
                </Form>
              </div>
            </RevealFade>
          </div>
        </div>

        <div className="ksmartcode-footer">
          <div className="ksmartcode-footer-all">
            <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
            <div className="ksmartcode-footer-address">{string.addressInfo}</div>
            <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
            <div className="ksmartcode-footer-term1">
              <Link to='/terms' offset='70' className="text-link5">
                {string.terms1}
              </Link>
            </div>
            <div className="ksmartcode-footer-term2">
              <Link to='/privacy' offset='70' className="text-link5">
                {string.terms2}
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};
export default withAlert()(connect(mapStateToProps)(Customer));
