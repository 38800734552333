import React from "react";
import { connect } from "react-redux";
import { stringReducer } from "../../actions";
import { Link, Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { Form, Input, Button, Modal, } from "antd";
import MenuButton from './../btn/MenuButton';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import first from '../../img/main/first.png';
import second from '../../img/main/second.png';
import third from '../../img/main/third.png';
import fourth from '../../img/main/fourth.png';
import { withAlert } from 'react-alert';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import RevealSlide from 'react-reveal/Slide';
import RevealFade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';

const FormItem = Form.Item;

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupVisible: false,
      open: false
    }
    this.formRef = React.createRef();
  }
  OnCreateInquiry = (e) => {
    let info = JSON.stringify({
      ...this.formRef.current.getFieldsValue(),
      //추가 지정해서 보내기
      category: 'MAIN',
      title: '문의/제안서',
      content:
        '회사이름:' + this.formRef.current.getFieldValue('company') + "/" +
        '전화번호:' + this.formRef.current.getFieldValue('phone') + "/" +
        '지역:' + this.formRef.current.getFieldValue('address') + "/" +
        '업종:' + this.formRef.current.getFieldValue('job')
    });
    const self = this;
    httpPost(httpUrl.inquiryCreate, [], info).then(response => {
      console.log('result=' + JSON.stringify(response.data))
      if (response.data == "SUCCESS") {
        alert('문의가 성공적으로 접수되었습니다. 빠른시일내 연락드리겠습니다.')
        this.formRef.current.resetFields();
      }
      else {
        alert('알수없는 문제로 문의에 실패하였습니다. 잠시 후 다시 시도해 주세요.')
      }
    }).catch(e => {
      console.log('err=' + JSON.stringify(e))
      Modal.error({
        content: (
          <div style={{ paddingLeft: '40px' }}>
            작성에 실패하였습니다. <br />잠시 후 다시 시도해주세요.
          </div>
        ),
        okText: '닫기'
      });
    })
  }

  ChangeNav = () => {
    this.setState(
      prevState => ({ open: !prevState.open })
    )
  };
  setLanguage = (lang) => {
    this.props.setLanguage(lang)
  }
  render() {
    const containerClasses = [];
    if (this.state.open) {
      containerClasses.push("open")
    }
    const { language, string } = this.props;

    const slideImages = [
      first,
      second,
      third,
      fourth
    ];

    const { form } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      }
    };
    const openPartnerPage = () => {
      const url = "https://partner.e-smartcode.com/";
      window.open(url, "_blank");
    }
    return (
      <div className="container">
        {/* header */}
        <div className="ksmartcode-header">
          <div className="ksmartcode-header-all">
            <div className="ksmartcode-header-left">
              <div className="text-link">
                <img src={require('../../img/kslogo.png')}
                  className="header-logo" />
              </div>
            </div>
            <div className="ksmartcode-header-menu">
              <div className="ksmartcode-header-menu-center">
                <div className="ksmartcode-header-menu-center-element">
                  <div class="dropdown">
                    <div class="dropdown-div">
                      {string.blockchainService}
                    </div>
                    <div class="dropdown-content">
                      <Link to='/intro' className="text-link2">
                        {string.intro}
                      </Link>
                      <Link to='/companyIntro' className="text-link2">
                        {string.safetyTitle}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-header-menu-center-element">
                  <div class="dropdown">
                    <div class="dropdown-div">
                      {string.serviceAll}
                    </div>
                    <div class="dropdown-content">
                      <Link to="/serviceUse" offset='100' className="text-link2">
                        {string.serviceUse}
                      </Link>
                      <Link to='/enterUse' className="text-link2">
                        {string.customUse}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="ksmartcode-header-menu-center-element">
                  <AnchorLink href="#serviceCharge" offset='-50' className="text-link">
                    {string.serviceCharge}
                  </AnchorLink>
                </div>
                <div className="ksmartcode-header-menu-center-element">
                  <Link to='/marketing' offset='70' className="text-link">
                    {string.marketing}
                  </Link>
                </div>
                <div className="ksmartcode-header-menu-center-element">
                  <Link to='/shopping' offset='70' className="text-link">
                    {string.shopping}
                  </Link>
                </div>
                <div className="ksmartcode-header-menu-center-element">
                  <Link to='/nft' offset='70' className="text-link">
                    {string.nftMarket}
                  </Link>
                </div>
              </div>
              <div className="ksmartcode-header-menu-right">
                <div className="ksmartcode-header-menu-right-element">
                  <Link to='/customer' className="text-link">
                    {string.customerCenter}
                  </Link>
                </div>
                <Link to='/qna' className="text-link">
                  <div className="ksmartcode-header-menu-right-element2">
                    <Link to='/qna' className="text-link">
                      {string.consulting}
                    </Link>
                  </div>
                </Link>             
                <div className="ksmartcode-header-menu-right-element3" onClick={openPartnerPage}>                  
                  {string.freeExperience}                                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <Slide easing="ease">
          {/* 헤더 첫번째 슬라이드 */}
          <div className="each-slide">
            <div style={{ 'backgroundImage': `url(${slideImages[0]})` }}>
              {/* 첫번째 섹션 */}
              <div className="ksmartcode-header-blockchain">
                <div className="ksmartcode-header-blockchain-mainText">
                  {string.blockChainTitle1}
                  <br />
                  {string.blockChainTitle2}
                  <div className="ksmartcode-header-blockchain-subText">{string.blockChainSub1}</div>
                </div>
              </div>
            </div>
          </div>
          {/* 헤더 두번째 슬라이드 */}
          <div className="each-slide">
            <div style={{ 'backgroundImage': `url(${slideImages[1]})` }}>
              {/* 첫번째 섹션 */}
              <div className="ksmartcode-header-blockchain">
                <div className="ksmartcode-header-blockchain-mainText">
                  {string.blockChainTitle3}
                  <br />
                  {string.blockChainTitle4}
                  <div className="ksmartcode-header-blockchain-subText">{string.blockChainSub2}</div>
                </div>
              </div>
            </div>
          </div>
          {/* 헤더 세번째 슬라이드 */}
          <div className="each-slide">
            <div style={{ 'backgroundImage': `url(${slideImages[2]})` }}>
              {/* 첫번째 섹션 */}
              <div className="ksmartcode-header-blockchain">
                <div className="ksmartcode-header-blockchain-mainText">
                  {string.blockChainTitle5}
                  <br />
                  {string.blockChainTitle6}
                  <div className="ksmartcode-header-blockchain-subText">{string.blockChainSub3}</div>
                </div>
              </div>
            </div>
          </div>
          {/* 헤더 네번째 슬라이드 */}
          <div className="each-slide">
            <div style={{ 'backgroundImage': `url(${slideImages[3]})` }}>
              {/* 첫번째 섹션 */}
              <div className="ksmartcode-header-blockchain">
                <div className="ksmartcode-header-blockchain-mainText">
                  {string.blockChainTitle7}
                  <br />
                  {string.blockChainTitle8}
                  <div className="ksmartcode-header-blockchain-subText">{string.blockChainSub4}</div>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        {/* 미디어 쿼리 min-width:1319px 일때만 실행 */}
        <div className="ksmartcode-header-hide">
          <div className="ksmartcode-header-hide-menu">
            <div className={containerClasses.join(' ')}>
              <div className={"menubutton"} onClick={() => this.ChangeNav()}>
                <MenuButton state={!this.state.open} /></div>
              <div className={"sidenav"}>
                <a href="/">{string.homePage}</a>
                <a href="./intro">{string.intro}</a>
                <a href="./companyintro">{string.safetyTitle}</a>
                <a href="./serviceuse">{string.serviceUse}</a>
                <a href="./enteruse">{string.customUse}</a>
                <a href="./charge">{string.serviceCharge}</a>
                <a href="./marketing">{string.marketing}</a>
                <a href="./shopping">{string.shopping}</a>
                <a href="./nft">{string.nftMarket}</a>
                <a href="./customer">{string.customerCenter}</a>
                <a href="./qna">{string.consulting}</a>
                <a onClick={openPartnerPage}>{string.freeExperience}</a>
              </div>
            </div>
          </div>
        </div>


        {/* body */}
        <div className="ksmartcode-body">
          {/* 두번째 섹션 */}
          <div className="ksmartcode-body-news">
            <RevealFade bottom duration={1500}>
              <div className="ksmartcode-body-news-guide">{string.newsTitle}</div>
            </RevealFade>
            <RevealFade bottom duration={1500}>
              <div className="ksmartcode-body-news-mainText">
                {string.newsMainTitle1}
                <br />
                {string.newsMainTitle2}
              </div>
            </RevealFade>
            <div className="ksmartcode-body-news-image-content1">
              <div className="ksmartcode-body-news-image">
                <RevealFade bottom duration={1500}>
                  <div className="ksmartcode-body-news-icon">
                    <img src={require('../../img/main/sectiontwo/firstimg.png')}
                      className="body-section2-img1" />
                  </div>
                </RevealFade>
                <RevealFade bottom duration={2000}>
                  <div className="ksmartcode-body-news-icon-title">
                    {string.newsContentMain1}
                    <br />
                    {string.newsContentSub1}
                  </div>
                </RevealFade>
                <RevealFade bottom duration={2200}>
                  <div className="ksmartcode-body-news-icon-link">
                    <a href="https://www.tokenpost.kr/article-24096" target="_blank" className="text-link3">
                      {string.newsContentLink1}
                    </a>
                  </div>
                  {/* <div className="button-small color-blue" onClick={()=>{this.props.alert.show(string.prepare)}}>가맹점보기</div> */}
                </RevealFade>
              </div>

              <div className="ksmartcode-body-news-image">
                <RevealFade bottom duration={1500}>
                  <div className="ksmartcode-body-news-icon">
                    <img src={require('../../img/main/sectiontwo/secondimg.png')}
                      className="body-section2-img1" />
                  </div>
                </RevealFade>
                <RevealFade bottom duration={2000}>
                  <div className="ksmartcode-body-news-icon-title">
                    {string.newsContentMain2}
                    <br />
                    {string.newsContentSub2}
                  </div>
                </RevealFade>
                <RevealFade bottom duration={2200}>
                  <div className="ksmartcode-body-news-icon-link">
                    <a href="https://www.hkbnews.com/article/view/4426" target="_blank" className="text-link3">
                      {string.newsContentLink2}
                    </a>
                  </div>
                </RevealFade>
              </div>
            </div>

            <div className="ksmartcode-body-news-image-content-center">
              <div className="ksmartcode-body-news-image">
                <RevealFade bottom duration={1500}>
                  <div className="ksmartcode-body-news-icon">
                    <img src={require('../../img/main/sectiontwo/centerimg.png')}
                      className="body-section2-img2" />
                  </div>
                </RevealFade>
                <RevealFade bottom duration={2000}>
                  <div className="ksmartcode-body-news-icon-title2">
                    {string.newsContentMainCenter1}
                  </div>
                </RevealFade>
                <RevealFade bottom duration={2200}>
                  <div className="ksmartcode-body-news-icon-link2">
                    <a href="https://www.blockmedia.co.kr/archives/167144" target="_blank" className="text-link3">
                      {string.newsContentLinkCenter}
                    </a>
                  </div>
                </RevealFade>
              </div>
            </div>

            <RevealFade bottom duration={1500}>
              <div className="ksmartcode-body-news-image-content2">
                <div className="ksmartcode-body-news-image">
                  <RevealFade bottom duration={1500}>
                    <div className="ksmartcode-body-news-icon">
                      <img src={require('../../img/main/sectiontwo/thirdimg.png')}
                        className="body-section2-img1" />
                    </div>
                  </RevealFade>
                  <RevealFade bottom duration={2000}>
                    <div className="ksmartcode-body-news-icon-title">
                      {string.newsContentMain3}
                      <br />
                      {string.newsContentSub3}
                    </div>
                  </RevealFade>
                  <RevealFade bottom duration={2200}>
                    <div className="ksmartcode-body-news-icon-link">
                      <a href="https://www.boannews.com/media/view.asp?idx=74564&page=1&kind=3" target="_blank" className="text-link3">
                        {string.newsContentLink3}
                      </a>
                    </div>
                  </RevealFade>
                </div>
                <div className="ksmartcode-body-news-image">
                  <RevealFade bottom duration={1500}>
                    <div className="ksmartcode-body-news-icon">
                      <img src={require('../../img/main/sectiontwo/fourthimg.png')}
                        className="body-section2-img1" />
                    </div>
                  </RevealFade>
                  <RevealFade bottom duration={2000}>
                    <div className="ksmartcode-body-news-icon-title">
                      {string.newsContentMain4}
                      <br />
                      {string.newsContentSub4}
                    </div>
                  </RevealFade>
                  <RevealFade bottom duration={2200}>
                    <div className="ksmartcode-body-news-icon-link">
                      <a href="https://www.coindeskkorea.com/news/articleView.html?idxno=48536" target="_blank" className="text-link3">
                        {string.newsContentLink4}
                      </a>
                    </div>
                  </RevealFade>
                </div>
              </div>
            </RevealFade>
          </div>

          {/* 세번째 섹션 */}
          <div className="ksmartcode-body-safety" id="safety">
            <div className="ksmartcode-body-safety-all">
              <RevealFade top duration={1000}>
                <div className="ksmartcode-body-safety-guide">
                  {string.safetyTitle}
                  <div className="ksmartcode-body-safety-mainText">
                    {string.safetyMainTitle1}
                    <br />
                    {string.safetyMainTitle2}
                  </div>
                  <div className="ksmartcode-body-safety-subText">{string.safetySubTitle}</div>
                  <div className="ksmartcode-body-safety-link">
                    <Link to='/companyintro' className="text-link2">
                      {string.safetyLink}
                    </Link>
                  </div>
                </div>
              </RevealFade>
              <RevealFade bottom duration={1000}>
                <div className="ksmartcode-body-safety-image">
                  <img src={require('../../img/main/sectionthree/safetysub.png')}
                    className="body-section3-img1" />
                  <img src={require('../../img/main/sectionthree/safetymain.png')}
                    className="body-section3-img2" />
                </div>
              </RevealFade>
            </div>
          </div>

          {/* 네번째 섹션 */}
          <div className="ksmartcode-body-cert" id="cert">
            <div className="ksmartcode-body-cert-all">
              <RevealFade left duration={1500} delay={500}>
                <div className="ksmartcode-body-cert-image">
                  <img src={require('../../img/main/sectionfour/certmain.png')}
                    className="body-section4-img1" />
                  <img src={require('../../img/main/sectionfour/certsub.png')}
                    className="body-section4-img2" />
                </div>
              </RevealFade>
              <RevealFade left duration={1500}>
                <div className="ksmartcode-body-cert-guide">
                  {string.certTitle}
                  <div className="ksmartcode-body-cert-mainText">
                    {string.certMainTitle1}
                    <br />
                    {string.certMainTitle2}
                  </div>
                  <div className="ksmartcode-body-cert-subText">
                    {string.certSubTitle1}
                    <br />
                    {string.certSubTitle2}
                  </div>
                  <div className="ksmartcode-body-cert-link">
                    <Link to='/serviceUse' className="text-link3">
                      {string.certLink}
                    </Link>
                  </div>
                </div>
              </RevealFade>
            </div>
          </div>

          {/* 다섯번째 섹션 */}
          <div className="ksmartcode-body-marketing" id="marketing">
            <div className="ksmartcode-body-marketing-all">
              <Bounce duration={1500}>
                <div className="ksmartcode-body-marketing-guide">{string.marketingTitle}
                  <div className="ksmartcode-body-marketing-mainText">
                    {string.marketingMainTitle1}
                    <br />
                    {string.marketingMainTitle2}
                  </div>
                  <div className="ksmartcode-body-marketing-subText">{string.marketingSubTitle}</div>
                  <div className="ksmartcode-body-marketing-link">
                    <Link to='/marketing' className="text-link">
                      {string.marketingLink}
                    </Link>
                  </div>
                </div>
              </Bounce>
              <div className="ksmartcode-body-marketing-image">
                {/* 공백 이미지 */}
                <img src={require('../../img/main/sectionfive/clear.png')}
                  className="body-section5-img1" />
                <Bounce duration={1500} delay={500}>
                  <img src={require('../../img/main/sectionfive/marketingmain.png')}
                    className="body-section5-img2" />
                </Bounce>

              </div>
            </div>
          </div>

          {/* 여섯번째 섹션 */}
          <div className="ksmartcode-body-shop" id="shop">
            <RevealSlide bottom duration={1500}>
              <div className="ksmartcode-body-shop-guide">{string.shopTitle}</div>
            </RevealSlide>
            <RevealSlide bottom duration={1500}>
              <div className="ksmartcode-body-shop-mainText">{string.shopMainTitle}</div>
              <div className="ksmartcode-body-shop-link">
                <Link to='/shopping' className="text-link3">
                  {string.shopLink}
                </Link>
              </div>
            </RevealSlide>
            <div className="ksmartcode-body-shop-content">
              <div className="ksmartcode-body-shop-image">
                <Flip left duration={1500}>
                  <div className="ksmartcode-body-shop-image-content-01">
                    <div className="ksmartcode-body-shop-image-content-title1">
                      {string.shopContentMain1}
                      <br />
                      {string.shopContentSub1}
                    </div>
                    <div className="ksmartcode-body-shop-image-content-sub">{string.shopContentFee}</div>
                  </div>
                </Flip>
                <Flip left duration={1500} delay={500}>
                  <div className="ksmartcode-body-shop-image-content-02">
                    <div className="ksmartcode-body-shop-image-content-title2">
                      {string.shopContentMain2}
                      <br />
                      {string.shopContentSub2}
                    </div>
                  </div>
                </Flip>
                <Flip left duration={1500} delay={1000}>
                  <div className="ksmartcode-body-shop-image-content-03">
                    <div className="ksmartcode-body-shop-image-content-title3">
                      {string.shopContentMain3}
                      <br />
                      {string.shopContentSub3}
                    </div>
                  </div>
                </Flip>
              </div>
            </div>
          </div>

          {/* 일곱번째 섹션 */}
          <div className="ksmartcode-body-charge" id='serviceCharge'>
            <RevealSlide bottom>
              <div className="ksmartcode-body-charge-guide">{string.chargeTitle}</div>
            </RevealSlide>
            <RevealSlide bottom>
              <div className="ksmartcode-body-charge-mainText">{string.chargeMainTitle}</div>
            </RevealSlide>
            <div className="ksmartcode-body-charge-all">
              <div className="ksmartcode-body-charge-allfield">
                {/* 이벤트 필드 */}
                <RevealFade bottom duration={1500}>
                  <div className="ksmartcode-body-charge-field">
                    <div className="ksmartcode-body-charge-field-event">
                      <div className="ksmartcode-body-charge-number-event">{string.enteruseChargeContentNumberEvent}</div>
                      <div className="ksmartcode-body-charge-numbertext">{string.enteruseChargeContentTitleEvent}</div>
                    </div>
                    <div className="ksmartcode-body-charge-field-content-event">
                      <div className="ksmartcode-body-charge-enter-text1">
                        {string.enteruseChargeContent1ContentEvent1}
                      </div>
                      <div className="ksmartcode-body-charge-enter-cancel">
                        {string.enteruseChargeContent1Content2}
                      </div>
                      <div>
                        <div className="ksmartcode-body-charge-enter-main-event">
                          {string.enteruseChargeContent1ContentEvent3}
                        </div>
                        <div className="ksmartcode-body-charge-enter-sub">
                          {string.enteruseChargeContent1Content4}
                        </div>
                        <div className="ksmartcode-body-charge-enter-amount">
                          {string.enteruseChargeContent1Content5}
                        </div>
                      </div>
                    </div>
                  </div>
                </RevealFade>
                {/* 첫번째 필드 */}
                <RevealFade bottom duration={1500}>
                  <div className="ksmartcode-body-charge-field">
                    <div className="ksmartcode-body-charge-field-01">
                      <div className="ksmartcode-body-charge-number">{string.chargeContentNumber1}</div>
                      <div className="ksmartcode-body-charge-numbertext">{string.chargeContentTitle1}</div>
                    </div>
                    <div className="ksmartcode-body-charge-field-content">
                      <div className="ksmartcode-body-charge-enter-text1">
                        {string.chargeContent1Content1}
                      </div>
                      <div className="ksmartcode-body-charge-enter-cancel">
                        {string.chargeContent1Content2}
                      </div>

                      <div>
                        <div className="ksmartcode-body-charge-enter-main">
                          {string.chargeContent1Content3}
                        </div>
                        <div className="ksmartcode-body-charge-enter-sub">
                          {string.chargeContent1Content4}
                        </div>
                        <div className="ksmartcode-body-charge-enter-amount">
                          {string.chargeContent1Content5}
                        </div>
                      </div>

                      <div className="ksmartcode-body-charge-enter-text2">
                        {string.chargeContent1Content6}
                      </div>
                      <div className="ksmartcode-body-charge-enter-cancel2">
                        {string.chargeContent1Content7}
                      </div>

                      <div>
                        <div className="ksmartcode-body-charge-enter-main">
                          {string.chargeContent1Content8}
                        </div>
                        <div className="ksmartcode-body-charge-enter-sub">
                          {string.chargeContent1Content9}
                        </div>
                        <div className="ksmartcode-body-charge-enter-amount">
                          {string.chargeContent1Content10}
                        </div>
                      </div>
                    </div>
                  </div>
                </RevealFade>
                {/* 두번째필드 */}
                <RevealFade bottom duration={1500}>
                  <div className="ksmartcode-body-charge-field">
                    <div className="ksmartcode-body-charge-field-02">
                      <div className="ksmartcode-body-charge-number">{string.chargeContentNumber2}</div>
                      <div className="ksmartcode-body-charge-numbertext">{string.chargeContentTitle2}</div>
                    </div>
                    <div className="ksmartcode-body-charge-field-content">
                      <div className="ksmartcode-body-charge-user-text">
                        {string.chargeContentTitle2Sub}
                      </div>

                      <div>
                        <div className="ksmartcode-body-charge-user-main-text1">
                          {string.chargeContent2Content1}
                        </div>
                        <div className="ksmartcode-body-charge-user-main-text2">
                          {string.chargeContent2Content2}
                        </div>
                        <div className="ksmartcode-body-charge-user-main-text3">
                          {string.chargeContent2Content3}
                        </div>
                        <div className="ksmartcode-body-charge-user-main-text4">
                          {string.chargeContent2Content4}
                        </div>
                      </div>

                      <div>
                        <div className="ksmartcode-body-charge-user-main-text5">
                          {string.chargeContent2Content5}
                        </div>
                        <div className="ksmartcode-body-charge-user-main-text6">
                          {string.chargeContent2Content6}
                        </div>
                        <div className="ksmartcode-body-charge-user-main-text7">
                          {string.chargeContent2Content7}
                        </div>
                        <div className="ksmartcode-body-charge-user-main-text8">
                          {string.chargeContent2Content8}
                        </div>
                      </div>

                      <div>
                        <div className="ksmartcode-body-charge-user-main-text9">
                          {string.chargeContent2Content9}
                        </div>
                        <div className="ksmartcode-body-charge-user-main-text10">
                          {string.chargeContent2Content10}
                        </div>
                        <div className="ksmartcode-body-charge-user-main-text11">
                          {string.chargeContent2Content11}
                        </div>
                        <div className="ksmartcode-body-charge-user-main-text12">
                          {string.chargeContent2Content12}
                        </div>
                      </div>

                    </div>
                  </div>
                </RevealFade>
                {/* 세번째필드 */}
                <RevealFade bottom duration={1500}>
                  <div className="ksmartcode-body-charge-field">
                    <div className="ksmartcode-body-charge-field-03">
                      <div className="ksmartcode-body-charge-number">{string.chargeContentNumber3}</div>
                      <div className="ksmartcode-body-charge-numbertext">{string.chargeContentTitle3}</div>
                    </div>
                    <div className="ksmartcode-body-charge-field-content">
                      <div className="ksmartcode-body-charge-independent-main">
                        {string.chargeContent3Content1}
                        <div className="ksmartcode-body-charge-independent-sub1">
                          {string.chargeContent3Content2}
                        </div>
                        <div className="ksmartcode-body-charge-independent-sub1">
                          {string.chargeContent3Content3}
                        </div>
                        <div className="ksmartcode-body-charge-independent-sub2">
                          {string.chargeContent3Content4}
                        </div>
                      </div>
                    </div>
                  </div>
                </RevealFade>
              </div>
            </div>
          </div>

          {/* 여덟번째 섹션 */}
          <div className="ksmartcode-body-consulting" id="consulting">
            <RevealFade duration={2000}>
              <div className="ksmartcode-body-consulting-all">
                <div className="ksmartcode-body-consulting-guide">
                  {string.consultingTitle}
                  <div className="ksmartcode-body-consulting-mainText">
                    {string.consultingMain1}
                    <br />
                    {string.consultingMain2}
                  </div>
                  <div className="ksmartcode-body-consulting-subText">
                    {string.consultingSub}
                  </div>
                </div>
                <div className="ksmartcode-body-consulting-input">
                  <Form ref={this.formRef} {...formItemLayout} onFinish={this.OnCreateInquiry}>
                    <FormItem
                      name="company"
                      rules={[{ required: true, message: '회사명을 입력해주세요' }]}>
                      <input type="text" id="qna" placeholder={string.consultingContent1} />
                    </FormItem>
                    <FormItem
                      name="phone"
                      rules={[{ required: true, message: '전화번호를 입력해주세요' }]}>
                      <input type="text" id="qna" placeholder={string.consultingContent2} />
                    </FormItem>
                    <FormItem
                      name="address"
                      rules={[{ required: true, message: '지역을 입력해주세요' }]}>
                      <input type="text" id="qna" placeholder={string.consultingContent3} />
                    </FormItem>
                    <FormItem
                      name="job"
                      rules={[{ required: true, message: '업종을 입력해주세요' }]}>
                      <input type="text" id="qna" placeholder={string.consultingContent4} />
                    </FormItem>
                    {/* <div className="ksmartcode-body-consulting-input-checkbox">
                      <input type="checkbox" id="agree" />
                      <div className="ksmartcode-body-consulting-input-checkbox-text">
                        {string.agreement}
                      </div>
                    </div> */}
                    {/* <div className="ksmartcode-body-consulting-input-text-button"
                      onClick={() => { }}>
                      {string.consultingButton}
                    </div> */}
                    <Button className="ksmartcode-body-consulting-input-text-button" type="primary" htmlType="submit">
                      {string.consultingButton}
                    </Button>
                  </Form>
                </div>
              </div>
            </RevealFade>
          </div>

        </div>

        {/* footer */}
        <div className="ksmartcode-footer">
          <div className="ksmartcode-footer-all">
            <div className="ksmartcode-footer-ceo">{string.ceoInfo}</div>
            <div className="ksmartcode-footer-address">{string.addressInfo}</div>
            <div className="ksmartcode-footer-custom">{string.emailInfo}</div>
            <div className="ksmartcode-footer-term1">
              <Link to='/terms' offset='70' className="text-link5">
                {string.terms1}
              </Link>
            </div>
            <div className="ksmartcode-footer-term2">
              <Link to='/privacy' offset='70' className="text-link5">
                {string.terms2}
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};
export default withAlert()(connect(mapStateToProps)(Main));
